import styled from 'styled-components';

export const Container = styled.h3`
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.greenDark};

  @media (max-width: 576px) {
    font-size: 22px;
    padding-bottom: 10px;
  }
`;
