import produce from 'immer';

const INITIAL_STATE = {
  loadingCustomers: true,
  nextPageCustomers: 1,
  customers: [],
  currentCustomer: null, // Customer on editing
  addressList: [], // List of addresses from current customer or creating customer
  modalAddress: false, // Flag to open/close modal for create/edit address
  addressToEdit: null, // Address passed to modal for edit
  customerAddress: null, // Address returned by find by zip code
};

export default function customer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_CUSTOMERS': {
        if (
          action.payload.page === 1 ||
          state.customers?.length <= 0 ||
          action.payload.customers?.length <= 0
        ) {
          draft.customers = action.payload.customers;
        } else {
          draft.customers = state.customers.concat(action.payload.customers);
        }

        draft.nextPageCustomers = action.payload.nextPage;

        break;
      }

      case 'SET_CURRENT_CUSTOMER': {
        draft.currentCustomer = action.payload.currentCustomer;
        break;
      }

      case 'DELETE_CUSTOMER': {
        draft.customers = state.customers.filter(
          (c) => c.id !== action.payload.customerId
        );
        break;
      }

      case 'HANDLE_MODAL_ADDRESS': {
        draft.modalAddress = action.state;
        break;
      }

      case 'SET_CUSTOMER_ZIP_CODE': {
        draft.customerAddress = action.address;
        break;
      }

      case 'UPDATE_ADDRESSES_LIST': {
        if (state.addressList) {
          draft.addressList.push(action.address);
          break;
        }

        draft.addressList = [action.address];
        break;
      }

      case 'EDIT_ADDRESS_LIST': {
        const addressListIndex = draft.addressList.findIndex((a) => {
          return a.tempId === action.payload.address.tempId;
        });
        if (addressListIndex >= 0) {
          draft.addressList[addressListIndex] = action.payload.address;
        }
        break;
      }

      case 'REMOVE_ADDRESS': {
        const addresIndex = draft.addressList.findIndex(
          (a) =>
            a.zip_code === action.payload.address.zip_code &&
            a.number === action.payload.address.number
        );
        if (addresIndex >= 0) {
          draft.addressList.splice(addresIndex, 1);
        }
        break;
      }

      case 'UPDATE_CURRENT_CUSTOMER': {
        const customerIndex = draft.customers.findIndex(
          (c) => c.id === action.payload.customer.id
        );

        if (customerIndex >= 0) {
          draft.customers[customerIndex] = action.payload.customer;
        }
        break;
      }

      case 'SET_CUSTOMER_ADDRESSES': {
        draft.addressList = action.payload.addresses;
        break;
      }

      case 'SET_ADDRESS_TO_EDIT': {
        draft.addressToEdit = action.payload.address;
        break;
      }

      case 'HANDLE_LOADING_CUSTOMERS': {
        draft.loadingCustomers = action.payload.state;
        break;
      }
      default:
    }
  });
}
