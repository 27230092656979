import produce from 'immer';

const INITIAL_STATE = {
  dashboardValues: {},
};

export default function dashboard(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_DASHBOARD_VALUES': {
        draft.dashboardValues = action.payload.dashboardValues;
        break;
      }
      default:
    }
  });
}
