import styled from 'styled-components';

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: ${(props) =>
    props.danger ? props.theme.colors.redDark : props.theme.colors.greenDark};
  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000050;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.6s;
  padding: 0 20px;

  &:hover {
    background: ${(props) =>
      props.danger
        ? props.theme.colors.redLight
        : props.theme.colors.greenMedium};
    box-shadow: 2px 4px 6px #00000050;
  }
`;

export const IconsArea = styled.div`
  display: flex;
`;

export const Options = styled.div`
  display: none;
  background: #fff;
  position: absolute;
  right: ${(props) => props.right}px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000050;

  @media (max-width: 992px) {
    display: flex;
  }
  @media (max-width: 576px) {
    display: flex;
    right: 70px;
  }
`;

export const IconEdit = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.greenMedium};
  box-shadow: 0px 3px 4px #00000050;
  transition: background 0.6s, box-shadow 0.6s;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 6px 8px #00000050;
    background: ${(props) => props.theme.colors.greenDark};
  }

  svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
    color: #fff;
  }
`;

export const IconDelete = styled(IconEdit)`
  margin-left: 10px;
  background: ${(props) => props.theme.colors.redLight};

  &:hover {
    background: ${(props) => props.theme.colors.redDark};
  }
`;
