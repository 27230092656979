import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IoIosMenu } from 'react-icons/io';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import * as OrdersActions from '../../../store/modules/orders/actions';

import { Container, MenuMobile } from './styles';

function Header({
  handleDrawerVisible,
  handleOrderItemsVisible,
  orderItemsVisible,
  drawerVisible,
  storeName,
}) {
  return (
    <Container>
      <MenuMobile>
        {orderItemsVisible ? (
          <div>
            <AiOutlineArrowLeft
              onClick={() => handleOrderItemsVisible(false)}
              size={24}
            />
          </div>
        ) : (
          <div className="MenuMobile">
            <IoIosMenu
              onClick={() => handleDrawerVisible(!drawerVisible)}
              size={30}
            />
          </div>
        )}
      </MenuMobile>
      <h3>
        Painel I9P | <small>{storeName}</small>
      </h3>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrdersActions, dispatch);

const mapStateToProps = (state) => ({
  drawerVisible: state.orders.drawerVisible,
  drawerComplete: state.orders.drawerComplete,
  orderItemsVisible: state.orders.orderItemsVisible,
  storeName: state.store?.storeInfo?.name,
  image: state.store?.storeInfo?.settings?.logo_url,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
