import styled, { keyframes } from 'styled-components';

const animation = keyframes`
 from {
		left: -20px;
		opacity: 0
	}
	to {
		left: 0px;
		opacity: 1;
	}

`;

const animationTop = keyframes`
 from {
		top: -20px;
		opacity: 0
	}
	to {
		top: 0px;
		opacity: 1;
	}

`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  background: #00000050;
  z-index: 4;
`;
export const Modal = styled.div`
  display: flex;
  width: 70%;
  height: 80%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 5%;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  animation: ${animation} 0.6s;
`;

export const SubTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
  margin: 10px 0;
`;

export const TooltipArea = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
`;

export const LimitArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const CheckBoxArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    padding: 5px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const Item = styled.div`
  display: flex;
  padding: 5px 0;
  position: relative;
  flex-direction: column;

  & + div {
    animation: ${animationTop} 0.6s;
    border-top: 1px solid #eee;
  }
`;

export const Line = styled.div`
  display: flex;
  width: 100%;

  & + div {
    padding: 0 0 0 40px;
  }
`;

export const NewItem = styled.div`
  flex: 0.2 !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 20px 10px 0 !important;
  display: flex;
`;

export const ButtonNewItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.greenDark};
  border-radius: 10px;
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 4px #00000050;
  cursor: pointer;
  color: #fff;
  transition: all 0.6s;

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 4px 6px #00000050;
  }
`;

export const ButtonDelete = styled(ButtonNewItem)`
  background: ${(props) => props.theme.colors.redDark};

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0 20px;
  }
`;

export const SelectArea = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  flex: 1;

  span {
    padding-bottom: 5px;
  }

  .select {
    width: 100%;
  }
`;

export const Price = styled.div`
  display: flex;
  flex: 1;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
`;
