import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 350px;
`;

export const Image = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 120px;
  height: 120px;
  background-image: ${(props) => ` url(${props.image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  /* @media (max-width: 480px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 380px) {
    width: 150px;
    height: 150px;
  } */
`;
export const ImageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 20px;
`;
export const SlideArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .slide {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-list {
    width: 100%;
  }
`;

export const LeftButton = styled.div``;
export const RightButton = styled.div``;

export const DeleteArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
`;

export const Delete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.redDark};
  border-radius: 5px;
  transition: color 0.4s, background 0.5s;

  &:hover {
    color: #fff;
    background: ${(props) => props.theme.colors.redLight};
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const ButtonPlus = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    display: flex;
    font-size: 20px;
    margin-right: 20px;
  }
`;
