import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: ${(props) =>
    props.drawerVisible ? props.theme.dimensions.drawer.width : '0px'};
  overflow: hidden;
  transition: width 0.5s;
  background: #fff;
  flex-direction: column;
  color: ${(props) => props.theme.colors.greenDark};
  z-index: 21;
  /* margin-top: ${(props) => (props.drawerVisible ? '80px' : '0')}; */
  top: 0;
  left: 0;

  @media (max-width: 992px) {
    width: ${(props) =>
      props.drawerVisible ? props.theme.dimensions.drawer.width : '0px'};
  }
  @media (min-width: 993px) {
    width: ${(props) => props.theme.dimensions.drawer.width};
  }
`;

export const DrawerOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  overflow: auto;
`;

export const Title = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
`;

export const Option = styled.a`
  display: flex;
  color: ${(props) =>
    props.current ? props.theme.colors.white : props.theme.colors.greenDark};
  background: ${(props) =>
    props.current ? props.theme.colors.greenDark : props.theme.colors.white};
  flex-direction: column;
  transition: background 0.6s, color 0.6s, flex-direction 0.6s;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  svg {
    width: 30px;
    height: auto;
    margin-bottom: 5px;
  }
`;
