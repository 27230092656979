import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  z-index: 10000;
  position: absolute;
  background: #00000020;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  height: auto;
  min-height: 700px;
  width: 70%;
  background: #fff;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const MapArea = styled.div`
  width: 100%;
  height: 500px;
  padding: 20px;
`;

export const Form = styled.form`
  padding: 10px 20px;
`;

export const Header = styled.div`
  padding: 5px;
  display: flex;
  width: 100%;
  font-weight: bold;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    & + div {
      border-left: 1px solid #ddd;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
`;

export const Name = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  input {
    color: #6e7989;
    text-align: center;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 8px;
    transition: border 0.6s;
    font-size: 16px;

    &:focus {
      border: 1px solid #727c8e;
    }

    &:placeholder-shown {
      text-align: center;
    }
  }
`;

export const Price = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  input {
    color: #6e7989;
    text-align: center;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 8px;
    transition: border 0.6s;
    font-size: 16px;

    &:focus {
      border: 1px solid #727c8e;
    }

    &:placeholder-shown {
      text-align: center;
    }
  }
`;

export const TimeMin = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const TimeInput = styled.div`
  input {
    width: 90%;
    color: #6e7989;
    text-align: center;
    padding: 5px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 9px;
    transition: border 0.6s;
    font-size: 14px;

    &:focus {
      border: 1px solid #727c8e;
    }
  }
`;

export const SelectArea = styled.div`
  width: 100%;
`;

export const TimeMax = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const ButtonArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

export const ButtonCancel = styled.button.attrs({ type: 'button' })`
  width: 40%;
  height: 30px;
  border-radius: 15px;
  border: 0;
  background: ${(props) => props.theme.colors.redLight};
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
  transition: box-shadow 0.6s, background 0.6s;
  font-size: 18px;
  margin-right: 10%;

  &:hover {
    background: ${(props) => props.theme.colors.redDark};
    box-shadow: 0px 5px 8px #00000050;
  }
`;

export const ButtonCreate = styled.button.attrs({ type: 'submit' })`
  width: 40%;
  height: 30px;
  border-radius: 15px;
  border: 0;
  background: ${(props) => props.theme.colors.greenMedium};
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
  transition: box-shadow 0.6s, background 0.6s;
  font-size: 18px;

  &:hover {
    background: ${(props) => props.theme.colors.greenDark};
    box-shadow: 0px 5px 8px #00000050;
  }
`;
