import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as AuthActions from '../../store/modules/auth/actions';
import * as StoreActions from '../../store/modules/store/actions';

import history from '../../services/history';

import { getStoreSlug } from '../../utils/storeUtil';

import i9pWhite from '../../assets/images/I9P-03.svg';
import i9pColor from '../../assets/images/I9P-01.svg';

import Input from '../components/Input';

import {
  Container,
  ImageArea,
  Image,
  FormArea,
  Form,
  LogoArea,
  Logo,
  Title,
  SubmitButton,
  ButtonArea,
} from './styles';

function Login({ signInRequest, signed, getStoreInfo }) {
  if (signed) history.push(`/${getStoreSlug()}/pedidos`);
  useEffect(() => {
    document.title = 'I9P - Login';
    getStoreInfo();
  }, [getStoreInfo]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    await signInRequest(email, password);
  }

  return (
    <Container>
      <ImageArea>
        <Image image={i9pWhite} />
      </ImageArea>
      <FormArea>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <LogoArea>
            <Logo image={i9pColor} />
          </LogoArea>
          <Title>
            <span>Login</span>
          </Title>
          <span>E-mail</span>
          <Input setState={setEmail} placeholder="Digite seu e-mail." />
          <span>Senha</span>
          <Input
            type="password"
            setState={setPassword}
            placeholder="Digite sua senha."
          />
          <ButtonArea>
            <SubmitButton>Entrar</SubmitButton>
          </ButtonArea>
        </Form>
      </FormArea>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...AuthActions, ...StoreActions }, dispatch);

const mapStateToProps = (state) => ({
  signed: state.auth.signed,
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
