import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swal from 'sweetalert';
import { toast } from 'react-toastify';

import Select from 'react-select';

import * as StoreActions from '../../../../store/modules/store/actions';

import Tooltip from '../../../components/Tooltip';
import CheckBox from '../../../components/CheckBox';
import Input from '../../../components/Input';

import {
  Container,
  Card,
  Body,
  ConfigArea,
  TitleConfig,
  TablePrice,
  Box,
  BoxResponsive,
  Button,
  ButtonArea,
} from './styles';

function StoreConfig({ storeSettings, tablePrices, updateStoreConfig }) {
  const [options, setOptions] = useState([
    { value: null, label: 'Sem tabela de preço' },
  ]);
  const [customerRegister, setCustomerRegister] = useState(
    storeSettings && !storeSettings.customer_register_approval
  );
  const [pricePublic, setPricePublic] = useState(
    storeSettings && storeSettings.price_public
  );
  const [minimunValue, setMinimunValue] = useState(
    (storeSettings && storeSettings.order_minimun_value) || ''
  );
  const [online, setOnline] = useState(storeSettings && storeSettings.online);
  const [deliveryTime, setDeliveryTime] = useState(
    (storeSettings && storeSettings.delivery_time) || ''
  );
  const [delivery, setDelivery] = useState(
    storeSettings && storeSettings.restrict_delivery_area
  );
  const [cartVisible, setCartVisible] = useState(
    storeSettings && storeSettings.cart_visible
  );
  const [showAddress, setShowAddress] = useState(
    storeSettings && storeSettings.show_address
  );
  const [tablePrice, setTablePrice] = useState(
    storeSettings && storeSettings.table_prices_id
  );

  useEffect(() => {
    tablePrices.map((tp) => {
      return setOptions((state) => [
        ...state,
        {
          value: tp.id,
          label: `${tp.id} - ${tp.name}`,
        },
      ]);
    });
  }, [tablePrices]);

  useEffect(() => {
    const selected = options.filter((option) => {
      return storeSettings && option.value === storeSettings.table_prices_id;
    });
    setTablePrice(selected[0]);
  }, [options, storeSettings]);

  function handleSave() {
    if (
      storeSettings &&
      customerRegister === !storeSettings.customer_register_approval &&
      pricePublic === storeSettings.price_public &&
      minimunValue === storeSettings.order_minimun_value &&
      online === storeSettings.online &&
      deliveryTime === storeSettings.delivery_time &&
      delivery === storeSettings.restrict_delivery_area &&
      tablePrice.value === storeSettings.table_prices_id &&
      cartVisible === storeSettings.cart_visible &&
      showAddress === storeSettings.show_address
    ) {
      return toast.info('Sem alterações');
    }
    return swal({
      title: 'Atenção?',
      text: 'Deseja realmente salvar as alterações?',
      icon: 'warning',
      buttons: ['Manter', 'Salvar'],
    }).then(async (save) => {
      if (save) {
        const storeConfig = {
          customerRegister: !customerRegister || false,
          pricePublic: pricePublic || false,
          minimunValue: parseFloat(minimunValue) || 0,
          online: online || false,
          deliveryTime,
          delivery: delivery || false,
          tablePrice: tablePrice.value,
          colorPrimary: storeSettings.color_primary,
          cardInfo: storeSettings.show_store_info_header,
          cartVisible,
          showAddress,
        };

        await updateStoreConfig(storeConfig);
      }
    });
  }

  function handleCustomerRegister(e) {
    setCustomerRegister(e.target.checked);
  }

  function handlePricePublic(e) {
    setPricePublic(e.target.checked);
  }

  function handleOnline(e) {
    setOnline(e.target.checked);
  }

  function handleDelivery(e) {
    setDelivery(e.target.checked);
  }

  function handleCartVisivle(e) {
    setCartVisible(e.target.checked);
  }

  function handleShowAddress(e) {
    setShowAddress(e.target.checked);
  }

  function handleMinimunPrice(text) {
    setMinimunValue(text);
  }

  function handleDeliveryTime(text) {
    setDeliveryTime(text);
  }

  function handleTablePrice(tp) {
    setTablePrice(tp);
  }

  return (
    <Container>
      <Card>
        <Body>
          <ConfigArea>
            <Box>
              <TitleConfig>
                <Tooltip
                  info="Caso habilitado, os clientes que realizarem o cadastro
                terão seus cadastros aprovados automaticamente."
                />
                <span>Tornar cadastro liberado</span>
              </TitleConfig>
              <CheckBox
                id="registerApproval"
                status={customerRegister}
                handleStatus={handleCustomerRegister}
              />
            </Box>
            <Box>
              <TitleConfig>
                <Tooltip
                  info="Caso habilitado, os preços estarão visíveis para os usuários que não
                efetuaram o login. Se não estiver habilitado, o usuário só poderá ver os preços ao
                realizar o login."
                />
                <span>Tornar preço publico</span>
              </TitleConfig>
              <CheckBox
                id="pricePublic"
                status={pricePublic}
                handleStatus={handlePricePublic}
              />
            </Box>
          </ConfigArea>

          <ConfigArea>
            <Box>
              <TitleConfig>
                <Tooltip info="Caso habilitado, sua loja pássara a ficar online/off-line." />
                <span>Tornar loja online/off-line</span>
              </TitleConfig>
              <CheckBox
                id="online"
                status={online}
                handleStatus={handleOnline}
              />
            </Box>
            <Box>
              <TitleConfig>
                <Tooltip
                  info="Caso habilitado, sua loja pássara a ser um delivery,
                 assim habilitando aos usuários o cadastro de endereços e verificação
                  de área de entrega."
                />
                <span>Restrição de área</span>
              </TitleConfig>
              <CheckBox
                id="delivery"
                status={delivery}
                handleStatus={handleDelivery}
              />
            </Box>
          </ConfigArea>
          <ConfigArea>
            <Box>
              <TitleConfig>
                <Tooltip info="Caso habilitado, ao iniciar o site o carrinho estará visível." />
                <span>Carrinho visível</span>
              </TitleConfig>
              <CheckBox
                id="cartVisible"
                status={cartVisible}
                handleStatus={handleCartVisivle}
              />
            </Box>
            <Box>
              <TitleConfig>
                <Tooltip info="Caso habilitado, seu enderço ficará visível para qualquer usuário da plataforma." />
                <span>Endereço visível</span>
              </TitleConfig>
              <CheckBox
                id="showAddress"
                status={showAddress}
                handleStatus={handleShowAddress}
              />
            </Box>
          </ConfigArea>
          <ConfigArea>
            <BoxResponsive>
              <TitleConfig>
                <Tooltip info="Informe aos usuários o tempo médio de entregas." />
                <span>Digite o tempo médio de entrega</span>
              </TitleConfig>
              <Input setState={handleDeliveryTime} value={deliveryTime} />
            </BoxResponsive>
            <BoxResponsive>
              <TitleConfig>
                <Tooltip info="Digite o valor mínimo do pedido, caso não haja deixe em branco. :)" />
                <span>Valor mínimo para do pedido.</span>
              </TitleConfig>
              <Input setState={handleMinimunPrice} value={minimunValue} />
            </BoxResponsive>
          </ConfigArea>
          <ConfigArea>
            <TablePrice>
              <TitleConfig>
                <Tooltip
                  info='Selecione a tabela de preços visível para usuários que não
              efetuaram o login. Para deixar a opção "Preço sob Consulta" basta seleciona
              a opção "sem tabela de preço". '
                />
                <span>Selecione a tabela de preços</span>
              </TitleConfig>
              <Select
                className="select"
                onChange={(e) => handleTablePrice(e)}
                placeholder={options[0].label}
                options={options}
                value={tablePrice}
              />
            </TablePrice>
          </ConfigArea>

          <ButtonArea>
            <Button onClick={() => handleSave()}>Salvar</Button>
          </ButtonArea>
        </Body>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ StoreActions }, dispatch);

const mapStateToProps = (state) => ({
  storeSettings: state.store.storeInfo && state.store.storeInfo.settings,
  tablePrices: state.products.tablePrices,
});
export default connect(mapStateToProps, mapDispatchToProps)(StoreConfig);
