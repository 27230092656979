import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swal from 'sweetalert';

import {
  FcNumericalSorting12,
  FcNumericalSorting21,
  FcAlphabeticalSortingAz,
  FcAlphabeticalSortingZa,
} from 'react-icons/fc';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineCloseCircle, AiOutlineClose } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import { IoMdAddCircle } from 'react-icons/io';

import history from '../../../../services/history';
import { getStoreSlug } from '../../../../utils/storeUtil';

import defaultImage from '../../../../assets/images/default.jpeg';

import * as ProductsActions from '../../../../store/modules/products/actions';

import {
  ButtonsEditDelete,
  ButtonsEditDeleteMobile,
} from '../../../components/Buttons';
import Loading from '../../../components/Loading';
import CheckBox from '../../../components/CheckBox';
import {
  SearchArea,
  Search,
  ProductsList,
  Product,
  ProductHeader,
  Sort,
  Photo,
  Id,
  Name,
  Code,
  Available,
  Delete,
  ProductPhoto,
  ProductId,
  ProductName,
  ProductCode,
  ProductDelete,
  OptionsArea,
  WithoutProducts,
  ButtonArea,
  Button,
} from './styles';

function Products({
  loadingProductsList,
  handleLoadingProductsList,
  getProducts,
  products,
  nextPage,
  deleteProduct,
  updateProductAvaiable,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [timeOut, setTimeOut] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    search: null,
    sort: 'id',
    direction: 'desc',
  });

  const [productSelected, setProductSelected] = useState(null);

  useEffect(() => {
    document.title = 'I9P - Produto';
  }, []);

  useEffect(() => {
    if (params.page === 1) handleLoadingProductsList(true);
    getProducts(params);
  }, [params, getProducts, handleLoadingProductsList]);

  function handleSortBy(field) {
    let direction = 'desc';
    if (field === params.sort)
      direction = params.direction === 'desc' ? 'asc' : 'desc';

    setParams({ ...params, page: 1, sort: field, direction });
  }

  function handleTextSearch(text) {
    clearTimeout(timeOut);

    setSearchInput(text);
    if (text.length <= 0) {
      setParams({ ...params, page: 1, search: null });
      return;
    }

    setTimeOut(
      setTimeout(() => {
        setParams({ ...params, page: 1, search: text });
      }, 900)
    );
  }

  function handleClear() {
    handleTextSearch('');
  }

  function handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (nextPage !== 0) {
        setParams({ ...params, page: nextPage });
      }
    }
  }

  function handleProduct(product) {
    history.push(`/${getStoreSlug()}/produtos/editar/${product.id}`);
  }

  function handleNewProduct() {
    history.push(`/${getStoreSlug()}/produtos/novo/`);
  }

  function handleDeleteProduct(product) {
    swal(`Deseja realmente excluir o produto ${product.name}?`, {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        deleteProduct(product.id);
      }
    });
  }

  function handleAvaiable(e, productId) {
    updateProductAvaiable(productId, e.target.checked);
  }

  return (
    <>
      <ButtonArea>
        <Button onClick={() => handleNewProduct()}>
          <IoMdAddCircle color="#fff" style={{ marginRight: '12px' }} />
          Novo produto
        </Button>
      </ButtonArea>
      <SearchArea>
        <FaSearch width="1.5rem" height="1.5rem" color="#78757E" />
        <Search
          onChange={(text) => handleTextSearch(text.target.value)}
          placeholder="Pesquise seu produto"
          value={searchInput}
        />
        {searchInput && searchInput.length > 0 && (
          <AiOutlineCloseCircle size={20} onClick={() => handleClear()} />
        )}
      </SearchArea>
      <ProductHeader>
        <Photo />
        <Id onClick={() => handleSortBy('id')}>
          <Sort>
            {params.sort === 'id' && params.direction === 'desc' ? (
              <Sort>
                <FcNumericalSorting21 />
                <span>ID</span>
              </Sort>
            ) : (
              <Sort>
                <FcNumericalSorting12 />
                <span>ID</span>
              </Sort>
            )}
          </Sort>
        </Id>
        <Name onClick={() => handleSortBy('name')}>
          <Sort>
            {params.sort === 'name' && params.direction === 'desc' ? (
              <Sort>
                <FcAlphabeticalSortingZa />
                <span>Nome</span>
              </Sort>
            ) : (
              <Sort>
                <FcAlphabeticalSortingAz />
                <span>Nome</span>
              </Sort>
            )}
          </Sort>
        </Name>
        <Code>
          <span>Código</span>
        </Code>
        <Available>
          <span>Status</span>
        </Available>
        <Delete />
      </ProductHeader>
      {loadingProductsList ? (
        <Loading />
      ) : (
        <>
          {products.length > 0 ? (
            <ProductsList onScroll={handleScroll}>
              {products.length > 0 &&
                products.map((product) => {
                  return (
                    <Product key={product.id}>
                      <ProductPhoto
                        onClick={() => handleProduct(product)}
                        image={
                          product.photos.length > 0
                            ? product.photos[0].thumb_url
                            : defaultImage
                        }
                      />
                      <ProductId onClick={() => handleProduct(product)}>
                        {product.id}
                      </ProductId>
                      <ProductName onClick={() => handleProduct(product)}>
                        <span>{product.name}</span>
                      </ProductName>
                      <ProductCode onClick={() => handleProduct(product)}>
                        <span>{product.code}</span>
                      </ProductCode>
                      <Available>
                        <CheckBox
                          handleStatus={(e) => handleAvaiable(e, product.id)}
                          id={product.id}
                          status={product.available}
                        />
                        {product.available ? 'Disponível' : 'Indisponível'}
                      </Available>
                      <ProductDelete>
                        <ButtonsEditDelete
                          handleEdit={handleProduct}
                          handleDelete={handleDeleteProduct}
                          data={product}
                        />
                      </ProductDelete>
                      <OptionsArea>
                        {productSelected === product ? (
                          <AiOutlineClose
                            onClick={() => setProductSelected(null)}
                          />
                        ) : (
                          <BsThreeDotsVertical
                            onClick={() => setProductSelected(product)}
                          />
                        )}
                        {productSelected === product && (
                          <ButtonsEditDeleteMobile
                            handleEdit={handleProduct}
                            handleDelete={handleDeleteProduct}
                            data={product}
                            right={120}
                          />
                        )}
                      </OptionsArea>
                    </Product>
                  );
                })}
            </ProductsList>
          ) : (
            <WithoutProducts>
              <span>Nenhum produto encontrado</span>
            </WithoutProducts>
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

const mapStateToProps = (state) => ({
  products: state.products.products,
  nextPage: state.products.nextPage,
  loadingProductsList: state.products.loadingProductsList,
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);
