import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: ${(props) => props.theme.dimensions.header.height};
  display: flex;
  align-items: center;
  padding: 0px 24px;
  position: fixed;
  background: #fff;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  z-index: 19;
  left: ${(props) => props.theme.dimensions.drawer.width};
  top: 0;

  h3 {
    color: ${(props) => props.theme.colors.blackLight};
    font-weight: 500;
    text-overflow: ellipsis;

    small {
      color: ${(props) => props.theme.colors.grayLight};
    }
  }

  svg {
    color: #6e7989;
    cursor: pointer;
    margin-right: 20px;
  }

  @media (max-width: 992px) {
    left: 0;
  }
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: flex;
  }
`;
