import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: auto;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 576px) {
    padding: 0px 0 50px 0;
  }
`;
