import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminLayout from './layouts/Admin';

import Login from './pages/Login';
import Orders from './pages/Orders';
import Delivery from './pages/Delivery';
import Settings from './pages/Settings';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import Categories from './pages/Categories';
import Customers from './pages/Customers';
import CustomersList from './pages/CustomersList';
import Dashboard from './pages/Dashboard';

// Errors
import WithOutStore from './pages/Error/WithOutStore';
import Error400 from './pages/Error/400';
import Error401 from './pages/Error/401';
import Error403 from './pages/Error/403';
import Error404 from './pages/Error/404';
import Error500 from './pages/Error/500';

/* eslint-disable react/jsx-props-no-spreading */
function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={WithOutStore} />
      <Route exact path="/:store" component={Login} />
      <RouteWrapper
        exact
        path="/:store/dashboard"
        component={Dashboard}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/pedidos"
        component={Orders}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/clientes"
        component={CustomersList}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/clientes/editar/:id"
        component={Customers}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/clientes/novo"
        component={Customers}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/categorias"
        component={Categories}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/produtos"
        component={Products}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/produtos/editar/:id"
        component={ProductDetail}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/produtos/novo"
        component={ProductDetail}
        layout={AdminLayout}
      />
      {/* <Route exact path="/:store/delivery" component={Delivery} /> */}
      <RouteWrapper
        exact
        path="/:store/delivery"
        component={Delivery}
        layout={AdminLayout}
      />
      <RouteWrapper
        exact
        path="/:store/configuracoes"
        component={Settings}
        layout={AdminLayout}
      />
      <Route exact path="/:store/erro/400" component={Error400} />
      <Route exact path="/:store/erro/401" component={Error401} />
      <Route exact path="/:store/erro/403" component={Error403} />
      <Route exact path="/:store/erro/404" component={Error404} />
      <Route exact path="/:store/erro/500" component={Error500} />
      <Route exact component={Error404} />
    </Switch>
  );
}
