import React from 'react';

import { InputStyled } from './styles';

function Input({
  id,
  length,
  type,
  placeholder,
  value,
  setState,
  required,
  disabled,
  pattern,
  autocomplete,
}) {
  return (
    <InputStyled
      id={id}
      required={required}
      maxLength={length}
      type={type}
      onChange={(text) => setState(text.target.value)}
      className="input"
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      pattern={pattern}
      autocomplete={autocomplete}
    />
  );
}

export default Input;
