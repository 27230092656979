import React, { useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import * as OrdersActions from '../../../../store/modules/orders/actions';
import { formatPrice } from '../../../../utils/format';

import {
  Container,
  ListOrders,
  Header,
  ArrayList,
  ContainerOrder,
  Cart,
  Order,
  Body,
  Id,
  Name,
  Date,
  Total,
  StatusColor,
  OnlinePaymentsArea,
  FooterMobile,
} from './styles';

function List({
  getOrders,
  orders,
  getOrderItem,
  handleOrderItemsVisible,
  priceDecimals,
  nextPage,
}) {
  const [orderRef, setOrderRef] = useState(orders[0]);

  async function handleOrderIitem(order) {
    setOrderRef(order);
    await getOrderItem(order.id);

    if (window.innerWidth < 992) {
      handleOrderItemsVisible(true);
    }
  }

  function handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (nextPage !== 0) {
        getOrders(nextPage);
      }
    }
  }

  return (
    <Container>
      {orders.length <= 0 ? (
        <> </>
      ) : (
        <ListOrders>
          <Header>
            <Id>ID </Id>
            <Name>Nome </Name>
            <Date>Data </Date>
            <Total>Total </Total>
          </Header>
          <ArrayList onScroll={(e) => handleScroll(e)}>
            {orders.map((order) => {
              return (
                <ContainerOrder key={order.id}>
                  <Order
                    style={{ background: '#fff' }}
                    key={order.id}
                    onClick={() => handleOrderIitem(order)}
                    orderRef={orderRef}
                    order={order}
                  >
                    <Cart>
                      <Body orderRef={orderRef} order={order}>
                        <Id>#{order.id}</Id>
                        <Name>{order.customer_name}</Name>
                        <Date>
                          {format(
                            new window.Date(order.created_at),
                            'dd/MM/yyyy'
                          )}
                        </Date>
                        <Total>
                          {formatPrice(order.value_total, priceDecimals)}
                        </Total>
                      </Body>
                      <StatusColor
                        status={order.status.toLowerCase()}
                        orderRef={orderRef}
                        order={order}
                      />
                    </Cart>
                    {order.onlie_payment_status_id && (
                      <OnlinePaymentsArea
                        status={order.online_payment_status_name}
                      >
                        {order.online_payment_method_name} -{' '}
                        {order.online_payment_status_name}
                      </OnlinePaymentsArea>
                    )}
                    <FooterMobile
                      orderRef={orderRef}
                      order={order}
                      status={order.status.toLowerCase()}
                    >
                      <div>
                        <span className="id">#{order.id}</span>
                      </div>
                      <div>
                        <span>{order.status}</span>
                      </div>
                      <div />
                    </FooterMobile>
                  </Order>
                </ContainerOrder>
              );
            })}
          </ArrayList>
        </ListOrders>
      )}
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrdersActions, dispatch);

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  nextPage: state.orders.nextPage,
  priceDecimals: state.store.storeInfo
    ? state.store.storeInfo.settings.price_decimals
    : 2,
});
export default connect(mapStateToProps, mapDispatchToProps)(List);
