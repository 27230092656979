import produce from 'immer';

const INITIAL_STATE = {
  nextPage: 1,
  modalArea: false,
  deliveryAreas: [],
  currentDeliveryArea: {},
  modalEditDelivery: false,
};

export default function delivery(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'HANDLE_MODAL_AREA': {
        draft.modalArea = action.state;
        break;
      }
      case 'SET_DELIVERY_AREAS': {
        draft.nextPage = action.payload.nextPage;
        if (action.payload.nextPage > 2 || action.payload.nextPage === 0) {
          draft.deliveryAreas = state.deliveryAreas.concat(
            action.payload.deliveryAreas
          );
          break;
        }
        if (action.payload.nextPage === 2) {
          draft.deliveryAreas = action.payload.deliveryAreas;
        }
        break;
      }
      case 'HANDLE_EDIT_DELIVERY': {
        draft.modalEditDelivery = action.state;
        break;
      }
      case 'HANDLE_CURRENT_DELIVERY': {
        draft.currentDeliveryArea = action.currentDeliveryArea;
        break;
      }
      case 'UPDATE_STATUS_DELIVERY': {
        const deliveryIndex = draft.deliveryAreas.findIndex((da) => {
          return da.id === action.payload.deliveryArea.id;
        });

        if (deliveryIndex >= 0) {
          draft.deliveryAreas[deliveryIndex].max_waiting_time =
            action.payload.deliveryArea.max_waiting_time;
          draft.deliveryAreas[deliveryIndex].max_waiting_type =
            action.payload.deliveryArea.max_waiting_type;
          draft.deliveryAreas[deliveryIndex].min_waiting_time =
            action.payload.deliveryArea.min_waiting_time;
          draft.deliveryAreas[deliveryIndex].min_waiting_type =
            action.payload.deliveryArea.min_waiting_type;
          draft.deliveryAreas[deliveryIndex].name =
            action.payload.deliveryArea.name;
          draft.deliveryAreas[deliveryIndex].price =
            action.payload.deliveryArea.price;
          draft.deliveryAreas[deliveryIndex].status =
            action.payload.deliveryArea.status;
        }
        break;
      }
      case 'ADD_DELIVERY_AREA': {
        draft.deliveryAreas.push(action.payload.deliveryArea);
        break;
      }
      default:
    }
  });
}
