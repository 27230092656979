import React from 'react';

import ReactTooltip from 'react-tooltip';

import { FcInfo } from 'react-icons/fc';

import { Container } from './styles';

function Tooltip({ info }) {
  return (
    <Container data-tip={info}>
      <ReactTooltip
        className="tooltip"
        place="top"
        type="info"
        effect="solid"
      />
      <FcInfo size={20} />
    </Container>
  );
}

export default Tooltip;
