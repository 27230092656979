import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdAddCircle } from 'react-icons/io';

import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import * as ProductsActions from '../../store/modules/products/actions';

import history from '../../services/history';
import { getStoreSlug } from '../../utils/storeUtil';
import { formatPrice } from '../../utils/format';

import ProductPhoto from './components/ProductPhoto';
import ModalTablePrice from './components/ModalTablePrice';

import Loading from '../components/Loading';
import { Title } from '../components/Title';
import Subtitle from '../components/Subtitle';
import Label from '../components/Label';
import Input from '../components/Input';
import InputMask from '../components/InputMask';

import {
  ButtonsEditDelete,
  ButtonsEditDeleteMobile,
} from '../components/Buttons';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  Container,
  ContentContainer,
  Card,
  Section,
  Box,
  Area,
  SelectArea,
  ButtonCreateCategory,
  ButtonArea,
  ButtonCancel,
  SubmitButton,
  TablePricesHeader,
  TablePricesField,
  TablePricesOptions,
  TablePricesOptionsMobile,
  TablePricesData,
  WithOutPrices,
  ButtonPlus,
} from './styles';

function ProductDetail({
  loadingProductsList,
  handleLoadingProductsList,
  getCategories,
  getTablePrices,
  getProduct,
  currentProduct,
  setCurrentProduct,
  currentProductPrices,
  setCurrentProductPrices,
  updateProduct,
  createProduct,
  createCategory,
  options,
  tablePrices,
  priceDecimals,
  setCurrentProductPrice,
  handleModalTablePrice,
  deleteProductTablePrice,
  removeProductTablePrice,
}) {
  const { id } = useParams();
  const [categoryId, setCategoryId] = useState('');
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [multiple, setMultiple] = useState(1);
  const [unitDescription, setUnitDescription] = useState('');
  const [weight, setWeight] = useState(null);
  const [length, setLength] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [currentTable, setCurrentTable] = useState(null);
  const [editorState, setEditorState] = useState(null);

  const toolbarConfig = {
    options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'history'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
  };

  useEffect(() => {
    handleLoadingProductsList(true);
    getCategories();
    getTablePrices();

    if (!id) {
      setCurrentProduct(null);
      setCurrentProductPrices([]);
      handleLoadingProductsList(false);
      return;
    }

    getProduct(id);

    document.title = 'I9P - Detalhes do produto';
  }, [
    id,
    getProduct,
    getCategories,
    getTablePrices,
    handleLoadingProductsList,
    setCurrentProduct,
    setCurrentProductPrices,
  ]);

  useEffect(() => {
    setName(currentProduct?.name || '');
    setCode(currentProduct?.code || '');
    setDescription(currentProduct?.description || '');
    setMultiple(currentProduct?.multiple || 1);
    setUnitDescription(currentProduct?.unit_description || '');
    setWeight(currentProduct?.weight || null);
    setLength(currentProduct?.length || null);
    setHeight(currentProduct?.height || null);
    setWidth(currentProduct?.width || null);
    setEditorState(
      currentProduct
        ? EditorState.createWithContent(
            stateFromHTML(currentProduct.description)
          )
        : EditorState.createEmpty()
    );
    setCurrentProductPrices(currentProduct?.prices || []);
  }, [currentProduct, setCurrentProductPrices]);

  useEffect(() => {
    if (currentProduct && options && options.length > 0) {
      const categoryFilter = options.filter((ct) => {
        return ct.value === currentProduct.CategoryId;
      });

      if (categoryFilter && categoryFilter.length > 0) {
        setCategory(categoryFilter[0]);
        setCategoryId(categoryFilter[0].value);
      }
    }
  }, [currentProduct, options]);

  function handleEditProduct(e) {
    e.preventDefault();

    if (!categoryId) {
      return toast.error('A categoria do produto é obrigatório.');
    }
    if (!name) {
      return toast.error('O campo nome é obrigatório.');
    }
    if (!multiple) {
      return toast.error('O campo multiplo é obrigatório.');
    }

    if (weight && !weight.match(/[0-9]{1}.[0-9]{3}/g)) {
      return toast.error('O campo peso está em formato inválido.');
    }

    if (length) length.replace(/\D/g, '');
    if (height) height.replace(/\D/g, '');
    if (width) width.replace(/\D/g, '');

    if (currentProduct) {
      if (
        currentProduct.category_id === categoryId &&
        currentProduct.name === name &&
        currentProduct.code === code &&
        currentProduct.description === description &&
        currentProduct.multiple === multiple &&
        currentProduct.unit_description === unitDescription &&
        currentProduct.weight === weight &&
        currentProduct.length === length &&
        currentProduct.height === height &&
        currentProduct.width === width
      ) {
        return toast.info('Sem alterações.');
      }

      return updateProduct({
        id: currentProduct.id,
        category_id: categoryId,
        name,
        code,
        description,
        multiple,
        unitDescription,
        weight,
        length,
        height,
        width,
      });
    }

    return createProduct({
      category_id: categoryId,
      name,
      code,
      multiple,
      unitDescription,
      description,
      weight,
      length,
      height,
      width,
      prices: currentProductPrices,
    });
  }

  function handleChange(e) {
    setCategory(e);
    setCategoryId(e.value);
  }

  function handleCreateCategory() {
    swal({
      text: 'Digite o nome da categoria que deseja criar.',
      content: 'input',
      button: {
        text: 'Criar',
      },
    }).then((categoryName) => {
      if (!categoryName) return null;
      return createCategory(categoryName);
    });
  }

  function onEditorStateChange(e) {
    setDescription(draftToHtml(convertToRaw(e.getCurrentContent())));
  }

  function handleTablePriceName(tablePriceId) {
    const tablePrice = tablePrices?.filter((tb) => {
      return tb.id === tablePriceId;
    });
    return tablePrice?.length > 0 ? tablePrice[0].name : '';
  }

  function handleCreatePrice() {
    handleModalTablePrice(true);
    setCurrentProductPrice(null);
  }

  function handleEditPrice(data) {
    handleModalTablePrice(true);
    setCurrentProductPrice(data.price);
  }

  function handleDeletePrice(data) {
    if (!data.currentProduct) {
      return removeProductTablePrice(data.price.tempId);
    }
    const tablePriceName = handleTablePriceName(data.price.table_prices_id);
    swal(
      `Deseja realmente excluir esse produto da tabela "${tablePriceName}"?`,
      {
        icon: 'warning',
        dangerMode: true,
        buttons: ['Cancelar', 'Excluir'],
      }
    ).then((response) => {
      if (response) {
        deleteProductTablePrice(
          data.price.table_prices_id,
          data.currentProduct.id
        );
      }
    });

    return true;
  }

  return (
    <Container>
      <ModalTablePrice />
      <ContentContainer>
        {currentProduct ? (
          <Title text="Editar produto" />
        ) : (
          <Title text="Cadastrar novo produto" />
        )}
        {loadingProductsList ? (
          <Loading />
        ) : (
          <Card create={!currentProduct}>
            {currentProduct && (
              <Section>
                <Subtitle>Fotos</Subtitle>
                <ProductPhoto />
              </Section>
            )}

            <Section>
              <Subtitle>Dados do produto</Subtitle>
              <Box>
                <Area flex={1}>
                  <Label>Categoria do produto</Label>
                  <SelectArea>
                    <Select
                      className="select"
                      onChange={handleChange}
                      placeholder="Selecione..."
                      options={options}
                      value={category}
                    />
                    <ButtonCreateCategory
                      onClick={() => handleCreateCategory()}
                    >
                      <AiOutlinePlus />
                    </ButtonCreateCategory>
                  </SelectArea>
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>Nome</Label>
                  <Input value={name || ''} setState={setName} />
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>Código</Label>
                  <Input value={code || ''} setState={setCode} />
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>Multiplo</Label>
                  <Input value={multiple || ''} setState={setMultiple} />
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>Descrição da unidade</Label>
                  <Input
                    value={unitDescription || ''}
                    setState={setUnitDescription}
                  />
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>Descrição</Label>
                  <Editor
                    defaultEditorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorStyle={{ border: '1px solid #eee' }}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={toolbarConfig}
                  />
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>Peso (kg)</Label>
                  <InputMask
                    mask="9.999"
                    pattern="([0-9]{1}.[0-9]{3})"
                    maskPlaceholder="0.000"
                    value={weight || ''}
                    setState={setWeight}
                  />
                </Area>
                <Area flex={1}>
                  <Label>Comprimento (cm)</Label>
                  <Input
                    type="number"
                    pattern="[0-9]"
                    value={length || ''}
                    setState={setLength}
                  />
                </Area>
                <Area flex={1}>
                  <Label>Altura (cm)</Label>
                  <Input
                    type="number"
                    value={height || ''}
                    setState={setHeight}
                  />
                </Area>
                <Area flex={1}>
                  <Label>Largura (cm)</Label>
                  <Input
                    type="number"
                    value={width || ''}
                    setState={setWidth}
                  />
                </Area>
              </Box>
            </Section>
            <Section>
              <Subtitle>Preços</Subtitle>
              <TablePricesHeader>
                <TablePricesField flex={2}>Tabela de preço</TablePricesField>
                <TablePricesField flex={2}>Preço</TablePricesField>
                <TablePricesField flex={2}>Preço promocional</TablePricesField>
                <TablePricesOptions />
              </TablePricesHeader>
              <Area flex={1}>
                {currentProductPrices.length > 0 ? (
                  currentProductPrices.map((price) => {
                    return (
                      <TablePricesData
                        key={price.tempId ? price.tempId : price.id}
                      >
                        <TablePricesField flex={2}>
                          <span>
                            {handleTablePriceName(price.table_prices_id)}
                          </span>
                        </TablePricesField>
                        <TablePricesField flex={2}>
                          <span>
                            {formatPrice(price.regular_price, priceDecimals) ||
                              'Sem preço'}
                          </span>
                        </TablePricesField>
                        <TablePricesField flex={2}>
                          <span>
                            {price.promotional_price &&
                            price.promotional_price !== 0
                              ? formatPrice(
                                  price.promotional_price,
                                  priceDecimals
                                )
                              : 'Sem preço promocional'}
                          </span>
                        </TablePricesField>
                        <TablePricesOptions>
                          <ButtonsEditDelete
                            handleEdit={handleEditPrice}
                            handleDelete={handleDeletePrice}
                            data={{ price, currentProduct }}
                          />
                        </TablePricesOptions>
                        <TablePricesOptionsMobile>
                          {currentTable === price ? (
                            <AiOutlineClose
                              onClick={() => setCurrentTable(null)}
                            />
                          ) : (
                            <BsThreeDotsVertical
                              onClick={() => setCurrentTable(price)}
                            />
                          )}

                          {currentTable === price && (
                            <ButtonsEditDeleteMobile
                              handleEdit={handleEditPrice}
                              handleDelete={handleDeletePrice}
                              data={{ price, currentProduct }}
                              right={80}
                            />
                          )}
                        </TablePricesOptionsMobile>
                      </TablePricesData>
                    );
                  })
                ) : (
                  <WithOutPrices>Nenhum preço cadastrado.</WithOutPrices>
                )}
              </Area>
              <ButtonArea>
                <ButtonPlus type="button" onClick={() => handleCreatePrice()}>
                  <IoMdAddCircle />
                  <span>Adicionar novo preço</span>
                </ButtonPlus>
              </ButtonArea>
            </Section>
            <hr
              style={{
                width: '100%',
                height: '2px',
                borderWidth: '0',
                color: '#dee2e6',
                backgroundColor: '#dee2e6',
              }}
            />

            <ButtonArea>
              <ButtonCancel
                onClick={() => history.push(`/${getStoreSlug()}/produtos`)}
              >
                Cancelar
              </ButtonCancel>
              <SubmitButton onClick={(e) => handleEditProduct(e)}>
                Salvar
              </SubmitButton>
            </ButtonArea>
          </Card>
        )}
      </ContentContainer>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...ProductsActions }, dispatch);

const mapStateToProps = (state) => ({
  loadingProductsList: state.products.loadingProductsList,
  currentProduct: state.products.currentProduct,
  currentProductPrices: state.products.currentProductPrices,
  options: state.products.options,
  tablePrices: state.products.tablePrices,
  priceDecimals: state.store.storeInfo
    ? state.store.storeInfo.settings.price_decimals
    : 2,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
