import styled from 'styled-components';

export const InputStyled = styled.input`
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  transition: border 0.6s;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  height: calc(2.25rem + 2px);
  /* margin-top: 5px; */

  &:focus {
    border: 1px solid #727c8e;
  }

  &::placeholder {
    color: #adb5be;
  }
`;
