export function getStoreInfo() {
  return {
    type: 'GET_STORE_INFO',
  };
}

export function setStoreInfo(store) {
  return {
    type: 'SET_STORE_INFO',
    payload: store,
  };
}

export function findZipCode(zipCode) {
  return {
    type: 'FIND_ZIP_CODE',
    zipCode,
  };
}
export function setStoreAddress(address) {
  return {
    type: 'SET_STORE_ADDRESS',
    address,
  };
}

export function updateStoreInfo(store) {
  return {
    type: 'UPDATE_STORE_INFO',
    store,
  };
}

export function updateSocialMedia(socialMedia) {
  return {
    type: 'UPDATE_SOCIAL_MEDIA',
    payload: { socialMedia },
  };
}

export function updateStoreConfig(store) {
  return {
    type: 'UPDATE_STORE_CONFIG',
    store,
  };
}

export function updateStoreLogo(logo) {
  return {
    type: 'UPDATE_STORE_LOGO',
    logo,
  };
}

export function deleteStoreLogo() {
  return {
    type: 'DELETE_STORE_LOGO',
  };
}

export function updateStoreBanner(banner) {
  return {
    type: 'UPDATE_STORE_BANNER',
    banner,
  };
}

export function deleteStoreBanner() {
  return {
    type: 'DELETE_STORE_BANNER',
  };
}
export function updateStoreBannerMobile(bannerMobile) {
  return {
    type: 'UPDATE_STORE_BANNER_MOBILE',
    payload: { bannerMobile },
  };
}

export function deleteStoreBannerMobile() {
  return {
    type: 'DELETE_STORE_BANNER_MOBILE',
  };
}

export function updateStoreWaterMark(waterMark) {
  return {
    type: 'UPDATE_STORE_WATERMARK',
    waterMark,
  };
}

export function deleteStoreWaterMark() {
  return {
    type: 'DELETE_STORE_WATERMARK',
  };
}

export function handleLoadingStore(state) {
  return {
    type: 'HANDLE_LOADING_STORE',
    payload: { state },
  };
}

export function updatePeriodInfo(workPeriod) {
  return {
    type: 'UPDATE_PERIOD_INFO',
    payload: { workPeriod },
  };
}

export function getDeliveryPaymentMethods() {
  return {
    type: 'GET_DELIVERY_PAYMENT_METHODS',
  };
}

export function createOrUpdateDeliveryPaymentMethods(deliveryPaymentMethods) {
  return {
    type: 'CREATE_OR_UPDATE_DELIVERY_PAYMENT_METHODS',
    payload: { deliveryPaymentMethods },
  };
}
export function getDeliveryPaymentCards() {
  return {
    type: 'GET_DELIVERY_PAYMENT_CARDS',
  };
}

export function createOrUpdateDeliveryPaymentCards(cards) {
  return {
    type: 'CREATE_OR_UPDATE_DELIVERY_PAYMENT_CARDS',
    payload: { cards },
  };
}

export function updateDeliveryPaymentCards(deliveryPaymentCards) {
  return {
    type: 'UPDATE_DELIVERY_PAYMENT_CARDS',
    payload: { deliveryPaymentCards },
  };
}

export function setDeliveryPaymentMethods(deliveryPaymentMethods) {
  return {
    type: 'SET_DELIVERY_PAYMENT_METHODS',
    payload: { deliveryPaymentMethods },
  };
}
export function setDeliveryPaymentCards(deliveryPaymentCards) {
  return {
    type: 'SET_DELIVERY_PAYMENT_CARDS',
    payload: { deliveryPaymentCards },
  };
}

export function createNewCard(card, type, deliveryPaymentMethodsId) {
  return {
    type: 'CREATE_CARD',
    payload: { card, type, deliveryPaymentMethodsId },
  };
}
