export function ignoreCase(str) {
  return str.toLowerCase();
}

/* eslint-disable no-unused-expressions, no-undef, no-restricted-globals, no-cond-assign, eqeqeq, radix, no-sequences, no-console */
export function maskCurrency(n, noCurrency = true, c, d, t) {
  try {
    let s;
    let i;
    let j;
    const currency = noCurrency ? 'R$ ' : '';
    (c = isNaN((c = Math.abs(c))) ? 2 : c),
      (d = d == undefined ? ',' : d),
      (t = t == undefined ? '.' : t),
      (s = n < 0 ? '-' : ''),
      (i = `${parseInt((n = Math.abs(+n || 0).toFixed(c)))}`),
      (j = (j = i.length) > 3 ? j % 3 : 0);
    return `${currency}${
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : '')
    }`;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function unmaksCurrency(n, c) {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  let monetary = n.replace(/\D/g, '');
  monetary = `${(monetary / 100).toFixed(c)}`;
  return monetary;
}

export const maskCPF = (cpf) => {
  try {
    if (cpf === undefined || cpf === null || cpf === '') return cpf;
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return cpf;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export function maskDate(date) {
  try {
    date = date.replace(/\D/g, '').slice(0, 10);
    date = date.replace(/(\d{2})(\d)/, '$1/$2');
    date = date.replace(/(\d{2})(\d)/, '$1/$2');
    return date;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const formatPhone = (phone = '') => {
  try {
    if (phone === null || phone === undefined || phone.includes('null'))
      return '';

    phone = phone.replace(/\D/g, '');
    phone = phone.replace(/(\d{0})(\d)/, '($1$2');
    phone = phone.replace(/(\d{2})(\d)/, '$1) $2');
    phone = phone.replace(/(\d{5})(\d{1,4})$/, '$1-$2');
    return phone;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export function maskOnlyNumber(text) {
  return text.replace(/\D/g, '');
}

export function handleMonetaryMask(value) {
  try {
    let monetary = value.replace(/\D/g, '');
    monetary = `${(monetary / 100).toFixed(2)}`;
    monetary = monetary.replace('.', ',');
    monetary = monetary.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    monetary = monetary.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return monetary;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function handleMonetaryMaskWithRS(value) {
  try {
    console.log('VALOR INPUTADO', value);
    let monetary = value.replace(/\D/g, '');
    monetary = `${(monetary / 100).toFixed(2)}`;
    monetary = monetary.replace('.', ',');
    monetary = monetary.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    monetary = monetary.replace(/(\d)(\d{3}),/g, '$1.$2,');
    console.log('VALOR OUTPUT', monetary);
    return `R$ ${monetary}`;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function maskCPFeCNPJ(number) {
  number = number.replace(/\D/g, '');
  if (number.length > 11) {
    number = number.replace(/^(\d{2})(\d)/, '$1.$2');
    number = number.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    number = number.replace(/\.(\d{3})(\d)/, '.$1/$2');
    number = number.replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    number = number.replace(/\D/g, '');
    number = number.replace(/(\d{3})(\d)/, '$1.$2');
    number = number.replace(/(\d{3})(\d)/, '$1.$2');
    number = number.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return number;
}

export function maskZipCode(zipcode) {
  zipcode = zipcode.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
  return zipcode;
}
