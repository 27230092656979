import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import store from './store/sagas';
import orders from './orders/sagas';
import delivery from './delivery/sagas';
import products from './products/sagas';
import customers from './customer/sagas';
import dashboard from './dashboard/sagas';
import alerts from './alerts/sagas';
import option from './option/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    store,
    orders,
    delivery,
    products,
    customers,
    dashboard,
    alerts,
    option,
  ]);
}
