export function getOptions() {
  return {
    type: 'GET_OPTIONS',
  };
}

export function setVariations(variations) {
  return {
    type: 'SET_VARIATIONS',
    payload: { variations },
  };
}
export function setOptions(options) {
  return {
    type: 'SET_OPTIONS',
    payload: { options },
  };
}

export function handleModalOption(status, isVariation) {
  return {
    type: 'HANDLE_MODAL_OPTION',
    payload: { status, isVariation },
  };
}

export function putVariations(variation) {
  return {
    type: 'PUT_VARIATION',
    payload: { variation },
  };
}

export function putOptions(option) {
  return {
    type: 'PUT_OPTION',
    payload: { option },
  };
}

export function createOption(group, items) {
  return {
    type: 'CREATE_OPTION',
    payload: { group, items },
  };
}
