import React from 'react';

import Values from './components/Values';
import { Container, Body } from './styles';

export default function Dashboard() {
  return (
    <Container>
      <Body>
        <Values />
      </Body>
    </Container>
  );
}
