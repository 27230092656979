import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { getStoreSlug } from '../utils/storeUtil';
// import expireReducer from 'redux-persist-expire';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: `i9p:painel:${getStoreSlug()}`,
      storage,
      whitelist: ['user', 'auth', 'store'],
    },

    reducers
  );

  return persistedReducer;
};
