import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { format } from 'date-fns';

import Select from 'react-select';

import chroma from 'chroma-js';
import swal from 'sweetalert';

import { FaRegUser, FaBoxes, FaShippingFast } from 'react-icons/fa';
import { RiFilePaper2Line } from 'react-icons/ri';
import { HiOutlineAnnotation } from 'react-icons/hi';
import { AiOutlineCreditCard } from 'react-icons/ai';

import { formatPrice } from '../../../../utils/format';

import * as OrdersActions from '../../../../store/modules/orders/actions';

import Loading from '../../../components/Loading/index';
import {
  Container,
  CardArea,
  Card,
  Order,
  IdArea,
  Id,
  Date,
  Status,
  TrackingCodeArea,
  TrackingCode,
  TrackingCodeMobile,
  SubTitle,
  Customer,
  Person,
  Name,
  Document,
  Phone,
  Email,
  Address,
  DeliveryPaymentMethod,
  PaymentMethod,
  OnlinePaymentMethod,
  Online,
  ObservationArea,
  Observation,
  Products,
  Product,
  ProductHeader,
  Item,
  Amount,
  ProductName,
  ProductDescription,
  ProductUnitDescription,
  Value,
  TotalMobile,
  Total,
} from './styles';

function OrderItem({
  orderItem,
  updateStatusOrder,
  loadingProducts,
  orderItemsVisible,
  priceDecimals,
  setModalTrackingOrderVisible,
  getTrackingOrder,
}) {
  const item = orderItem;

  const options = [
    { value: 'Aprovado', label: 'APROVADO', color: '#b2dbb2' },
    { value: 'Pendente', label: 'PENDENTE', color: '#fcf1de' },
    { value: 'Cancelado', label: 'CANCELADO', color: '#edafa4' },
  ];

  function verifyColor() {
    if (item.status.toLowerCase() === 'aprovado') return '#b2dbb2';
    if (item.status.toLowerCase() === 'pendente') return '#fcf1de';
    return '#edafa4';
  }

  function handleChange(e) {
    swal({
      title: 'Atenção!',
      html: true,
      text: `Deseja realmente alterar o status do pedido?`,
      icon: 'warning',
      buttons: ['Cancelar', 'Sim!'],
    }).then((response) => {
      if (response) {
        updateStatusOrder(e.value, item.id);
      }
    });
  }
  const dot = (
    color = item &&
      item.status &&
      chroma(verifyColor()).darken().saturate(10).hex()
  ) => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
    }),
    option: (styles, { data, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? data.color : 'white',
        color: 'black',
        cursor: 'default',
        ':hover': {
          backgroundColor: data.color,
          color: 'black',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? data.color : '#fff',
        },
      };
    },
    placeholder: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  function handleTrackingOrder(trackingCode) {
    getTrackingOrder(trackingCode);
    setModalTrackingOrderVisible(true);
  }

  function getDelveryType(deliveryType, deliveryMethod) {
    switch (deliveryType) {
      case 'correios':
        return deliveryMethod ? `Correios - ${deliveryMethod}` : 'Correios';
      case 'onw_delivery':
        return deliveryMethod
          ? `Entrega própria - ${deliveryMethod}`
          : 'Entrega própria';
      case 'pick_up':
        return deliveryMethod
          ? `Retirar na loja - ${deliveryMethod}`
          : 'Retirar na loja';
      default:
        return '';
    }
  }

  return (
    <Container width={window.innerWidth} orderItemsVisible={orderItemsVisible}>
      <CardArea>
        {loadingProducts ? (
          <Loading />
        ) : (
          <Card>
            <SubTitle>
              <RiFilePaper2Line />
              <span>Informações do pedido</span>
            </SubTitle>
            <Order>
              <IdArea>
                <Id>
                  <span>Pedido: </span>
                  <strong>#{item && item.id}</strong>
                </Id>
                <Date>
                  <span>Data:</span>
                  <strong>
                    {item &&
                      format(new window.Date(item.created_at), 'dd/MM/yyyy')}
                  </strong>
                </Date>
                <Date>
                  <span>Total: </span>
                  <strong>{item && formatPrice(item.value_total, 2)}</strong>
                </Date>
                {item?.tracking_code && (
                  <TrackingCodeArea>
                    <span>Código de rastreio: </span>
                    <TrackingCode
                      onClick={() => handleTrackingOrder(item?.tracking_code)}
                    >
                      {item && item.tracking_code}
                    </TrackingCode>
                  </TrackingCodeArea>
                )}
              </IdArea>
              {item?.tracking_code && (
                <TrackingCodeMobile>
                  <span>Código de rastreio: </span>
                  <TrackingCode
                    onClick={() => handleTrackingOrder(item?.tracking_code)}
                  >
                    {item && item.tracking_code}
                  </TrackingCode>
                </TrackingCodeMobile>
              )}
              <Status>
                <span>Status:</span>
                <Select
                  className="select"
                  onChange={handleChange}
                  defaultValue={item && item.status}
                  placeholder={item && item.status && item.status.toUpperCase()}
                  options={options}
                  styles={colourStyles}
                />
              </Status>
            </Order>
            <SubTitle>
              <FaRegUser />
              <span>Informações do cliente</span>
            </SubTitle>
            <Customer>
              <Person>
                <Name>{item ? item.customer_name : 'name'}</Name>
                <Document>{item ? item.customer_document : ''}</Document>
                <Phone>{item ? item.customer_phone : ''}</Phone>
                <Email>{item ? item.customer_email : ''}</Email>
              </Person>
              {item && (
                <Address>
                  {item.customer_address_zip_code &&
                    item.customer_address_city && <strong>Endereço</strong>}
                  {item.customer_address_zip_code && (
                    <span>CEP: {item.customer_address_zip_code}. </span>
                  )}
                  {item.customer_address_street &&
                    item.customer_address_number && (
                      <span>
                        {item.customer_address_street},{' '}
                        {item.customer_address_number}
                      </span>
                    )}
                  {item.customer_address_neighborhood && (
                    <span>{item.customer_address_neighborhood}, </span>
                  )}
                  {item.customer_address_city && item.customer_address_state && (
                    <span>
                      {item.customer_address_city},{' '}
                      {item.customer_address_state}
                    </span>
                  )}
                </Address>
              )}
            </Customer>

            {item?.delivery_payment_method && (
              <DeliveryPaymentMethod>
                <SubTitle>
                  <AiOutlineCreditCard />
                  <span>Forma de pagamento na entrega</span>
                </SubTitle>
                <PaymentMethod>
                  {item?.delivery_payment_method} -{' '}
                  {item?.delivery_payment_card ||
                    `Troco para ${formatPrice(
                      item?.delivery_payment_change,
                      2
                    )}.`}
                </PaymentMethod>
              </DeliveryPaymentMethod>
            )}
            {item.onlie_payment_status_id && (
              <OnlinePaymentMethod>
                <SubTitle>
                  <AiOutlineCreditCard />
                  <span>Forma de pagamento online</span>
                </SubTitle>
                <Online status={item.online_payment_status_name}>
                  {item?.online_payment_method_name} -{' '}
                  {item.online_payment_status_name}
                </Online>
              </OnlinePaymentMethod>
            )}

            {item?.delivery_type && (
              <ObservationArea>
                <SubTitle>
                  <FaShippingFast />
                  <span>Forma de entrega</span>
                </SubTitle>
                <Observation>
                  {getDelveryType(
                    item?.delivery_type,
                    item?.delivery_method_name
                  ) &&
                    `Tipo de entrega: ${getDelveryType(
                      item?.delivery_type,
                      item?.delivery_method_name
                    )}`}
                  <br />
                  <br />
                  {`Taxa de entrega: ${formatPrice(
                    item?.value_delivery_tax,
                    2
                  )}`}
                </Observation>
              </ObservationArea>
            )}

            {item?.customer_observation && (
              <ObservationArea>
                <SubTitle>
                  <HiOutlineAnnotation />
                  <span>Obserção do pedido</span>
                </SubTitle>
                <Observation>{item?.customer_observation}</Observation>
              </ObservationArea>
            )}
            <SubTitle>
              <FaBoxes />
              <span>Produtos</span>
            </SubTitle>
            <ProductHeader>
              <Item>Item</Item>
              <Amount>QTDE</Amount>
              <ProductName>Nome</ProductName>
              <ProductDescription>Código</ProductDescription>
              <ProductUnitDescription>Unidade</ProductUnitDescription>
              <Value>Preço</Value>
            </ProductHeader>
            <Products>
              {item &&
                item.products &&
                item.products.length > 0 &&
                item.products.map((product, index) => {
                  return (
                    <Product key={product.id}>
                      <Item>
                        <span>{index + 1} - </span>
                      </Item>
                      <Amount>
                        <span>{product.amount}</span>
                      </Amount>
                      <ProductName>
                        <span>{product.product_name}</span>
                      </ProductName>
                      <ProductDescription>
                        <span>{product.product_code}</span>
                      </ProductDescription>
                      <ProductUnitDescription>
                        <span>{product.product_unit_description}</span>
                      </ProductUnitDescription>
                      <Value>
                        <span>
                          {formatPrice(product.value_unitary, priceDecimals)}
                        </span>
                      </Value>
                    </Product>
                  );
                })}
              <TotalMobile>
                <span>Total</span>
                <span>
                  {item &&
                    formatPrice(item.value_total_products, priceDecimals)}
                </span>
              </TotalMobile>
            </Products>
            <Total>
              <span>Total</span>
              {item && formatPrice(item.value_total_products, priceDecimals)}
            </Total>
          </Card>
        )}
      </CardArea>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrdersActions, dispatch);

const mapStateToProps = (state) => ({
  orderItem: state.orders.orderItem,
  loadingProducts: state.orders.loadingProducts,
  orderItemsVisible: state.orders.orderItemsVisible,
  priceDecimals: state.store.storeInfo
    ? state.store.storeInfo.settings.price_decimals
    : 2,
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
