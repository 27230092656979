import { toast } from 'react-toastify';
import { takeLatest, all, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { validationResponse } from '../../../utils/validations';

import { setDashboardValue } from './actions';

export function* getDashboardValues({ payload }) {
  const response = yield api.getDashboardValues({
    start_day: payload.startDay,
    end_day: payload.endDay,
  });

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao buscar dados do dashboard, tente novamente mais tarde.'
    );
  }

  return yield put(setDashboardValue(response.data));
}

export default all([takeLatest('GET_DASHBOARD_VALUES', getDashboardValues)]);
