export function requestStoreLocation(latitude, longitude) {
  return {
    type: 'REQUEST_STORE_LOCATION',
    latitude,
    longitude,
  };
}

export function handleDeliveryLoading(state) {
  return {
    type: 'HANDLEL_DELIVERY_LOADING',
    state,
  };
}

export function createCircle(latitude, longitude, radius) {
  return {
    type: 'CREATE_CIRCLE',
    latitude,
    longitude,
    radius,
  };
}

export function handleModalArea(state) {
  return {
    type: 'HANDLE_MODAL_AREA',
    state,
  };
}

export function createDeliveryArea(
  name,
  price,
  minWaitingTime,
  maxWaitingTime,
  minWaitingType,
  maxWaitingType,
  latitude,
  longitude,
  radius,
  positions
) {
  return {
    type: 'CREATE_DELIVERY_AREA',
    name,
    price,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
    latitude,
    longitude,
    radius,
    positions,
  };
}

export function getDeliveryAreas(nextPage) {
  return {
    type: 'GET_DELIVERY_AREAS',
    payload: { nextPage },
  };
}

export function setDeliveryAreas(deliveryAreas, nextPage) {
  return {
    type: 'SET_DELIVERY_AREAS',
    payload: { deliveryAreas, nextPage },
  };
}

export function handleDeliveryStatus(status, deliveryId) {
  return {
    type: 'HANDLE_DELIVERY_STATUS',
    status,
    deliveryId,
  };
}

export function handleEditDelivery(state) {
  return { type: 'HANDLE_EDIT_DELIVERY', state };
}

export function handleCurrentDelivery(currentDeliveryArea) {
  return {
    type: 'HANDLE_CURRENT_DELIVERY',
    currentDeliveryArea,
  };
}

export function updateDeliveryArea(deliveryArea) {
  return {
    type: 'UPDATE_STATUS_DELIVERY',
    payload: { deliveryArea },
  };
}
export function updateDelivery(
  deliveryId,
  name,
  price,
  minWaitingTime,
  maxWaitingTime,
  minWaitingType,
  maxWaitingType
) {
  return {
    type: 'UPDATE_DELIVERY',
    deliveryId,
    name,
    price,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
  };
}

export function addDeliveryArea(deliveryArea) {
  return {
    type: 'ADD_DELIVERY_AREA',
    payload: { deliveryArea },
  };
}

export function deleteDeliveryArea(deliveryId) {
  return {
    type: 'DELETE_DELIVERY_AREA',
    deliveryId,
  };
}
