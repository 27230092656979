export function getDashboardValues(startDay, endDay) {
  return {
    type: 'GET_DASHBOARD_VALUES',
    payload: { startDay, endDay },
  };
}

export function setDashboardValue(dashboardValues) {
  return {
    type: 'SET_DASHBOARD_VALUES',
    payload: { dashboardValues },
  };
}
