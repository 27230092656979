import React, { useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { RiImageAddLine } from 'react-icons/ri';
import { AiFillCloseSquare } from 'react-icons/ai';

import defaultImage from '../../../../assets/images/default.jpeg';

import * as ProductsActions from '../../../../store/modules/products/actions';

import {
  Container,
  Body,
  SlideArea,
  ImageArea,
  Image,
  DeleteArea,
  Delete,
  RightButton,
  LeftButton,
  ButtonArea,
  ButtonPlus,
} from './styles';

function ProductPhoto({
  currentProduct,
  updateProductPhoto,
  deleteProductPhoto,
}) {
  const inputRef = useRef(null);

  function handleCamera() {
    inputRef.current.click();
  }
  function handleUploadImage(image) {
    updateProductPhoto(currentProduct.id, image);
  }

  function handleDeleteImage(image) {
    deleteProductPhoto(currentProduct.id, image.id);
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <RightButton
        className={className}
        style={{ ...style, background: '#ddd', borderRadius: '10px' }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <LeftButton
        className={className}
        style={{ ...style, background: '#ddd', borderRadius: '10px' }}
        onClick={onClick}
      />
    );
  }

  return (
    <Container>
      <input
        onChange={(image) => handleUploadImage(image.target.files[0])}
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
      />
      <Body>
        <SlideArea>
          {currentProduct &&
          currentProduct.photos &&
          currentProduct.photos.length > 1 ? (
            <Slider
              variableWidth
              className="slide"
              dots
              infinite={false}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              centerMode={false}
              swipeToSlide
              prevArrow={<SamplePrevArrow />}
              nextArrow={<SampleNextArrow />}
            >
              {currentProduct &&
                currentProduct.photos &&
                currentProduct.photos.map((photo) => (
                  <ImageArea key={photo.id}>
                    <Image image={photo.url}>
                      <DeleteArea onClick={() => handleDeleteImage(photo)}>
                        <Delete>
                          <AiFillCloseSquare />
                        </Delete>
                      </DeleteArea>
                    </Image>
                  </ImageArea>
                ))}
            </Slider>
          ) : (
            <ImageArea>
              <Image
                image={
                  currentProduct &&
                  currentProduct.photos &&
                  currentProduct.photos.length > 0
                    ? currentProduct.photos[0].url
                    : defaultImage
                }
              >
                {currentProduct && currentProduct.photos.length > 0 && (
                  <DeleteArea
                    onClick={() => handleDeleteImage(currentProduct.photos[0])}
                  >
                    <Delete>
                      <AiFillCloseSquare />
                    </Delete>
                  </DeleteArea>
                )}
              </Image>
            </ImageArea>
          )}
        </SlideArea>

        <ButtonArea
          carousel={
            currentProduct &&
            currentProduct.photos &&
            currentProduct.photos.length > 1
          }
        >
          <ButtonPlus
            type="button"
            carousel={
              currentProduct &&
              currentProduct.photos &&
              currentProduct.photos.length > 1
            }
            onClick={() => handleCamera()}
          >
            <RiImageAddLine />
            Adicionar nova foto
          </ButtonPlus>
        </ButtonArea>
      </Body>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

const mapStateToProps = (state) => ({
  currentProduct: state.products.currentProduct,
  products: state.products.products,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductPhoto);
