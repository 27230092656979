import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  animation: ${animate} 0.6s;

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const Cart = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
`;

export const Header = styled.div``;

export const Store = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: 500;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const StoreInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const CompanyName = styled(Name)``;

export const Document = styled(Name)``;

export const Description = styled(Name)`
  textarea {
    height: calc(6.75rem + 1.5rem + 4rem + 6px);
    /* min-height: 190px; */
    width: 100%;
    resize: none;
    padding: 0.45rem 0.9rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    transition: border 0.6s;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;

    &:focus {
      border: 1px solid #727c8e;
    }
  }
`;

export const Address = styled(Name)`
  flex: 1;
  padding: 0 10px;

  @media (max-width: 992px) {
    padding: 0;
  }
`;

export const ZipCode = styled(Name)``;

export const Street = styled(Name)``;

export const Number = styled(Name)``;

export const Neighborhood = styled(Name)``;

export const Complement = styled(Name)``;

export const CityArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const City = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;

  @media (max-width: 576px) {
    width: 80%;
  }
`;

export const State = styled.div`
  display: flex;
  flex-direction: column;
  width: 13%;

  @media (max-width: 576px) {
    width: 16%;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: 500;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
`;

export const Facebook = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  flex: 1;

  svg {
    margin-right: 5px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Instagram = styled(Facebook)``;

export const Youtube = styled(Facebook)``;

export const Twitter = styled(Facebook)``;

export const Website = styled(Facebook)``;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;

export const ButtonCancel = styled(Button)`
  background: ${(props) => props.theme.colors.redDark};
  margin-right: 20px;
  animation: ${animate} 0.6s;

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
  }
`;
