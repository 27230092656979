import { takeLatest, all, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import api from '../../../services/api';

import { validationResponse } from '../../../utils/validations';

import {
  setStoreInfo,
  setStoreAddress,
  handleLoadingStore,
  setDeliveryPaymentMethods,
  setDeliveryPaymentCards,
  updateDeliveryPaymentCards,
} from './actions';
import { signOut } from '../auth/actions';

export function* getStoreInfo() {
  const response = yield api.getStore();

  const validation = validationResponse(response);

  if (!validation) {
    api.getApiInstance().defaults.headers.Authorization = ``;
    return yield put(signOut());
  }

  return yield put(setStoreInfo(response));
}

export function* findZipCode(payload) {
  const { zipCode } = payload;

  const response = yield api.getZipCode(zipCode);

  if (!response.data) {
    return toast.error('CEP inválido!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  if (response.status && response.status === 200) {
    yield put(
      setStoreAddress({
        neighborhood: response.data.neighborhood,
        zipCode: response.data.cep
          .replace(/\D/g, '')
          .replace(/^(\d{5})(\d{3})/g, '$1-$2'),
        city: response.data.city,
        street: response.data.street,
        state: response.data.state,
        complement: '',
      })
    );
  }
  return true;
}

export function* updateStoreInfo(payload) {
  yield put(handleLoadingStore(true));
  const response = yield api.updateStore(payload.store);

  const validation = validationResponse(response);
  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      'Ocorreu um erro ao atualizar os dados da loja, tente novamente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  yield put(handleLoadingStore(false));
  toast.success('Dados alterados com sucesso!');
  return yield put({ type: 'GET_STORE_INFO' });
}

export function* updateSocialMedia({ payload }) {
  const response = yield api.updateStoreSettings(payload.socialMedia);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao atualizar as mídias sociais, tente novamente mais tarde.'
    );
  }
  return true;
}

export function* updateStoreConfig(payload) {
  yield put(handleLoadingStore(true));
  const response = yield api.updateStoreSettings(payload.store);

  const validation = validationResponse(response);

  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      'Ocorreu um erro ao atualizar as configurações da loja, tente novamente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  yield put(handleLoadingStore(false));
  toast.success('Dados alterados com sucesso!');
  return yield put({ type: 'GET_STORE_INFO' });
}

export function* updateStoreLogo(payload) {
  yield put(handleLoadingStore(true));
  const response = yield api.updateStoreLogo(payload.logo);

  const validation = validationResponse(response);

  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      'Ocorreu um erro ao subir o logo, tente novamente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  yield put(handleLoadingStore(false));
  return yield put({ type: 'GET_STORE_INFO' });
}

export function* deleteStoreLogo() {
  yield put(handleLoadingStore(true));
  const response = yield api.deleteStoreLogo();

  const validation = validationResponse(response);

  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error('Erro ao excluir logo, tente novamente mais tarde');
  }
  yield put(handleLoadingStore(false));
  return toast.success('Logo excluído com sucesso!');
}

export function* updateStoreBanner(payload) {
  yield put(handleLoadingStore(true));
  const response = yield api.updateStoreBanner(payload.banner);
  const validation = validationResponse(response);
  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      'Ocorreu um erro ao subir o banner, tente novamente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  yield put(handleLoadingStore(false));
  return yield put({ type: 'GET_STORE_INFO' });
}

export function* deleteStoreBanner() {
  yield put(handleLoadingStore(true));
  const response = yield api.deleteStoreBanner();

  const validation = validationResponse(response);

  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error('Erro ao excluir banner, tente novamente mais tarde');
  }
  yield put(handleLoadingStore(false));
  return toast.success('Banner excluído com sucesso!');
}

export function* updateStoreBannerMobile({ payload }) {
  yield put(handleLoadingStore(true));
  const response = yield api.updateStoreBannerMobile(payload.bannerMobile);
  const validation = validationResponse(response);
  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      'Ocorreu um erro ao subir o banner mobile, tente novamente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  yield put(handleLoadingStore(false));
  return yield put({ type: 'GET_STORE_INFO' });
}

export function* deleteStoreBannerMobile() {
  yield put(handleLoadingStore(true));
  const response = yield api.deleteStoreBannerMobile();

  const validation = validationResponse(response);

  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      'Erro ao excluir banner mobile, tente novamente mais tarde'
    );
  }
  yield put(handleLoadingStore(false));
  return toast.success('Banner mobile excluído com sucesso!');
}

export function* updateStoreWaterMark(payload) {
  yield put(handleLoadingStore(true));
  const response = yield api.updateStoreWaterMark(payload.waterMark);
  const validation = validationResponse(response);
  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      `Ocorreu um erro ao subir a marca d'agua, tente novamente mais tarde.`,
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  yield put(handleLoadingStore(false));
  return yield put({ type: 'GET_STORE_INFO' });
}
export function* deleteStoreWaterMark() {
  yield put(handleLoadingStore(true));
  const response = yield api.deleteStoreWaterMark();

  const validation = validationResponse(response);

  if (!validation) {
    yield put(handleLoadingStore(false));
    return toast.error(
      "Erro ao excluir marca d'agua, tente novamente mais tarde"
    );
  }
  yield put(handleLoadingStore(false));
  return toast.success("Marca d'agua excluída com sucesso!");
}
export function* updatePeriodInfo({ payload }) {
  const { workPeriod } = payload;

  yield all(
    workPeriod.map(function* (period) {
      if (period) {
        const workPeriodId = period.id || null;
        const data = {
          week_day: period.weekDay,
          start_hour: period.start_hour,
          end_hour: period.end_hour,
          period: period.period,
        };
        return yield api.updatePeriodInfo(workPeriodId, data);
      }
      return true;
    })
  );

  yield put({ type: 'GET_STORE_INFO' });

  return toast.success('Horários de funcionamento alterado com sucesso!');
}

export function* getDeliveryPaymentMethods() {
  const response = yield api.getDeliveryPaymentMethods();

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao buscar métodos de pagamentos, tente novamente mais tarde.'
    );
  }

  return yield put(setDeliveryPaymentMethods(response.data));
}

export function* createOrUpdateDeliveryPaymentMethods({ payload }) {
  const response = yield api.createDeliveryPaymentMethods(
    payload.deliveryPaymentMethods
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao criar métodos de pagamento, tente novamente masi tarde.'
    );
  }

  toast.success('Métodos de pagamentos atualizados com sucesso!');
  return yield put(setDeliveryPaymentMethods(response.data));
}

export function* getDeliveryPaymentCards() {
  const response = yield api.getDeliveryPaymentCards();

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao buscar cartões de pagamentos, tente novamente mais tarde.'
    );
  }

  return yield put(setDeliveryPaymentCards(response.data));
}

export function* createOrUpdateDeliveryPaymentCards({ payload }) {
  const deliveryPaymentMethods = yield select(
    (state) => state.store.deliveryPaymentMethods
  );

  yield all(
    payload.cards.map(function* (card) {
      return yield api.createOrUpdateDeliveryPaymentCards({
        delivery_payment_methods_id: deliveryPaymentMethods.id,
        name: card.name,
        type: card.type,
        status: card.status,
      });
    })
  );

  yield put({ type: 'GET_DELIVERY_PAYMENT_CARDS' });
  toast.success('Bandeiras atualizadas com sucesso!');
}

export function* createNewCard({ payload }) {
  const data = {
    delivery_payment_methods_id: payload.deliveryPaymentMethodsId,
    name: payload.card,
    type: payload.type,
    status: false,
  };
  const response = yield api.createOrUpdateDeliveryPaymentCards(data);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao adicionar nova bandeira, tente novamente mais tarde.'
    );
  }

  yield put(updateDeliveryPaymentCards(response.data));

  return toast.success('Bandeira adicionada com sucesso!');
}

export default all([
  takeLatest('GET_STORE_INFO', getStoreInfo),
  takeLatest('FIND_ZIP_CODE', findZipCode),
  takeLatest('UPDATE_STORE_INFO', updateStoreInfo),
  takeLatest('UPDATE_SOCIAL_MEDIA', updateSocialMedia),
  takeLatest('UPDATE_STORE_CONFIG', updateStoreConfig),
  takeLatest('UPDATE_STORE_LOGO', updateStoreLogo),
  takeLatest('DELETE_STORE_LOGO', deleteStoreLogo),
  takeLatest('UPDATE_STORE_BANNER', updateStoreBanner),
  takeLatest('DELETE_STORE_BANNER', deleteStoreBanner),
  takeLatest('UPDATE_STORE_BANNER_MOBILE', updateStoreBannerMobile),
  takeLatest('DELETE_STORE_BANNER_MOBILE', deleteStoreBannerMobile),
  takeLatest('UPDATE_STORE_WATERMARK', updateStoreWaterMark),
  takeLatest('DELETE_STORE_WATERMARK', deleteStoreWaterMark),
  takeLatest('UPDATE_PERIOD_INFO', updatePeriodInfo),
  takeLatest('GET_DELIVERY_PAYMENT_METHODS', getDeliveryPaymentMethods),
  takeLatest(
    'CREATE_OR_UPDATE_DELIVERY_PAYMENT_METHODS',
    createOrUpdateDeliveryPaymentMethods
  ),
  takeLatest('GET_DELIVERY_PAYMENT_CARDS', getDeliveryPaymentCards),
  takeLatest(
    'CREATE_OR_UPDATE_DELIVERY_PAYMENT_CARDS',
    createOrUpdateDeliveryPaymentCards
  ),
  takeLatest('CREATE_CARD', createNewCard),
]);
