import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  animation: ${animate} 0.6s;

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 576px) {
    padding: 0;
  }
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px;

  & + div {
    border-left: 1px solid #eee;
  }
`;

export const AlertsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.grayDark};

  & + div {
    border-top: 1px solid #eee;
  }
`;

export const Value = styled(Title)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & + div {
    border-left: 0;
  }
`;

export const Message = styled(Value)`
  flex-direction: row;
  justify-content: flex-start;
`;

export const EditArea = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonEdit = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.greenDark};
  color: #fff;
  cursor: pointer;
  transition: color 0.5s, background 0.6s, box-shadow 0.6s;
  font-weight: bold;
  box-shadow: 0px 3px 4px #00000050;

  svg {
    height: 30px;
    width: 30px;
  }

  &:hover {
    box-shadow: 0px 6px 8px #00000050;
    background: ${(props) => props.theme.colors.greenMedium};
  }
`;

export const ButtonDelete = styled(ButtonEdit)`
  background: ${(props) => props.theme.colors.redDark};

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
  }
`;

export const WithoutAlert = styled.div`
  display: flex;
  padding: 20px;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;
