import styled, { keyframes } from 'styled-components';

const animate = keyframes`
 from {
  opacity: 0;
  };
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 20px 20px;
  font-size: 30px;
  font-weight: bold;
  color: #6e7989;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  color: #6e7989;

  .treeview {
    > div {
      border-left: 1px dashed black !important;
    }
  }
`;

export const CategoryList = styled.div`
  background: #fff;
  min-height: 78vh;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 4px #00000050;

  .treeview {
    border: 1px solid #eee;
    padding: 10px;
  }

  svg {
    color: #6e7989;
  }
`;

export const Category = styled.span`
  font-size: 2em;
  color: #6e7989;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.4s, margin-left 0.6s;

  &:hover {
    color: #000;
  }
`;

export const NewCategory = styled.div`
  vertical-align: middle;
  cursor: pointer;
  color: #6e7989;
  text-decoration: underline;
  transition: color 0.4s;

  &:hover {
    color: #000;
  }
`;

export const CurrentCategory = styled(CategoryList)`
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 50%;
  animation: ${animate} 0.6s;
`;

export const SubTitle = styled.div`
  display: flex;
`;

export const Name = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  span {
    padding: 0 10px;
    font-size: 30px;
  }
`;

export const ParentCategory = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  span + span {
    font-size: 22px;
  }
`;

export const Children = styled.div`
  display: flex;
  height: auto;
  flex-wrap: wrap;
`;
export const Child = styled.div`
  padding: 10px;
  width: auto;
  cursor: pointer;
`;
