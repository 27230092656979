import styled from 'styled-components';

export const Container = styled.h2`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  /* font-size: 1.25rem; */
  font-weight: 700;
  color: ${(props) => props.theme.colors.greenDark};
  line-height: 4.75rem;

  @media (max-width: 576px) {
  }
`;
