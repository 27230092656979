import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FcNumericalSorting12,
  FcNumericalSorting21,
  FcAlphabeticalSortingAz,
  FcAlphabeticalSortingZa,
} from 'react-icons/fc';
import { AiOutlineCloseCircle, AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import { IoMdAddCircle } from 'react-icons/io';

import swal from 'sweetalert';
import * as CustomersActions from '../../../../store/modules/customer/actions';
import history from '../../../../services/history';
import { getStoreSlug } from '../../../../utils/storeUtil';

import Loading from '../../../components/Loading';

import {
  ButtonsEditDelete,
  ButtonsEditDeleteMobile,
} from '../../../components/Buttons';
import { Title } from '../../../components/Title';
import {
  Container,
  Body,
  Customers,
  Customer,
  Card,
  Search,
  SearchArea,
  SubTitle,
  Sort,
  Id,
  Name,
  Document,
  Phone,
  Options,
  IdArea,
  NameArea,
  DocumentArea,
  PhoneArea,
  WithOutCustomers,
  ButtonAddCustomer,
  ButtonCustomer,
  ButtonsArea,
  OptionsArea,
} from './styles';

function CustomersList({
  loadingCustomers,
  handleLoadingCustomers,
  customers,
  getCustomers,
  deleteCustomer,
  nextPageCustomers,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [timeOut, setTimeOut] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    search: null,
    sort: 'id',
    direction: 'desc',
  });
  const [currentOption, setCurrentOption] = useState({});

  useEffect(() => {
    document.title = 'I9P - Clientes';
  }, []);

  useEffect(() => {
    if (params.page === 1) handleLoadingCustomers(true);
    getCustomers(params);
  }, [params, getCustomers, handleLoadingCustomers]);

  function handleSortBy(field) {
    let direction = 'desc';
    if (field === params.sort)
      direction = params.direction === 'desc' ? 'asc' : 'desc';

    setParams({ ...params, page: 1, sort: field, direction });
  }

  function handleTextSearch(text) {
    clearTimeout(timeOut);

    setSearchInput(text);
    if (text.length <= 0) {
      setParams({ ...params, page: 1, search: null });
      return;
    }

    setTimeOut(
      setTimeout(() => {
        setParams({ ...params, page: 1, search: text });
      }, 900)
    );
  }

  function handleClear() {
    handleTextSearch('');
  }

  function handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (nextPageCustomers !== 0) {
        setParams({ ...params, page: nextPageCustomers });
      }
    }
  }

  function handleCurrentCustomers(customer) {
    history.push(`/${getStoreSlug()}/clientes/editar/${customer.id}`);
  }

  function handleNewCustomer() {
    history.push(`/${getStoreSlug()}/clientes/novo`);
  }

  function handleDelete(customer) {
    swal(`Deseja realmente excluir o cliente ${customer.name}?`, {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        deleteCustomer(customer.id);
      }
    });
  }

  return (
    <Container>
      <Body>
        <Title text="Clientes" />
        <Card>
          <ButtonCustomer>
            <ButtonAddCustomer onClick={() => handleNewCustomer()}>
              <IoMdAddCircle color="#fff" style={{ marginRight: '12px' }} />
              Novo cliente
            </ButtonAddCustomer>
          </ButtonCustomer>
          <SearchArea>
            <FaSearch width="1.5rem" height="1.5rem" color="#78757E" />
            <Search
              onChange={(text) => handleTextSearch(text.target.value)}
              placeholder="Procurar clientes"
              value={searchInput}
            />
            {searchInput && searchInput.length > 0 && (
              <AiOutlineCloseCircle size={20} onClick={() => handleClear()} />
            )}
          </SearchArea>
          <SubTitle>
            <Id onClick={() => handleSortBy('id')}>
              <Sort>
                {params.sort === 'id' && params.direction === 'desc' ? (
                  <Id>
                    <FcNumericalSorting21 />
                    <span>ID</span>
                  </Id>
                ) : (
                  <Id>
                    <FcNumericalSorting12 />
                    <span>ID</span>
                  </Id>
                )}
              </Sort>
            </Id>
            <Name onClick={() => handleSortBy('name')}>
              {params.sort === 'name' && params.direction === 'desc' ? (
                <Name>
                  <FcAlphabeticalSortingZa />
                  <span>Nome</span>
                </Name>
              ) : (
                <Name>
                  <FcAlphabeticalSortingAz />
                  <span>Nome</span>
                </Name>
              )}
            </Name>
            <Document>
              <span>CPF/CNPJ</span>
            </Document>
            <Phone>
              <span>Telefone</span>
            </Phone>
            <Options />
          </SubTitle>
          {loadingCustomers ? (
            <Loading />
          ) : (
            <Customers onScroll={handleScroll}>
              {customers && customers.length > 0 ? (
                customers.map((customer) => {
                  return (
                    <Customer key={customer.id}>
                      <IdArea onClick={() => handleCurrentCustomers(customer)}>
                        {customer.id}
                      </IdArea>
                      <NameArea
                        onClick={() => handleCurrentCustomers(customer)}
                      >
                        {customer.name}
                      </NameArea>
                      <DocumentArea
                        onClick={() => handleCurrentCustomers(customer)}
                      >
                        {customer.document}
                      </DocumentArea>
                      <PhoneArea
                        onClick={() => handleCurrentCustomers(customer)}
                      >
                        {customer.phone}
                      </PhoneArea>
                      <ButtonsArea>
                        <ButtonsEditDelete
                          handleEdit={handleCurrentCustomers}
                          handleDelete={handleDelete}
                          data={customer}
                        />
                      </ButtonsArea>

                      <OptionsArea>
                        {currentOption === customer ? (
                          <AiOutlineClose
                            onClick={() => setCurrentOption({})}
                          />
                        ) : (
                          <BsThreeDotsVertical
                            onClick={() => setCurrentOption(customer)}
                          />
                        )}
                        {currentOption === customer && (
                          <ButtonsEditDeleteMobile
                            handleEdit={handleCurrentCustomers}
                            handleDelete={handleDelete}
                            data={customer}
                            right={100}
                          />
                        )}
                      </OptionsArea>
                    </Customer>
                  );
                })
              ) : (
                <WithOutCustomers>
                  Nenhum cliente cadastrado no momento
                </WithOutCustomers>
              )}
            </Customers>
          )}
        </Card>
      </Body>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CustomersActions, dispatch);

const mapStateToProps = (state) => ({
  customers: state.customer.customers,
  loadingCustomers: state.customer.loadingCustomers,
  nextPageCustomers: state.customer.nextPageCustomers,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
