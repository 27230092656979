import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  animation: ${animate} 0.6s;
`;

export const Card = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 576px) {
    padding: 0 10px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const Header = styled.div`
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 20px;
  transition: all 0.6s;
  font-weight: bold;
`;

export const Text = styled.div`
  display: flex;
  flex: 1;
`;

export const Status = styled(Text)`
  justify-content: center;
`;

export const CheckBoxArea = styled(Text)`
  justify-content: flex-end;
`;

export const Options = styled(Text)`
  justify-content: flex-end;

  svg {
    transform: ${(props) => (props.status ? 'rotate(0deg)' : 'rotate(180deg)')};
    margin-right: 20px;
    transition: transform 0.6s;
  }
`;

export const CardArea = styled.div`
  animation: ${animate} 0.6s;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 300px;
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: ${(props) => props.theme.colors.grayDark};

  img {
    width: 50px;
    margin: 0 10px;
  }
`;

export const Money = styled.div`
  display: flex;
  transition: background 0.6s, color 0.1s;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.money ? `${props.theme.colors.greenMedium}` : '#f5f5f5'};
  color: ${(props) => (props.money ? `#fff` : props.theme.colors.greenDark)};
  border-radius: 10px;
  margin-bottom: 1px;

  svg {
    height: 25px;
    width: 25px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const CreditCard = styled(Money)`
  flex-direction: column;
  align-items: flex-start;
  transition: border 0.6s, background 0.6s, height 0.6s, color 0.2s;
  background: ${(props) => {
    if (props.credit && !props.creditVisible) {
      return props.theme.colors.greenMedium;
    }
    return '#f5f5f5';
  }};
  border: 1px solid
    ${(props) => {
      if (props.credit && props.creditVisible) {
        return props.theme.colors.greenDark;
      }
      return '#fff';
    }};
  color: ${(props) => {
    if (props.credit && !props.creditVisible) {
      return '#fff';
    }
    return props.theme.colors.greenDark;
  }};
`;

export const DebitCard = styled(CreditCard)`
  background: ${(props) => {
    if (props.debit && !props.debitVisible) {
      return props.theme.colors.greenMedium;
    }
    return '#f5f5f5';
  }};
  border: 1px solid
    ${(props) => {
      if (props.debit && props.debitVisible) {
        return props.theme.colors.greenDark;
      }
      return '#fff';
    }};
  color: ${(props) => {
    if (props.debit && !props.debitVisible) {
      return '#fff';
    }
    return props.theme.colors.greenDark;
  }};
`;
export const Voucher = styled(CreditCard)`
  background: ${(props) => {
    if (props.voucher && !props.voucherVisible) {
      return props.theme.colors.greenMedium;
    }
    return '#f5f5f5';
  }};
  border: 1px solid
    ${(props) => {
      if (props.voucher && props.voucherVisible) {
        return props.theme.colors.greenDark;
      }
      return '#fff';
    }};
  color: ${(props) => {
    if (props.voucher && !props.voucherVisible) {
      return '#fff';
    }
    return props.theme.colors.greenDark;
  }};
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;
