import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdAddCircle } from 'react-icons/io';

import { toast } from 'react-toastify';
import Select from 'react-select';

import swal from 'sweetalert';
import history from '../../services/history';
import { getStoreSlug } from '../../utils/storeUtil';
import * as CustomerActions from '../../store/modules/customer/actions';
import * as ProductActions from '../../store/modules/products/actions';

import { ValidateDocument } from '../../utils/validations';

import Subtitle from '../components/Subtitle';
import Label from '../components/Label';
import Input from '../components/Input';
import CheckBox from '../components/CheckBox';

import ModalAddress from './components/ModalAddress';

import {
  ButtonsEditDelete,
  ButtonsEditDeleteMobile,
} from '../components/Buttons';
import { Title } from '../components/Title';
import {
  Container,
  ContentContainer,
  Card,
  Address,
  Section,
  Header,
  Trash,
  OptionsArea,
  Box,
  Area,
  Check,
  Tool,
  Cep,
  Street,
  HomeNumb,
  Neighborhood,
  City,
  State,
  ForceChangePassword,
  StatusArea,
  Status,
  WithOutAddress,
  ButtonArea,
  ButtonPlus,
  SubmitButton,
  ButtonCancel,
} from './styles';

import Loading from '../components/Loading';
import Tooltip from '../components/Tooltip';

function Customers({
  loadingCustomers,
  handleLoadingCustomers,
  getCustomer,
  currentCustomer,
  addressList,
  setCurrentCustomer,
  setCustomersAdresses,
  getTablePrices,
  tablePriceOptions,
  createCustomer,
  updateCustomer,
  handleModalAddress,
  setAddressToEdit,
  setCustomerZipCode,
  deleteCustomerAddress,
  removeAddress,
}) {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [status, setStatus] = useState(false);
  const [forceChangePassword, setForceChangePassword] = useState(false);
  const [tablePriceId, setTablePriceId] = useState();
  const [currentTablePrice, setCurrentTablePrice] = useState('');
  const [currentAddress, setCurrentAddress] = useState(null);

  useEffect(() => {
    handleLoadingCustomers(true);
    getTablePrices();

    if (!id) {
      setCurrentCustomer(null);
      setCustomersAdresses(null);
      handleLoadingCustomers(false);
      return;
    }

    getCustomer(id);
  }, [
    id,
    getCustomer,
    getTablePrices,
    handleLoadingCustomers,
    setCurrentCustomer,
    setCustomersAdresses,
  ]);

  useEffect(() => {
    setName(currentCustomer?.name || '');
    setEmail(currentCustomer?.email || '');
    setPhone(currentCustomer?.phone);
    setCellphone(currentCustomer?.cellphone || '');
    setDocument(currentCustomer?.document || '');
    setStatus(currentCustomer?.status || false);
    setForceChangePassword(currentCustomer?.force_change_password || false);
  }, [currentCustomer]);

  useEffect(() => {
    if (!currentCustomer) {
      setCurrentTablePrice(null);
      setTablePriceId(null);
      return;
    }

    const tablePrice = tablePriceOptions.filter((tb) => {
      return tb.value === currentCustomer.table_prices_id;
    });

    if (tablePrice.length > 0) {
      setCurrentTablePrice(tablePrice);
      setTablePriceId(tablePrice[0].value);
    } else {
      setCurrentTablePrice(null);
      setTablePriceId(null);
    }
  }, [currentCustomer, tablePriceOptions]);

  function handleSubmit(e) {
    e.preventDefault();

    const validation = ValidateDocument(document);

    if (!validation) return toast.error('CPF/CNPJ inválido.');

    // editing customer
    if (currentCustomer) {
      const customer = {
        name,
        phone,
        cellphone,
        email,
        document,
        register_aproved: status,
        force_change_password: forceChangePassword,
        table_prices_id: tablePriceId,
      };
      updateCustomer(currentCustomer.id, customer);
      return history.push(`/${getStoreSlug()}/clientes`);
    }

    // creating customer
    if (password !== confirmpassword) {
      return toast.error('As senhas precisam ser iguais!.');
    }

    const customer = {
      name,
      email,
      celphone: cellphone,
      phonehome: phone,
      document,
      password,
      forceChangePassword,
      tablePriceId,
      address: addressList,
    };
    return createCustomer(customer);
  }

  const PhoneMask = (text) => {
    return text
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d)(\d{4})$/, '$1$2-$3');
  };

  function handleCelphone(text) {
    setCellphone(PhoneMask(text));
  }

  function handlePhonehome(text) {
    setPhone(PhoneMask(text));
  }

  const DocumentMask = (text) => {
    if (text.length <= 14) {
      text = text.replace(/\D/g, '');
      text = text.replace(/(\d{3})(\d)/, '$1.$2');
      text = text.replace(/(\d{3})(\d)/, '$1.$2');
      text = text.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      text = text.replace(/\D/g, '');
      text = text.replace(/^(\d{2})(\d{3})(\d)/g, '$1.$2.$3');
      text = text.replace(/(\d{3})(\d{4})(\d{2})$/, '$1/$2-$3');
    }
    return text;
  };

  function handleDocument(text) {
    setDocument(DocumentMask(text));
  }

  function handleChange(e) {
    if (e) {
      setTablePriceId(e.value);
      setCurrentTablePrice(e);
      return;
    }

    setTablePriceId(null);
    setCurrentTablePrice(null);
  }

  function handleEditAddress(address) {
    setAddressToEdit(address);
    handleModalAddress(true);
  }
  function handleNewAddress() {
    setAddressToEdit(null);
    setCustomerZipCode({});
    handleModalAddress(true);
  }

  function handleDeleteAddress(address) {
    if (!currentCustomer) {
      return removeAddress(address);
    }
    return swal('Deseja realmente exluir esse endereço?', {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        deleteCustomerAddress(address, currentCustomer.id);
      }
    });
  }

  function handleStatus(e) {
    setStatus(e.target.checked);
  }

  return (
    <Container>
      <ModalAddress />
      <ContentContainer>
        {currentCustomer ? (
          <Title text="Editar cliente" />
        ) : (
          <Title text="Cadastrar novo cliente" />
        )}
        {loadingCustomers ? (
          <Loading />
        ) : (
          <Card onSubmit={(e) => handleSubmit(e)}>
            <Section>
              <Subtitle>Dados pessoais</Subtitle>
              <Box>
                <Area flex={2}>
                  <Label>
                    Nome completo
                    <span style={{ marginLeft: '5px', color: '#f00' }}>*</span>
                  </Label>

                  <Input
                    required
                    setState={setName}
                    value={name}
                    placeholder="Digite o nome do cliente"
                  />
                </Area>
                <Area flex={1}>
                  <Label>
                    CPF/CNPJ
                    <span style={{ marginLeft: '5px', color: '#f00' }}>*</span>
                  </Label>
                  <Input
                    required
                    length={18}
                    setState={handleDocument}
                    value={document}
                    placeholder="Digite o CPF ou CNPJ do cliente"
                  />
                </Area>
              </Box>
              <Box>
                <Area flex={1}>
                  <Label>
                    E-mail
                    <span style={{ marginLeft: '5px', color: '#f00' }}>*</span>
                  </Label>
                  <Input
                    required
                    type="email"
                    setState={setEmail}
                    value={email}
                    placeholder="Digite o e-mail do cliente"
                  />
                </Area>
              </Box>
              <Box>
                {currentCustomer ? (
                  <></>
                ) : (
                  <>
                    <Area flex={1}>
                      <Label>
                        Senha
                        <span style={{ marginLeft: '5px', color: '#f00' }}>
                          *
                        </span>
                      </Label>

                      <Input
                        required
                        type="password"
                        setState={setPassword}
                        value={password}
                        placeholder="Digite a senha de acesso do cliente."
                        autocomplete="new-password"
                      />
                    </Area>
                    <Area flex={1}>
                      <Label>
                        Confirmação da senha
                        <span style={{ marginLeft: '5px', color: '#f00' }}>
                          *
                        </span>
                      </Label>
                      <Input
                        required
                        type="password"
                        setState={setConfirmpassword}
                        value={confirmpassword}
                        placeholder="confirme a senha do cliente."
                        autocomplete="new-password"
                      />
                    </Area>
                  </>
                )}
                <Area flex={1}>
                  <ForceChangePassword>
                    <span>Forçar alterar senha</span>
                    <Check>
                      <Tool>
                        <Tooltip info="Caso habilitado, o cliente será obrigado a alterar a senha." />
                      </Tool>
                      <CheckBox
                        handleStatus={setForceChangePassword}
                        id="check"
                        checked={forceChangePassword}
                      />
                    </Check>
                  </ForceChangePassword>
                </Area>
                {currentCustomer && (
                  <Area flex={1}>
                    <StatusArea>
                      <span>Situação ( Ativo / Inativo ) </span>
                      <Status>
                        <Tool>
                          <Tooltip info="Case habilitado, o usuário poderá ter acesso a plataforma." />
                        </Tool>
                        <CheckBox
                          id="state"
                          status={status}
                          handleStatus={handleStatus}
                        />
                      </Status>
                    </StatusArea>
                  </Area>
                )}
              </Box>
            </Section>
            <Section>
              <Subtitle>Contato</Subtitle>
              <Box>
                <Area flex={1}>
                  <Label>Telefone residêncial</Label>
                  <Input
                    length={15}
                    setState={handlePhonehome}
                    value={phone}
                    placeholder="Digite o telefone do cliente."
                  />
                </Area>
                <Area flex={1}>
                  <Label>Telefone celular</Label>
                  <Input
                    length={15}
                    setState={handleCelphone}
                    value={cellphone}
                    placeholder="Digite o celular do cliente."
                  />
                </Area>
              </Box>
            </Section>
            <Section>
              <Subtitle>Endereço</Subtitle>
              {addressList && addressList.length > 0 ? (
                <Header>
                  <Cep>CEP</Cep>
                  <Street>Rua</Street>
                  <HomeNumb>Número</HomeNumb>
                  <Neighborhood>Bairro</Neighborhood>
                  <City>Cidade</City>
                  <State>Estado</State>
                  <Trash />
                </Header>
              ) : (
                <></>
              )}
              <Area flex={1}>
                {addressList && addressList.length > 0 ? (
                  addressList.map((address) => {
                    return (
                      <Address>
                        <Cep flex={1}>
                          <span>{address.zip_code}</span>
                        </Cep>
                        <Street flex={1}>
                          <span>{address.street}</span>
                        </Street>
                        <HomeNumb flex={1}>
                          <span>{address.number}</span>
                        </HomeNumb>
                        <Neighborhood flex={1}>
                          <span>{address.neighborhood}</span>
                        </Neighborhood>
                        <City flex={1}>
                          <span>{address.city}</span>
                        </City>
                        <State flex={1}>
                          <span> {address.state}</span>
                        </State>

                        <Trash>
                          <ButtonsEditDelete
                            handleEdit={handleEditAddress}
                            handleDelete={handleDeleteAddress}
                            data={address}
                          />
                        </Trash>
                        <OptionsArea>
                          {currentAddress === address ? (
                            <AiOutlineClose
                              onClick={() => setCurrentAddress(null)}
                            />
                          ) : (
                            <BsThreeDotsVertical
                              onClick={() => setCurrentAddress(address)}
                            />
                          )}

                          {currentAddress === address && (
                            <ButtonsEditDeleteMobile
                              handleEdit={handleEditAddress}
                              handleDelete={handleDeleteAddress}
                              data={address}
                              right={80}
                            />
                          )}
                        </OptionsArea>
                      </Address>
                    );
                  })
                ) : (
                  <WithOutAddress>
                    Nenhum endereço cadastrado no momento.
                  </WithOutAddress>
                )}
              </Area>
              <ButtonArea>
                <ButtonPlus type="button" onClick={() => handleNewAddress()}>
                  <IoMdAddCircle />
                  <span>Adicionar novo endereço</span>
                </ButtonPlus>
              </ButtonArea>
            </Section>
            <Section>
              <Subtitle>Tabela de preço</Subtitle>
              <Area>
                <Label>
                  Selecione a tabela de preço referente a esse cliente.
                </Label>
                <Select
                  className="select"
                  onChange={handleChange}
                  placeholder="Selecione..."
                  options={tablePriceOptions}
                  value={currentTablePrice}
                  isClearable
                />
              </Area>
            </Section>
            <ButtonArea>
              <ButtonCancel
                type="button"
                onClick={() => history.push(`/${getStoreSlug()}/clientes`)}
              >
                <span>Cancelar</span>
              </ButtonCancel>
              <SubmitButton>
                <span>Salvar</span>
              </SubmitButton>
            </ButtonArea>
          </Card>
        )}
      </ContentContainer>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...CustomerActions, ...ProductActions }, dispatch);

const mapStateToProps = (state) => ({
  loadingCustomers: state.customer.loadingCustomers,
  addressList: state.customer.addressList,
  currentCustomer: state.customer.currentCustomer,
  tablePriceOptions: state.products.tablePriceOptions,
});
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
