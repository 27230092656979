// #region CATEGORY
export function getCategories() {
  return {
    type: 'GET_CATEGORIES',
  };
}
export function setCategories(categories) {
  return {
    type: 'SET_CATEGORIES',
    payload: { categories },
  };
}
export function createCategory(name) {
  return {
    type: 'CREATE_CATEGORY',
    payload: { name },
  };
}
export function updateCategories(category) {
  return {
    type: 'UPDATE_CATEGORY',
    payload: { category },
  };
}
export function setOptions(options) {
  return {
    type: 'SET_OPTIONS',
    payload: { options },
  };
}
export function updateOptions(option) {
  return {
    type: 'UPDATE_OPTIONS',
    payload: { option },
  };
}
// #endregion

// #region PRODUCTS
export function getProducts(payload) {
  return {
    type: 'GET_PRODUCTS',
    payload,
  };
}

export function setProducts(products, page, nextPage) {
  return {
    type: 'SET_PRODUCTS',
    payload: { products, page, nextPage },
  };
}

export function getProduct(productId) {
  return {
    type: 'GET_PRODUCT',
    payload: { productId },
  };
}

export function setCurrentProduct(product) {
  return {
    type: 'SET_CURRENT_PRODUCT',
    payload: { product },
  };
}

export function setCurrentProductPrices(prices) {
  return {
    type: 'SET_CURRENT_PRODUCT_PRICES',
    payload: { prices },
  };
}

export function setCurrentProductPrice(price) {
  return {
    type: 'SET_CURRENT_PRODUCT_PRICE',
    payload: { price },
  };
}

export function createProduct(product) {
  return {
    type: 'CREATE_PRODUCT',
    payload: { product },
  };
}

export function updateProduct(product) {
  return {
    type: 'UPDATE_PRODUCT',
    payload: { product },
  };
}

export function deleteProduct(productId) {
  return {
    type: 'DELETE_PRODUCT',
    payload: { productId },
  };
}

export function removeProduct(productId) {
  return {
    type: 'REMOVE_PRODUCT',
    payload: { productId },
  };
}

export function updateProductPhoto(productId, image) {
  return {
    type: 'UPDATE_PRODUCT_PHOTO',
    payload: { productId, image },
  };
}
export function updateProductPrice(tablePriceId, price, promotionalPrice) {
  return {
    type: 'UPDATE_CURRENT_PRODUCT_PRICE',
    payload: { tablePriceId, price, promotionalPrice },
  };
}

export function removeProductPhoto(imageId) {
  return {
    type: 'REMOVE_PRODUCT_PHOTO',
    payload: { imageId },
  };
}
export function deleteProductPhoto(productId, imageId) {
  return {
    type: 'DELETE_PRODUCT_PHOTO',
    payload: { productId, imageId },
  };
}

export function updateProductAvaiable(productId, status) {
  return {
    type: 'UPDATE_PRODUCT_AVAIABLE',
    payload: { productId, status },
  };
}
// #endregion

// #region CURRENT PRODUCT
export function updateCurrentProductPhoto(photo) {
  return {
    type: 'UPDATE_CURRENT_PRODUCT_PHOTO',
    payload: { photo },
  };
}

export function updateCurrentProductPrices(newPrice) {
  return {
    type: 'UPDATE_CURRENT_PRODUCT_PRICES',
    payload: { newPrice },
  };
}
// #endregion

// #region PRODUCT TABLE PRICE
export function deleteProductTablePrice(tablePriceId, productId) {
  return {
    type: 'DELETE_PRODUCT_TABLE_PRICE',
    payload: { tablePriceId, productId },
  };
}

export function removeProductTablePrice(tablePriceId) {
  return {
    type: 'REMOVE_PRODUCT_PRICE',
    payload: { tablePriceId },
  };
}

export function handleModalTablePrice(state) {
  return {
    type: 'HANDLE_MODAL_TABLE_PRICE',
    payload: { state },
  };
}

export function createProductTablePrice(
  productId,
  tablePriceId,
  price,
  promotionalPrice
) {
  return {
    type: 'CREATE_PRODUCT_TABLE_PRICE',
    payload: { productId, tablePriceId, price, promotionalPrice },
  };
}
export function updateProductTablePrice(
  tablePriceId,
  productId,
  price,
  promotionalPrice
) {
  return {
    type: 'UPDATE_PRODUCT_TABLE_PRICE',
    payload: { tablePriceId, productId, price, promotionalPrice },
  };
}

export function addProductTablePrice(price) {
  return {
    type: 'ADD_PRODUCT_TABLE_PRICE',
    payload: { price },
  };
}

export function editProductTablePrices(price) {
  return {
    type: 'EDIT_PRODUCT_TABLE_PRICE',
    payload: { price },
  };
}

export function createTablePrice(name) {
  return {
    type: 'CREATE_TABLE_PRICE',
    payload: { name },
  };
}

export function getTablePrices() {
  return {
    type: 'GET_TABLE_PRICES',
  };
}

export function setTablePrices(tablePrices) {
  return {
    type: 'SET_TABLE_PRICES',
    payload: { tablePrices },
  };
}
export function setTablePriceOptions(options) {
  return {
    type: 'SET_TABEL_PRICE_OPTIONS',
    payload: { options },
  };
}
export function updateTablePriceOptions(option) {
  return {
    type: 'UPDATE_TABEL_PRICE_OPTIONS',
    payload: { option },
  };
}

export function handleLoadingProductsList(state) {
  return {
    type: 'HANDLE_LOADING_PRODUCTS_LIST',
    payload: { state },
  };
}
// #endregion
