import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { format } from 'date-fns';

import Select from 'react-select';
import chroma from 'chroma-js';

import * as OrdersActions from '../../../../store/modules/orders/actions';

import { formatPrice } from '../../../../utils/format';

import { Title } from '../../../components/Title';
import {
  Container,
  Body,
  Header,
  Name,
  Phone,
  ProductName,
  ProductPrice,
  Date,
  Status,
  ContactList,
  ContactResquest,
} from './styles';

function ContactRequests({
  getContactRequests,
  contactRequests,
  priceDecimals,
  updateContactRequest,
  nextPageContactRequests,
}) {
  const options = [
    {
      value: 'Pendente',
      label: 'Pendente',
    },
    {
      value: 'Contatado',
      label: 'Contatado',
    },
  ];
  useEffect(() => {
    getContactRequests(nextPageContactRequests);
  }, [getContactRequests, nextPageContactRequests]);

  function handleChangeStatus(status, contactRequest) {
    updateContactRequest(contactRequest.id, status);
  }
  function verifyColor(status) {
    if (status.toLowerCase() === 'contatado') return '#b2dbb2';
    if (status.toLowerCase() === 'pendente') return '#fcf1de';
    return '#edafa4';
  }

  function dot(status) {
    const color = chroma(verifyColor(status)).darken().saturate(10).hex();
    return {
      alignItems: 'center',
      display: 'flex',

      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    };
  }

  function handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (nextPageContactRequests > 0) {
        getContactRequests(nextPageContactRequests);
      }
    }
  }

  return (
    <Container>
      <Title text="Solicitação de contato" />
      <Body>
        <Header>
          <Name>Nome</Name>
          <Phone>Telefone</Phone>
          <ProductName>Produto</ProductName>
          <ProductPrice>Preço</ProductPrice>
          <Date>Data</Date>
          <Status>Situação do contato</Status>
        </Header>
        <ContactList
          onScroll={(e) => handleScroll(e)}
          height={window.innerHeight - 350}
        >
          {contactRequests &&
            contactRequests.length > 0 &&
            contactRequests.map((contactRequest) => {
              return (
                <ContactResquest>
                  <Name>{contactRequest.name}</Name>
                  <Phone>{contactRequest.phone}</Phone>
                  <ProductName>{contactRequest.product_name}</ProductName>
                  <ProductPrice>
                    {formatPrice(contactRequest.product_price, priceDecimals)}
                  </ProductPrice>
                  <Date>
                    {format(
                      new window.Date(contactRequest.created_at),
                      'dd/MM/yyyy'
                    )}
                  </Date>
                  <Status status={contactRequest.status.toLowerCase()}>
                    <Select
                      styles={{
                        singleValue: (styles) => ({
                          ...styles,
                          ...dot(contactRequest.status),
                        }),
                      }}
                      className="select"
                      onChange={(e) =>
                        handleChangeStatus(e.value, contactRequest)
                      }
                      options={options}
                      value={{
                        value: contactRequest.status.toLowerCase(),
                        label: contactRequest.status,
                      }}
                    />
                  </Status>
                </ContactResquest>
              );
            })}
        </ContactList>
      </Body>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  contactRequests: state.orders.contactRequests,
  nextPageContactRequests: state.orders.nextPageContactRequests,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...OrdersActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactRequests);
