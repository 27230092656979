import React from 'react';

import { MdDelete, MdEdit } from 'react-icons/md';

import { Button, IconsArea, Options, IconEdit, IconDelete } from './styles';

export const ButtonCancel = ({ text, onClick }) => {
  return (
    <Button onClick={() => onClick()} danger>
      {text}
    </Button>
  );
};

export const ButtonSave = ({ type, text, onClick }) => {
  return (
    <Button type={type} onClick={() => onClick()} danger={false}>
      {text}
    </Button>
  );
};

export const ButtonsEditDelete = ({ handleEdit, handleDelete, data }) => {
  return (
    <IconsArea>
      <IconEdit>
        <MdEdit onClick={() => handleEdit(data)} />
      </IconEdit>
      <IconDelete>
        <MdDelete onClick={() => handleDelete(data)} />
      </IconDelete>
    </IconsArea>
  );
};
export const ButtonsEditDeleteMobile = ({
  handleEdit,
  handleDelete,
  data,
  right,
}) => {
  return (
    <Options right={right}>
      <IconEdit>
        <MdEdit onClick={() => handleEdit(data)} />
      </IconEdit>
      <IconDelete>
        <MdDelete onClick={() => handleDelete(data)} />
      </IconDelete>
    </Options>
  );
};
