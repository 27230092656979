import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Select from 'react-select';

import swal from 'sweetalert';
import { toast } from 'react-toastify';

import moment from 'moment';

import * as AlertsAction from '../../../../../../store/modules/alerts/actions';

import Input from '../../../../../components/Input';
import Tooltip from '../../../../../components/Tooltip';
import {
  Container,
  TitleAlert,
  AlertModal,
  MonthArea,
  Month,
  WeekDaysArea,
  WeekDays,
  TitleInfo,
  DayArea,
  Day,
  Box,
  ButtonArea,
  ButtonCancel,
  ButtonAdd,
} from './styles';

function ModalAlerts({
  currentAlert,
  modalAlerts,
  handleModalAlerts,
  createAlert,
  updateAlert,
}) {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [options] = useState([
    { value: 'Month', label: 'Mensal' },
    { value: 'Week', label: 'Semanal' },
    { value: 'Day', label: 'Diário' },
  ]);
  const [currentOptions, setCurrentOptions] = useState({});
  const [month, setMonth] = useState(false);
  const [day, setDay] = useState(false);
  const [week, setWeek] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [monthDay, setMonthDay] = useState(null);

  function verifyFrequencyType(frequencyType) {
    if (frequencyType === 'Day') {
      return 'Diário';
    }
    if (frequencyType === 'Week') {
      return 'Semanal';
    }

    return 'Mensal';
  }

  useEffect(() => {
    if (currentAlert) {
      setTitle(currentAlert?.title || '');
      setMessage(currentAlert?.message || '');
      setCurrentOptions({
        value: currentAlert?.frequency_type,
        label: verifyFrequencyType(currentAlert?.frequency_type),
      });
      if (currentAlert?.frequency_type === 'Day') {
        setDay(true);
        setWeek(false);
        setMonth(false);
        setStartDate(moment(currentAlert?.start_date).format('YYYY-MM-DD'));
        setEndDate(moment(currentAlert?.end_date).format('YYYY-MM-DD'));
      }
      if (currentAlert?.frequency_type === 'Week') {
        setDay(false);
        setWeek(true);
        setMonth(false);
        const array = currentAlert?.week_day?.split(',');
        array.map((weekDay) => {
          if (weekDay) {
            switch (weekDay) {
              case 'Sunday':
                setSunday(true);
                break;
              case 'Monday': {
                setMonday(true);
                break;
              }
              case 'Tuesday': {
                setTuesday(true);
                break;
              }
              case 'Wednesday': {
                setWednesday(true);
                break;
              }
              case 'Thursday': {
                setThursday(true);
                break;
              }
              case 'Friday': {
                setFriday(true);
                break;
              }
              case 'Saturday': {
                setSaturday(true);
                break;
              }
              default:
                break;
            }
          }
          return true;
        });
      }
      if (currentAlert?.frequency_type === 'Month') {
        setDay(false);
        setWeek(false);
        setMonth(true);
        setMonthDay(currentAlert?.month_day || null);
      }
    }
    if (!currentAlert) {
      setTitle('');
      setMessage('');
      setCurrentOptions({ value: 'Week', label: 'Semanal' });
      setMonth(false);
      setDay(false);
      setWeek(true);
      setStartDate('');
      setEndDate('');
      setMonday(false);
      setTuesday(false);
      setWednesday(false);
      setThursday(false);
      setFriday(false);
      setSaturday(false);
      setSunday(false);
      setMonthDay('');
    }
  }, [currentAlert]);

  function handleSelectOptions(e) {
    if (e.value === 'Month') {
      setMonth(true);
      setWeek(false);
      setDay(false);
    }
    if (e.value === 'Week') {
      setMonth(false);
      setWeek(true);
      setDay(false);
    }
    if (e.value === 'Day') {
      setMonth(false);
      setWeek(false);
      setDay(true);
    }
    setCurrentOptions(e);
  }
  function handleSaveAlerts() {
    if (message.length > 510)
      return toast.info(
        'Mensagem muito grande, por favor insira uma mensagem menor.'
      );
    if (currentOptions.value === 'Day') {
      if (!startDate || !endDate || !message)
        return toast.info('As datas e a mensagem são obrigatórias. ');
      return swal({
        title: 'Atenção',
        text: `Deseja realmente exbir um aviso diário, de ${moment(
          startDate
        ).format('L')} até ${moment(endDate).format('L')} ?`,
        icon: 'warning',
        buttons: ['Não', 'Sim'],
      }).then((save) => {
        if (save) {
          return createAlert({
            title,
            message,
            active: true,
            frequency_type: currentOptions.value,
            start_date: moment(startDate).format(),
            end_date: moment(endDate).format(),
          });
        }
        return false;
      });
    }
    if (currentOptions.value === 'Week') {
      if (
        (!monday &&
          !tuesday &&
          !wednesday &&
          !thursday &&
          !friday &&
          !saturday &&
          !sunday) ||
        !message
      ) {
        return toast.info(
          'Selecione pelo menos um dia da semana e informe a mensagem. '
        );
      }
      const weekDays = `${sunday ? 'Sunday,' : ''}${monday ? 'Monday,' : ''}${
        tuesday ? 'Tuesday,' : ''
      }${wednesday ? 'Wednesday,' : ''}${thursday ? 'Thursday,' : ''}${
        friday ? 'Friday,' : ''
      }${saturday ? 'Saturday,' : ''}`;
      return swal({
        title: 'Atenção',
        text: `Deseja realmente exibir um aviso todo(s) os dia(s) selecionado?`,
        icon: 'warning',
        buttons: ['Não', 'Sim'],
      }).then((save) => {
        if (save) {
          return createAlert({
            title,
            message,
            active: true,
            frequency_type: currentOptions.value,
            week_day: weekDays,
          });
        }
        return false;
      });
    }
    if (currentOptions.value === 'Month') {
      if (monthDay <= 0 || monthDay > 31) {
        return toast.error('Insira um dia do mês válido!');
      }
      if (!monthDay || !message)
        return toast.info('Informe somente o dia do mês e a mensagem.');
      return swal({
        title: 'Atenção',
        text: `Deseja realmente exibir a mensagem todo dia ${monthDay}?`,
        icon: 'warning',
        buttons: ['Não', 'Sim'],
      }).then((save) => {
        if (save) {
          return createAlert({
            title,
            message,
            active: true,
            frequency_type: currentOptions.value,
            month_day: parseInt(monthDay, 10),
          });
        }
        return false;
      });
    }
    return setCurrentOptions({});
  }

  function handleStartDate(e) {
    setStartDate(e);
  }

  function handleEndDate(e) {
    setEndDate(e);
  }

  function verifyWeekDay(weekD) {
    let response = false;
    if (currentAlert.week_day) {
      const array = currentAlert.week_day.split(',');

      if (array.length > 0) {
        array.map((wk) => {
          if (wk === weekD) {
            response = true;
            return response;
          }
          return false;
        });
      }
    }

    return response;
  }

  function handleEditAlert() {
    const sundayVerified = verifyWeekDay('Sunday');
    const mondayVerified = verifyWeekDay('Monday');
    const tuesdayVerified = verifyWeekDay('Tuesday');
    const wednesdayVerified = verifyWeekDay('Wednesday');
    const thursdayVerified = verifyWeekDay('Thursday');
    const fridayVerified = verifyWeekDay('Friday');
    const saturdayVerified = verifyWeekDay('Saturday');

    if (
      currentAlert.title === title &&
      currentAlert.message === message &&
      currentAlert.frequency_type === currentOptions.value &&
      currentAlert.start_date === startDate &&
      currentAlert.end_date === endDate &&
      currentAlert.month_day === monthDay &&
      sunday === sundayVerified &&
      monday === mondayVerified &&
      tuesday === tuesdayVerified &&
      wednesday === wednesdayVerified &&
      thursday === thursdayVerified &&
      friday === fridayVerified &&
      saturday === saturdayVerified
    ) {
      handleModalAlerts(false);
      return toast.info('Sem alterações.');
    }
    if (currentOptions.value === 'Day') {
      return updateAlert(currentAlert.id, {
        title,
        message,
        frequency_type: currentOptions.value,
        start_date: startDate,
        end_date: endDate,
        week_day: null,
        month_day: null,
      });
    }
    if (currentOptions.value === 'Week') {
      const weekDays = `${sunday ? 'Sunday,' : ''}${monday ? 'Monday,' : ''}${
        tuesday ? 'Tuesday,' : ''
      }${wednesday ? 'Wednesday,' : ''}${thursday ? 'Thursday,' : ''}${
        friday ? 'Friday,' : ''
      }${saturday ? 'Saturday,' : ''}`;
      return updateAlert(currentAlert.id, {
        title,
        message,
        frequency_type: currentOptions.value,
        start_date: null,
        end_date: null,
        week_day: weekDays,
        month_day: null,
      });
    }

    return updateAlert(currentAlert.id, {
      title,
      message,
      frequency_type: currentOptions.value,
      start_date: null,
      end_date: null,
      week_day: null,
      month_day: monthDay,
    });
  }

  function handleCancel() {
    if (!currentAlert) {
      setTitle('');
      setMessage('');
      setCurrentOptions({ value: 'Week', label: 'Semanal' });
      setMonth(false);
      setDay(false);
      setWeek(true);
      setStartDate('');
      setEndDate('');
      setMonday(false);
      setTuesday(false);
      setWednesday(false);
      setThursday(false);
      setFriday(false);
      setSaturday(false);
      setSunday(false);
      setMonthDay('');
      return handleModalAlerts(false);
    }
    setTitle(currentAlert?.title || '');
    setMessage(currentAlert?.message || '');
    setCurrentOptions({
      value: currentAlert?.frequency_type,
      label: verifyFrequencyType(currentAlert?.frequency_type),
    });
    if (currentAlert?.frequency_type === 'Day') {
      setDay(true);
      setWeek(false);
      setMonth(false);
      setStartDate(moment(currentAlert?.start_date).format('YYYY-MM-DD'));
      setEndDate(moment(currentAlert?.end_date).format('YYYY-MM-DD'));
    }
    if (currentAlert?.frequency_type === 'Week') {
      setDay(false);
      setWeek(true);
      setMonth(false);
      const array = currentAlert?.week_day?.split(',');
      array.map((weekDay) => {
        if (weekDay) {
          switch (weekDay) {
            case 'Sunday':
              setSunday(true);
              break;
            case 'Monday': {
              setMonday(true);
              break;
            }
            case 'Tuesday': {
              setTuesday(true);
              break;
            }
            case 'Wednesday': {
              setWednesday(true);
              break;
            }
            case 'Thursday': {
              setThursday(true);
              break;
            }
            case 'Friday': {
              setFriday(true);
              break;
            }
            case 'Saturday': {
              setSaturday(true);
              break;
            }
            default:
              break;
          }
        }
        return true;
      });
    }
    if (currentAlert?.frequency_type === 'Month') {
      setDay(false);
      setWeek(false);
      setMonth(true);
      setMonthDay(currentAlert?.month_day || null);
    }

    return handleModalAlerts(false);
  }

  return modalAlerts ? (
    <Container>
      <AlertModal>
        <TitleAlert>
          <span>Selecione a frequência de avisos</span>
          <Select
            className="select"
            onChange={handleSelectOptions}
            placeholder="selecione uma das opções"
            options={options}
            value={currentOptions}
          />
        </TitleAlert>

        {month && (
          <MonthArea>
            <TitleInfo>
              <Tooltip info="Caso selecionado, a mensagem será exibida todo mês no dia informado." />
              <span>Selecione o dia para o aviso</span>
            </TitleInfo>
            <Month>
              <WeekDays>
                <Input type="number" setState={setMonthDay} value={monthDay} />
              </WeekDays>
            </Month>

            <TitleAlert>
              <span>Título da mensagem</span>
              <Input setState={setTitle} value={title || ''} />
            </TitleAlert>
            <TitleAlert>
              <span>Descrição da mensagem</span>
              <textarea
                maxLength={510}
                onChange={(text) => setMessage(text.target.value)}
                value={message}
              />
            </TitleAlert>
          </MonthArea>
        )}
        {week && (
          <MonthArea>
            <TitleInfo>
              <Tooltip info="Caso selecionado, a mensagem será exibida toda semana no dias selecioandos." />
              <span>Selecione os dias da semana para o aviso</span>
            </TitleInfo>{' '}
            <WeekDaysArea>
              <DayArea>
                <Day status={sunday} onClick={() => setSunday(!sunday)}>
                  D
                </Day>
              </DayArea>
              <DayArea>
                <Day status={monday} onClick={() => setMonday(!monday)}>
                  S
                </Day>
              </DayArea>
              <DayArea>
                <Day status={tuesday} onClick={() => setTuesday(!tuesday)}>
                  T
                </Day>
              </DayArea>
              <DayArea>
                <Day
                  status={wednesday}
                  onClick={() => setWednesday(!wednesday)}
                >
                  Q
                </Day>
              </DayArea>
              <DayArea>
                <Day status={thursday} onClick={() => setThursday(!thursday)}>
                  Q
                </Day>
              </DayArea>
              <DayArea>
                <Day status={friday} onClick={() => setFriday(!friday)}>
                  S
                </Day>
              </DayArea>
              <DayArea>
                <Day status={saturday} onClick={() => setSaturday(!saturday)}>
                  S
                </Day>
              </DayArea>
            </WeekDaysArea>
            <TitleAlert>
              <span>Título da mensagem</span>
              <Input setState={setTitle} value={title || ''} />
            </TitleAlert>
            <TitleAlert>
              <span>Descrição da mensagem</span>
              <textarea
                value={message}
                maxLength={510}
                onChange={(text) => setMessage(text.target.value)}
              />
            </TitleAlert>
          </MonthArea>
        )}
        {day && (
          <MonthArea>
            <TitleInfo>
              <Tooltip info="Caso selecionado, a mensagem será exibida todos os dias dentro do intervalo selecionado." />
              <span>Selecione os dias da semana para o aviso</span>
            </TitleInfo>{' '}
            <Month>
              <WeekDays>
                <span>Começa no dia:</span>
                <input
                  value={startDate}
                  type="date"
                  onChange={(e) => handleStartDate(e)}
                />
              </WeekDays>
              <WeekDays>
                <span>Termina no dia:</span>
                <input
                  value={endDate}
                  type="date"
                  onChange={(e) => handleEndDate(e)}
                />
              </WeekDays>
            </Month>
            <TitleAlert>
              <span>Título da mensagem</span>
              <Input setState={setTitle} value={title || ''} />
            </TitleAlert>
            <TitleAlert>
              <span>Descrição da mensagem</span>
              <textarea
                value={message}
                maxLength={510}
                onChange={(text) => setMessage(text.target.value)}
              />
            </TitleAlert>
          </MonthArea>
        )}
        {currentOptions.value && (
          <Box>
            <ButtonArea>
              <ButtonCancel onClick={() => handleCancel()}>
                Cancelar
              </ButtonCancel>
            </ButtonArea>
            {currentAlert ? (
              <ButtonArea>
                <ButtonAdd onClick={() => handleEditAlert()}>Salvar</ButtonAdd>
              </ButtonArea>
            ) : (
              <ButtonArea>
                <ButtonAdd onClick={() => handleSaveAlerts()}>
                  Adicionar
                </ButtonAdd>
              </ButtonArea>
            )}
          </Box>
        )}
      </AlertModal>
    </Container>
  ) : (
    <> </>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AlertsAction, dispatch);

const mapStatetoProps = (state) => ({
  currentAlert: state?.alerts?.currentAlert,
  modalAlerts: state?.alerts?.modalAlerts,
});

export default connect(mapStatetoProps, mapDispatchToProps)(ModalAlerts);
