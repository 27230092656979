import React, { useState } from 'react';

import swal from 'sweetalert';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineYoutube,
} from 'react-icons/ai';
import { CgWebsite } from 'react-icons/cg';

import { toast } from 'react-toastify';
import * as StoreActions from '../../../../store/modules/store/actions';

import Subtitle from '../../../components/Subtitle';
import Label from '../../../components/Label';
import Input from '../../../components/Input';

import {
  Container,
  Cart,
  Store,
  StoreInfoArea,
  Name,
  CompanyName,
  Document,
  Description,
  Address,
  ZipCode,
  Street,
  Number,
  Neighborhood,
  Complement,
  CityArea,
  City,
  State,
  SocialMedia,
  Line,
  Facebook,
  Instagram,
  Youtube,
  Twitter,
  Website,
  ButtonArea,
  Button,
} from './styles';

function StoreInfo({
  store,
  findZipCode,
  address,
  updateStoreInfo,
  updateSocialMedia,
}) {
  const [name, setName] = useState(store.name || '');
  const [razaoSocial, setRazaoSocial] = useState(store.razao_social || '');
  const [cnpj, setCnpj] = useState(store.cnpj || '');
  const [description, setDescription] = useState(store.description || '');
  const [zipCode, setZipCode] = useState(store.zip_code || '');
  const [street, setStreet] = useState(store.street || '');
  const [number, setNumber] = useState(store.number || 0);
  const [neighborhood, setNeighborhood] = useState(store.neighborhood || '');
  const [complement, setComplement] = useState(store.complement || '');
  const [city, setCity] = useState(store.city || '');
  const [state, setState] = useState(store.state) || '';
  const [facebook, setFacebook] = useState(store.settings.facebook) || '';
  const [instagram, setInstagram] = useState(store.settings.instagram) || '';
  const [youtube, setYoutube] = useState(store.settings.youtube) || '';
  const [twitter, setTwitter] = useState(store.settings.twitter) || '';
  const [website, setWebsite] = useState(store.settings.website) || '';

  function validateCnpj() {
    const cnpjFormated = cnpj.replace(/[^\d]+/g, '');

    if (cnpjFormated === '') return false;

    if (cnpjFormated.length !== 14) return false;

    if (
      cnpjFormated === '00000000000000' ||
      cnpjFormated === '11111111111111' ||
      cnpjFormated === '22222222222222' ||
      cnpjFormated === '33333333333333' ||
      cnpjFormated === '44444444444444' ||
      cnpjFormated === '55555555555555' ||
      cnpjFormated === '66666666666666' ||
      cnpjFormated === '77777777777777' ||
      cnpjFormated === '88888888888888' ||
      cnpjFormated === '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpjFormated.length - 2;
    let numeros = cnpjFormated.substring(0, tamanho);
    const digitos = cnpjFormated.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    // eslint-disable-next-line
    for (let i = tamanho; i >= 1; i--) {
      // eslint-disable-next-line
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    // eslint-disable-next-line
    if (resultado != digitos.charAt(0)) return false;

    tamanho += 1;
    numeros = cnpjFormated.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    // eslint-disable-next-line
    for (let i = tamanho; i >= 1; i--) {
      // eslint-disable-next-line
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    // eslint-disable-next-line
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  async function handleSave() {
    if (
      name === store.name &&
      razaoSocial === store.razao_social &&
      cnpj === store.cnpj &&
      description === store.description &&
      zipCode === store.zip_code &&
      street === store.street &&
      number === store.number &&
      neighborhood === store.neighborhood &&
      complement === store.complement &&
      city === store.city &&
      state === store.state &&
      facebook === store.settings.facebook &&
      instagram === store.settings.instagram &&
      youtube === store.settings.youtube &&
      twitter === store.settings.twitter &&
      website === store.settings.website
    ) {
      return toast.info('Sem alterações');
    }

    const validate = validateCnpj();

    if (!validate) return toast.error('CNPJ inválido.');

    return swal({
      title: 'Atenção?',
      text: 'Deseja realmente salvar as alterações?',
      icon: 'warning',
      buttons: ['Manter', 'Salvar'],
    }).then(async (save) => {
      if (save) {
        if (
          name !== store.name ||
          razaoSocial !== store.razao_social ||
          cnpj !== store.cnpj ||
          description !== store.description ||
          zipCode !== store.zip_code ||
          street !== store.stree ||
          number !== store.number ||
          neighborhood !== store.neighborhood ||
          complement !== store.complement ||
          city !== store.city ||
          state !== store.state
        ) {
          const storeInfo = {
            name,
            razaoSocial,
            cnpj,
            description,
            zipCode,
            street,
            number,
            neighborhood,
            complement,
            city,
            state,
          };
          await updateStoreInfo(storeInfo);
        }

        if (
          facebook !== store.facebook ||
          instagram !== store.instagram ||
          youtube !== store.youtube ||
          twitter !== store.twitter ||
          website !== store.website
        ) {
          const socialMedia = {
            facebook: facebook || '',
            instagram: instagram || '',
            youtube: youtube || '',
            twitter: twitter || '',
            website: website || '',
          };
          await updateSocialMedia(socialMedia);
        }
      }
    });
  }

  async function handleZipCode(text) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    await setZipCode(text);

    if (text && text.length > 8) {
      findZipCode(text);

      setZipCode(address.zipCode);
      setStreet(address.street || '');
      setNeighborhood(address.neighborhood || '');
      setCity(address.city);
      setState(address.state);
    }
  }
  const DocumentMask = (text) => {
    text = text.replace(/\D/g, '');
    text = text.replace(/^(\d{2})(\d{3})(\d)/g, '$1.$2.$3');
    text = text.replace(/(\d{3})(\d{4})(\d{2})$/, '$1/$2-$3');

    return text;
  };

  function handleSetCnpj(text) {
    setCnpj(DocumentMask(text));
  }

  return (
    <Container>
      <Cart>
        {store && (
          <>
            <Store>
              <StoreInfoArea>
                <Name>
                  <Label>Nome</Label>
                  <Input
                    setState={setName}
                    placeholder="Digite aqui o nome da sua loja."
                    value={name}
                  />
                </Name>
                <CompanyName>
                  <Label>Razão social</Label>
                  <Input value={razaoSocial} setState={setRazaoSocial} />
                </CompanyName>
                <Document>
                  <Label>CNPJ</Label>
                  <Input length={18} value={cnpj} setState={handleSetCnpj} />
                </Document>
                <Description>
                  <Label>Descrição</Label>
                  <textarea
                    onChange={(text) => setDescription(text.target.value)}
                    maxLength={255}
                    placeholder="Digite aqui umas descrição adicional da sua loja."
                    value={description}
                  />
                </Description>
              </StoreInfoArea>
              <Address>
                <ZipCode>
                  <Label>CEP</Label>
                  <Input setState={handleZipCode} value={zipCode} />
                </ZipCode>
                <Street>
                  <Label>Rua</Label>
                  <Input
                    setState={setStreet}
                    placeholder="Digite aqui sua rua."
                    value={street}
                  />
                </Street>
                <Number>
                  <Label>Número</Label>
                  <Input
                    setState={setNumber}
                    placeholder="Digite aqui seu número."
                    value={number}
                  />
                </Number>
                <Neighborhood>
                  <Label>Bairro</Label>
                  <Input setState={setNeighborhood} value={neighborhood} />
                </Neighborhood>
                <CityArea>
                  <City>
                    <Label>Cidade</Label>
                    <Input value={city} disabled />
                  </City>
                  <State>
                    <Label>Estado</Label>
                    <Input value={state} disabled />
                  </State>
                </CityArea>
                <Complement>
                  <Label>Complemento</Label>
                  <Input
                    setState={setComplement}
                    placeholder="Digite aqui o complemento do endereço."
                    value={complement}
                  />
                </Complement>
              </Address>
            </Store>
            <SocialMedia>
              <Subtitle>Redes sociais</Subtitle>
              <Line>
                <Facebook>
                  <Label>
                    <AiFillFacebook />
                    Facebook
                  </Label>
                  <Input
                    setState={setFacebook}
                    placeholder="Digite aqui o link do Facebook."
                    value={facebook}
                  />
                </Facebook>
                <Instagram>
                  <Label>
                    <AiFillInstagram />
                    <span>Instagram</span>
                  </Label>
                  <Input
                    setState={setInstagram}
                    placeholder="Digite aqui o link do Instagram."
                    value={instagram}
                  />
                </Instagram>
              </Line>
              <Line>
                <Youtube>
                  <Label>
                    <AiOutlineYoutube />
                    <span>Youtube</span>
                  </Label>
                  <Input
                    setState={setYoutube}
                    placeholder="Digite aqui o link do canal no Youtube."
                    value={youtube}
                  />
                </Youtube>
                <Twitter>
                  <Label>
                    <AiOutlineTwitter />
                    <span>Twitter</span>
                  </Label>
                  <Input
                    setState={setTwitter}
                    placeholder="Digite aqui o link do canal no Twitter."
                    value={twitter}
                  />
                </Twitter>
              </Line>
              <Line>
                <Website>
                  <Label>
                    <CgWebsite />
                    <span>Website</span>
                  </Label>
                  <Input
                    setState={setWebsite}
                    placeholder="Digite aqui o link do canal no Website."
                    value={website}
                  />
                </Website>
              </Line>
            </SocialMedia>

            <ButtonArea>
              <Button onClick={() => handleSave()}>
                <span>Salvar</span>
              </Button>
            </ButtonArea>
          </>
        )}
      </Cart>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(StoreActions, dispatch);

const mapStateToProps = (state) => ({
  store: state.store.storeInfo,
  address: state.store.address,
});
export default connect(mapStateToProps, mapDispatchToProps)(StoreInfo);
