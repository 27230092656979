import styled from 'styled-components';

import Circle from 'react-circle';
import { Container as ContainerTitle } from '../../../components/Title/styles';

export const Container = styled.div`
  width: 100%;
`;

export const Background = styled.div`
  width: 100%;
  height: 30%;
  background: ${(props) => props.theme.colors.linearGradientGreen};
`;

export const TitleStyled = styled(ContainerTitle)`
  color: #fff;
`;

export const Body = styled.div`
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 576px) {
    justify-content: center;
    align-items: center;
  }
`;

export const Filter = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px #00000050;
  cursor: pointer;
  transition: all 0.6s;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }

  @media (max-width: 576px) {
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
  }
`;

export const DateArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  background: #00000020;
  position: fixed;
  padding-bottom: 180px;

  @media (max-width: 576px) {
    padding-bottom: 0;
  }
`;

export const ModalDate = styled.div`
  background: #fff;
  box-shadow: 0px 2px 3px #00000050;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;

  @media (max-width: 576px) {
    width: 100%;
    height: 100% !important;
    border-radius: 0;
  }
`;

export const ConfirmArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.greenMedium};
  box-shadow: 0px 2px 3px #00000050;
  color: #fff;
  transition: all 0.6s;
  font-weight: bold;
  width: 100%;

  &:hover {
    background: ${(props) => props.theme.colors.greenDark};
    box-shadow: 2px 5px 7px #00000050;
  }
`;

export const DateTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  cursor: pointer;

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 30px;
  padding: 20px 0;
  color: #fff;
  font-weight: bold;

  @media (max-width: 576px) {
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
`;

export const CardsArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0px 2px 3px #00000050;
  border-radius: 10px;
  padding: 0 20px;
`;

export const Card = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 576px) {
    flex-direction: column;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
  }
`;

export const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-size: 16px;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 10px;
`;

export const Amount = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  padding: 5px 0;
`;

export const CircleArea = styled.div`
  display: flex;
`;

export const CircleCustomized = styled(Circle).attrs((props) => ({
  progressColor: props.theme.colors.greenMedium,
}))``;
