import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Map,
  TileLayer,
  FeatureGroup,
  Marker,
  Tooltip,
  Circle,
  Polygon,
} from 'react-leaflet';
// import FullscreenControl from 'react-leaflet-fullscreen';
// import 'react-leaflet-fullscreen/dist/styles.css';

import swal from 'sweetalert';

import { MdMyLocation } from 'react-icons/md';
import { GiPositionMarker } from 'react-icons/gi';
import { FaRegSave } from 'react-icons/fa';

import { EditControl } from 'react-leaflet-draw';
import Control from 'react-leaflet-control';
import * as StoreActions from '../../../../store/modules/store/actions';
import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import { Container, ControlCard, Locate, EditMarker } from './styles';

function MapArea({
  store,
  requestStoreLocation,
  handleModalArea,
  setLatArea,
  setLongArea,
  setPositions,
  setRadiusArea,
  getStoreInfo,
  deliveryAreas,
  delivery,
}) {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [center, setCenter] = useState([-20, -47.48]);
  const [editMarker, setEditMarker] = useState(false);

  async function getData() {
    await getStoreInfo();
  }

  useEffect(() => {
    getData();

    if (delivery) {
      if (store && store.latitude && store.longitude) {
        setZoom(14);
        setLatitude(store.latitude);
        setLongitude(store.longitude);
        setCenter([store.latitude, store.longitude]);
      }
      if (store && !store.latitude && !store.longitude) {
        swal({
          title: 'Bem vindo!',
          html: true,
          text: `Antes de começarmos, siga os passos :  \r\n -> Ative a sua localização no canto superior direito do mapa \r\n -> Permita que o navegador acesse a sua localização.`,
          icon: 'info',
          button: 'Entendi!',
        });
      }
    }
  }, [delivery, store]);

  async function handleLocate() {
    await navigator.geolocation.getCurrentPosition(async function (position) {
      await setLatitude(position.coords.latitude);
      await setLongitude(position.coords.longitude);
      await setZoom(14);
      await setCenter([position.coords.latitude, position.coords.longitude]);
      await requestStoreLocation(
        position.coords.latitude,
        position.coords.longitude
      );

      setTimeout(() => {
        swal({
          title: 'Atenção!',
          text:
            'Caso a localização esteja incorreta, ajuste seguindo os passos: \r\n -> Precione no marcador no canto superior direito. \r\n -> Mova o marcador do mapa até a sua localização. \r\n -> Precione em salvar no canto superior direito.  ',
          icon: 'info',
          button: 'Entendi!',
        });
      }, 1000);
      await getStoreInfo();
    });
  }

  function HandleCreated(e) {
    const type = e.layerType;

    if (type === 'circle') {
      setLatArea(e.layer._latlng.lat);
      setLongArea(e.layer._latlng.lng);
      setRadiusArea(e.layer._mRadius);
      handleModalArea(true);
    } else {
      setLatArea(e.layer._bounds.getCenter().lat);
      setLongArea(e.layer._bounds.getCenter().lng);
      setPositions(e.layer._latlngs);
      handleModalArea(true);
    }
  }

  function handlePosition(e) {
    const { lat } = e.target._latlng;
    const { lng } = e.target._latlng;
    setLatitude(lat);
    setLongitude(lng);
    setZoom(14);
  }

  function handleSaveMarker() {
    setEditMarker(false);
    requestStoreLocation(latitude, longitude);
  }

  return (
    <Container deliveryAreas={deliveryAreas}>
      <Map
        center={center}
        zoom={zoom}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        {/* <FullscreenControl position="topright" /> */}
        <Control position="topright">
          <ControlCard>
            <Locate editMarker={editMarker} onClick={() => handleLocate()}>
              <MdMyLocation size={20} />
            </Locate>
            <>
              {!editMarker ? (
                <EditMarker onClick={() => setEditMarker(true)}>
                  <GiPositionMarker size={20} />
                </EditMarker>
              ) : (
                <EditMarker onClick={() => handleSaveMarker()}>
                  <FaRegSave size={20} />
                </EditMarker>
              )}
            </>
          </ControlCard>
        </Control>
        {latitude && longitude && (
          <Marker
            ondrag={handlePosition}
            draggable={editMarker}
            position={[latitude, longitude]}
          >
            <Tooltip>Sua localização</Tooltip>
          </Marker>
        )}
        <FeatureGroup>
          <EditControl
            position="topleft"
            onEdited={(e) => console.log(e)}
            onCreated={HandleCreated}
            onDeleted={(e) => console.log(e)}
            draw={{
              polyline: false,
              circlemarker: false,
              marker: false,
              polygon: latitude != null && longitude != null,
              rectangle: latitude != null && longitude != null,
              circle: latitude != null && longitude != null,
            }}
            edit={{ remove: false, edit: false }}
          />
        </FeatureGroup>
        {deliveryAreas &&
          deliveryAreas.map((deliveryArea) => {
            return deliveryArea?.status &&
              deliveryArea?.geolocation?.length <= 1
              ? deliveryArea.geolocation.map((geo) => {
                  return (
                    <Circle
                      key={geo.id}
                      center={[geo.latitude, geo.longitude]}
                      fillColor="blue"
                      radius={parseFloat(geo.radius)}
                    >
                      <Tooltip>{deliveryArea.name}</Tooltip>
                    </Circle>
                  );
                })
              : deliveryArea.status && deliveryArea.positions.length > 0 && (
                  <Polygon
                    key={deliveryArea.id}
                    positions={deliveryArea.positions}
                  >
                    <Tooltip>{deliveryArea.name}</Tooltip>
                  </Polygon>
                );
          })}
      </Map>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...StoreActions, ...DeliveryActions }, dispatch);

const mapStateToProps = (state) => ({
  store: state.store.storeInfo,
  deliveryAreas: state.delivery.deliveryAreas,
  delivery: state.store.storeInfo.settings.restrict_delivery_area,
});
export default connect(mapStateToProps, mapDispatchToProps)(MapArea);
