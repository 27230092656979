import React, { useState, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getStoreSlug } from '../../utils/storeUtil';
import * as DeliveryActions from '../../store/modules/delivery/actions';
import * as StoreActions from '../../store/modules/store/actions';
import * as AuthActions from '../../store/modules/auth/actions';

import history from '../../services/history';

import ModalEditDelivery from './components/ModalEditDelivery';

import ModalArea from './components/ModalArea';
import MapArea from './components/MapArea';
import DeliveryInformations from './components/DeliveryInformations';

import { Container, Body, DeliveryInfo } from './styles';

function Delivery({
  getStoreInfo,
  getDeliveryAreas,
  deliveryAreas,
  modalEditDelivery,
  delivery,
}) {
  const [latArea, setLatArea] = useState(null);
  const [longArea, setLongArea] = useState(null);
  const [radiusArea, setRadiusArea] = useState(null);
  const [positions, setPositions] = useState([]);

  async function getData() {
    await getDeliveryAreas(1);
    await getStoreInfo();
  }

  useEffect(() => {
    document.title = 'I9P - Delivery';
    getData();
  }, []);

  useEffect(() => {
    if (!delivery) history.push(`/${getStoreSlug()}`);
  }, [delivery]);

  return (
    <Container>
      {modalEditDelivery && <ModalEditDelivery />}
      <ModalArea
        setLatArea={setLatArea}
        setLongArea={setLongArea}
        setRadiusArea={setRadiusArea}
        setPositions={setPositions}
        positions={positions}
        latitude={latArea}
        longitude={longArea}
        radius={radiusArea}
      />
      <Body>
        <DeliveryInfo>
          <MapArea
            setLatArea={setLatArea}
            setLongArea={setLongArea}
            setPositions={setPositions}
            setRadiusArea={setRadiusArea}
          />

          {deliveryAreas?.length > 0 ? <DeliveryInformations /> : <></>}
        </DeliveryInfo>
      </Body>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...StoreActions, ...DeliveryActions, ...AuthActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  store: state.store.storeInfo,
  deliveryAreas: state.delivery.deliveryAreas,
  modalEditDelivery: state.delivery.modalEditDelivery,
  delivery: state.store.storeInfo.settings.restrict_delivery_area,
});
export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
