import React, { useState, useEffect } from 'react';

import { FiPlus, FiMinus } from 'react-icons/fi';

import { toast } from 'react-toastify';
import Select from 'react-select';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StoreActions from '../../../../store/modules/store/actions';
import * as OptionActions from '../../../../store/modules/option/actions';

import Input from '../../../components/Input';
import Tooltip from '../../../components/Tooltip';
import { Title } from '../../../components/Title';
import { ButtonCancel, ButtonSave } from '../../../components/Buttons';
import CheckBox from '../../../components/CheckBox';

import {
  Container,
  Modal,
  Body,
  SubTitle,
  TooltipArea,
  LimitArea,
  CheckBoxArea,
  List,
  Item,
  Line,
  InputArea,
  NewItem,
  ButtonNewItem,
  ButtonDelete,
  ButtonsArea,
  SelectArea,
  Price,
  ButtonArea,
} from './styles';

function ModalOption({
  getTablePrices,
  isVariation,
  handleModalOption,
  createOption,
  tablePriceOptions,
}) {
  const [groupName, setGroupName] = useState('');
  const [groupMin, setGroupMin] = useState('');
  const [groupMax, setGroupMax] = useState('');
  const [repeatOption, setRepeatOption] = useState(false);
  const [items, setItems] = useState([
    {
      name: '',
      code: '',
      prices: [{ tablePriceId: '', price: '' }],
    },
  ]);

  useEffect(() => {
    getTablePrices();
    const input = document.getElementById('inputGroup');
    if (input) input.focus();
  }, [getTablePrices]);

  function handleGrousetGroupName(text) {
    setGroupName(text);
  }

  const formatPrice = (text) => {
    text = text.replace(/\D/g, '');
    text = text.replace(/([0-9]{2})$/g, ',$1');

    if (text.length > 6) {
      text = text.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }
    return text;
  };

  function handleChangeTablePrice(e, index, indexPrice) {
    const array = [...items];
    array[index].prices[indexPrice] = {
      ...array[index].prices[indexPrice],
      tablePriceId: e.value,
    };
    setItems(array);
  }

  function handleChangePrice(text, index, indexPrice) {
    const array = [...items];
    array[index].prices[indexPrice] = {
      ...array[index].prices[indexPrice],
      price: formatPrice(text),
    };
    setItems(array);
  }

  function handleChange(field, text, index) {
    const array = [...items];
    array[index] = {
      ...array[index],
      [field]: text,
    };
    setItems(array);
  }

  function handleSave() {
    if (!groupName) {
      return toast.error('Digite o nome do grupo desta variação.');
    }
    const nameError = [];
    const priceError = [];
    items.map((item, index) => {
      if (!item.name) {
        return nameError.push(index);
      }
      if (!item.price || parseFloat(item.price) === 0) {
        return priceError.push(index);
      }
      return false;
    });
    if (nameError.length > 0) {
      return toast.error('Nome obrigatório.');
    }
    if (priceError.length > 0) {
      return toast.error('Preço obrigatório.');
    }

    if (isVariation) {
      return createOption(
        {
          groupName,
          groupMin: 0,
          groupMax: 0,
          variation: true,
          repeatOption: false,
        },
        items
      );
    }
    return createOption(
      {
        groupName,
        groupMin,
        groupMax,
        repeatOption,
        variation: false,
      },
      items
    );
  }

  function handleNewItem() {
    setItems((state) => [
      ...state,
      { name: '', code: '', prices: [{ tablePriceId: '', price: '' }] },
    ]);
    const list = document.getElementById('list');
    if (list) {
      setTimeout(() => {
        list.scrollTo({
          top: list.scrollHeight,
        });
      }, 100);
    }
  }

  function handleDeleteItem(index) {
    const array = [...items];
    array.splice(index, 1);
    setItems(array);
  }

  function handleNewPrice(index) {
    const array = [...items];
    array[index].prices.push({ tablePriceId: '', price: '' });
    setItems(array);
  }

  function handleDeletePrice(index, indexPrice) {
    const array = [...items];
    array[index].prices.splice(indexPrice, 1);
    setItems(array);
  }

  return (
    <Container>
      <Modal>
        <Body>
          <Title
            text={isVariation ? 'Cadastro de variação' : 'Cadastro de opções'}
          />
          <SubTitle>Grupo</SubTitle>
          <TooltipArea>
            <Tooltip
              info={
                isVariation
                  ? 'Informe o nome do grupo de variações, este nome ficará como título de cada seção de variações. Ex( Medidas, Tamanhos, Embalagens, etc. )'
                  : 'Informe o nome do grupo de opções, este nome ficará como título de cada seção de opções. Ex( Babidas, Utensílios, Diversos, etc.  )'
              }
            />
            {isVariation ? (
              <span>Nome do título das variações</span>
            ) : (
              <span>Nome do título das opções</span>
            )}
          </TooltipArea>
          <Input
            id="inputGroup"
            placeholder={
              isVariation
                ? 'Digite o nome do grupo de variações'
                : 'Digite o nome do grupo de opções'
            }
            value={groupName}
            setState={(text) => handleGrousetGroupName(text)}
          />
          {!isVariation && (
            <LimitArea>
              <InputArea>
                <TooltipArea>
                  <Tooltip info="Informe a quantidade mínima que o usuário pode selecionar dessa opção." />
                  <span>Quantidade mínima</span>
                </TooltipArea>
                <Input type="number" setState={setGroupMin} />
              </InputArea>
              <InputArea>
                <TooltipArea>
                  <Tooltip info="Informe a quantidade máxima que o usuário pode selecionar dessa opção." />
                  <span>Quantidade máximo</span>
                </TooltipArea>
                <Input type="number" setState={setGroupMax} />
              </InputArea>
              <InputArea>
                <CheckBoxArea>
                  <TooltipArea>
                    <Tooltip info="Caso habilitado, o usuário poderá repetir as opções." />
                    <span>Repetir opção</span>
                  </TooltipArea>
                  <CheckBox handleStatus={setRepeatOption} id="repeat" />
                </CheckBoxArea>
              </InputArea>
            </LimitArea>
          )}
          <SubTitle>{isVariation ? 'Variações' : 'Opções'}</SubTitle>
          <List id="list">
            {items.length > 0 &&
              items.map((item, index) => (
                <Item id={`id-${index}`}>
                  <Line>
                    <InputArea>
                      <span>Nome </span>
                      <Input
                        value={items[index].name}
                        setState={(text) => handleChange('name', text, index)}
                        placeholder="Digite o nome da variação"
                      />
                    </InputArea>

                    <InputArea>
                      <span>Código</span>
                      <Input
                        setState={(text) => handleChange('code', text, index)}
                        placeholder="Digite o código da variação"
                      />
                    </InputArea>
                    {items.length > 1 && (
                      <NewItem>
                        <ButtonDelete>
                          <FiMinus onClick={() => handleDeleteItem(index)} />
                        </ButtonDelete>
                      </NewItem>
                    )}
                  </Line>
                  {item.prices.map((price, indexPrice) => (
                    <Line>
                      <SelectArea>
                        <span>Selecione a tabela de preço</span>
                        <Select
                          options={tablePriceOptions}
                          onChange={(e) =>
                            handleChangeTablePrice(e, index, indexPrice)
                          }
                          className="select"
                        />
                      </SelectArea>
                      <Price>
                        <InputArea>
                          <span>Preço</span>
                          <Input
                            value={`R$ ${items[index].prices[indexPrice].price}`}
                            setState={(text) =>
                              handleChangePrice(text, index, indexPrice)
                            }
                            placeholder="Digite o preço da variação"
                          />
                        </InputArea>
                        {item.prices.length === indexPrice + 1 ? (
                          <NewItem>
                            <ButtonNewItem
                              onClick={() => handleNewPrice(index)}
                            >
                              <FiPlus />
                            </ButtonNewItem>
                          </NewItem>
                        ) : (
                          <NewItem>
                            <ButtonDelete>
                              <FiMinus
                                onClick={() =>
                                  handleDeletePrice(index, indexPrice)
                                }
                              />
                            </ButtonDelete>
                          </NewItem>
                        )}
                      </Price>
                    </Line>
                  ))}
                </Item>
              ))}
          </List>
          <ButtonArea>
            <ButtonSave onClick={() => handleNewItem()} text="Nova variação" />
          </ButtonArea>
          <ButtonsArea>
            <ButtonCancel onClick={() => handleModalOption()} text="Cancelar" />
            <ButtonSave
              onClick={() => handleSave()}
              text="Salvar"
              type="submit"
            />
          </ButtonsArea>
        </Body>
      </Modal>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...OptionActions, ...StoreActions }, dispatch);

const mapStateToProps = (state) => ({
  isVariation: state.option.isVariation,
  inputsItem: state.option.inputsItem,
  tablePriceOptions: state.products.tablePriceOptions,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalOption);
