import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tree from 'react-animated-tree';

import * as ProductsActions from '../../../../store/modules/products/actions';

import {
  Container,
  Title,
  Body,
  CategoryList,
  Category,
  NewCategory,
  CurrentCategory,
  SubTitle,
  Name,
  ParentCategory,
  Children,
  Child,
} from './styles';

function CategoriesList({ getCategories, categories: categoriesAndChildren }) {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  function renderAllCategory(categories) {
    categories.map((category) => {
      if (category.children && category.children.length > 0) {
        setAllCategories((state) => [...state, category]);
        return renderAllCategory(category.children);
      }
      return setAllCategories((state) => [...state, category]);
    });
  }

  useEffect(() => {
    renderAllCategory(categoriesAndChildren);
  }, [categoriesAndChildren]);

  function handleCategory(category) {
    setCurrentCategory(category);
  }

  function renderCategory(categories) {
    return categories.map((category) => {
      if (category.children && category.children.length > 0) {
        return (
          <Tree
            style={{ borderLeft: '1px solid black !important' }}
            type={
              <Category onClick={() => handleCategory(category)}>
                {category.name}
              </Category>
            }
          >
            {renderCategory(category.children)}
            <NewCategory>Nova categoria</NewCategory>
          </Tree>
        );
      }

      return (
        <Tree
          type={
            <Category onClick={() => handleCategory(category)}>
              {category.name}
            </Category>
          }
        >
          <NewCategory>Nova categoria</NewCategory>
        </Tree>
      );
    });
  }

  function searchCategory(categoryId) {
    const categoryFiltered = allCategories.filter((category) => {
      return category.id === categoryId;
    });

    return categoryFiltered && categoryFiltered.length > 0
      ? categoryFiltered[0].name
      : null;
  }

  return (
    <Container>
      <Title>Categorias</Title>
      <Body>
        <CategoryList>{renderCategory(categoriesAndChildren)}</CategoryList>
        {currentCategory && (
          <CurrentCategory>
            <Name>
              <span>{currentCategory.name}</span>
            </Name>
            {currentCategory.parent_id && (
              <ParentCategory>
                <span>Categoria pai</span>
                <span>{searchCategory(currentCategory.parent_id)}</span>
              </ParentCategory>
            )}
            {currentCategory.children && currentCategory.children.length > 0 && (
              <>
                <SubTitle>
                  <span>Subcategorias</span>
                </SubTitle>
                <Children>
                  {currentCategory.children.map((child) => {
                    return (
                      <Child onClick={() => handleCategory(child)}>
                        {child.name}
                      </Child>
                    );
                  })}
                </Children>
              </>
            )}
          </CurrentCategory>
        )}
      </Body>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  categories: state.products.categories,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
