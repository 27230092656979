import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #ddd;
  background: #fff;
  max-height: 39px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;

    & + div {
      border-left: 1px solid #ddd;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  max-height: 150px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 15px 15px #80828b26;
  overflow-y: auto;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const Delivery = styled.div`
  display: flex;
  color: ${(props) => !props.status && '#ddd'};
  text-align: center;

  & + div {
    border-top: 1px solid #ddd;
  }
`;

export const Name = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const Price = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const TimeMin = styled.div`
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const TimeMax = styled.div`
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
export const Status = styled.div`
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const CheckBoxArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.status ? 'flex-end' : 'center')};
  align-items: center;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4db6ac;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const OptionsArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const animate = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const OptionsMenu = styled.div`
  display: ${(props) =>
    props.menuRef === props.deliveryArea ? 'flex' : 'none'};
  position: relative;
  height: 80px;
  width: 80px;
  right: 0px !important;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  animation: ${animate} 1s;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Edit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.8s;
  width: 100%;
  padding: 5px;
  border-radius: 10px 10px 0 0;
  &:hover {
    background: #eeeeee;
  }
`;

export const Delete = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.8s;
  width: 100%;
  padding: 5px;
  border-radius: 0 0 10px 10px;
  &:hover {
    background: #eeeeee;
  }
`;
