import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 20px 20px 0 20px;
  padding: 20px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const ContactList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  overflow-y: auto;
  max-height: ${(props) => props.height}px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const ContactResquest = styled.div`
  display: flex;
  border-radius: 10px;

  &:nth-child(odd) {
    background: #f7f7f7;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-weight: bold;
`;

export const Name = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const Phone = styled(Name)``;

export const ProductName = styled(Name)``;

export const ProductPrice = styled(Name)``;

export const Date = styled(Name)``;

export const Status = styled(Name)`
  .select {
    width: 80%;
  }
`;

export const StatusColor = styled.div`
  width: 30px;
  height: 100%;
  border-radius: 0 10px 10px 0;
`;
