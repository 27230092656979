import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as StoreActions from '../../store/modules/store/actions';
import * as OrdersActions from '../../store/modules/orders/actions';
import * as AuthActions from '../../store/modules/auth/actions';

import { Title } from '../components/Title';
import List from './components/List';
import OrderItem from './components/OrderItem';
import Loading from '../components/Loading';
import ContactRequests from './components/ContactRequests';
import ModalTracks from './components/ModalTracks';

import {
  Container,
  Body,
  ContentContainer,
  WithOutOrders,
  Cart,
} from './styles';

function Orders({
  orders,
  getStoreInfo,
  getOrders,
  loading,
  nextPage,
  showcase,
}) {
  const [modalTrackingOrderVisible, setModalTrackingOrderVisible] = useState(
    false
  );

  async function getData() {
    await getStoreInfo();
    await getOrders(nextPage);
  }

  useEffect(() => {
    document.title = 'I9P - Painel';

    if (nextPage !== 0) getData();
  }, [nextPage]);

  return (
    <Container>
      {modalTrackingOrderVisible && (
        <ModalTracks
          setModalTrackingOrderVisible={setModalTrackingOrderVisible}
          modalTrackingOrderVisible={modalTrackingOrderVisible}
        />
      )}

      <Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {showcase ? (
              <ContactRequests />
            ) : (
              <>
                <Title text="Pedidos" />
                {orders && orders.length <= 0 ? (
                  <WithOutOrders>
                    <Cart>
                      <span>Nenhum pedido disponível no momento.</span>
                    </Cart>
                  </WithOutOrders>
                ) : (
                  <ContentContainer>
                    <List />
                    <OrderItem
                      setModalTrackingOrderVisible={
                        setModalTrackingOrderVisible
                      }
                    />
                  </ContentContainer>
                )}
              </>
            )}
          </>
        )}
      </Body>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...StoreActions, ...OrdersActions, ...AuthActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  nextPage: state.orders.nextPage,
  orders: state.orders.orders,
  loading: state.orders.loading,
  showcase: state.store?.storeInfo?.settings?.showcase,
});
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
