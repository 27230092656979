import { takeLatest, all, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  setOrders,
  setOrderItem,
  handleLoading,
  handleLoadingProducts,
  setNextPage,
  setContactRequests,
  updateContactRequests,
  setNextPageContactRequest,
  handleLoadingTracks,
  setTrackingOrder,
} from './actions';
import { signOut } from '../auth/actions';

import { validationResponse } from '../../../utils/validations';

import api from '../../../services/api';

export function* getOrderItem({ payload }) {
  yield put(handleLoadingProducts(true));

  const response = yield api.getOrders(payload.orderId);

  yield put(setOrderItem(response && response.data));
  yield put(handleLoadingProducts(false));
  yield put(handleLoading(false));
}

export function* getOrders({ payload }) {
  const response = yield api.getOrders(null, payload.nextPage);

  const validation = validationResponse(response);

  if (!validation) {
    api.getApiInstance().defaults.headers.Authorization = ``;
    return yield put(signOut());
  }

  yield put(setOrders(response && response.data.orders));
  yield put(setNextPage(response && response.data.nextPage));

  if (
    response &&
    response.data &&
    response.data.orders &&
    response.data.orders.length > 0 &&
    response.data.nextPage <= 2
  ) {
    yield put({
      type: 'GET_ORDER_ITEM',
      payload: {
        orderId: response.data.orders[0].id,
      },
    });
  }
  return yield put(handleLoading(false));
}

export function* updateStatusOrder(payload) {
  const { status, orderId } = payload;
  const response = yield api.updateOrderStatus(status, orderId);
  if (response.data) {
    yield put({ type: 'GET_ORDERS' });
  }
}

export function* getContactRequests({ payload }) {
  const response = yield api.getContactRequests(payload.nextPage);

  const validation = validationResponse(response);
  if (!validation) {
    return toast.error(
      'Erro ao buscar solicitações de contato, tente novamente mais tarde.'
    );
  }
  yield put(setNextPageContactRequest(response.data.nextPage));
  return yield put(setContactRequests(response.data.contactRequests));
}

export function* updateContactRequest({ payload }) {
  const response = yield api.updateContactRequest(payload.id, payload.status);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao atualizar situação do contato.');
  }

  yield put(updateContactRequests(response.data));
  return toast.success('Situação atualizada com sucesso!');
}

export function* getTrackingOrder({ payload }) {
  yield put(handleLoadingTracks(true));
  const data = {
    tracking_code: payload.trackingCode,
  };
  const response = yield api.getTrackingOrder(data);

  yield put(setTrackingOrder(response.data));

  yield put(handleLoadingTracks(false));
}

export default all([
  takeLatest('GET_ORDERS', getOrders),
  takeLatest('GET_ORDER_ITEM', getOrderItem),
  takeLatest('UPDATE_STATUS_ORDER', updateStatusOrder),
  takeLatest('GET_CONTACT_REQUESTS', getContactRequests),
  takeLatest('UPDATE_CONTACT_REQUEST', updateContactRequest),
  takeLatest('GET_TRACKING_ORDER', getTrackingOrder),
]);
