import produce from 'immer';

const INITIAL_STATE = {
  variations: [],
  options: [],
  modalOptionVisible: false,
  isVariation: false,
};

export default function option(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_VARIATIONS': {
        draft.variations = action.payload.variations;
        break;
      }
      case 'SET_OPTIONS': {
        draft.options = action.payload.options;
        break;
      }
      case 'HANDLE_MODAL_OPTION': {
        draft.modalOptionVisible = action.payload.status;
        draft.isVariation = action.payload.isVariation;

        break;
      }
      case 'PUT_VARIATION': {
        draft.variations.push(action.payload.variation);

        break;
      }
      case 'PUT_OPTION': {
        draft.options.push(action.payload.option);

        break;
      }

      default:
        break;
    }
  });
}
