import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};
`;
export const Customers = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const Customer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.6s;
  cursor: pointer;
  padding: 12px 0;

  &:nth-child(odd) {
    /* background: #f7f7f7; */
    background: #f1f3fa;
  }

  &:hover {
    color: #000;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }
  @media (max-width: 420px) {
    font-size: 12px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  border-radius: 0.25rem;
  width: 100%;
  height: calc(
    100vh - 4.75rem - 70px - 12px
  ); // tela - titulo - header - padding bottom
  padding: 20px;

  @media (max-width: 576px) {
    padding: 10px;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f1f3fa;
  margin: 12px 0;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const Search = styled.input`
  width: 100%;
  height: 38px;
  border-radius: 0.25rem;
  padding: 10px 10px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.grayLight};
  background: #f1f3fa;
  /* background: #f5f5f5; */
  border: none;
  color: #6c757d;

  &::placeholder {
    color: #adb5bd;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-top: 1px solid #e3eaef;
  border-bottom: 1px solid #e3eaef;
  padding: 12px 0;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const Sort = styled.div`
  display: flex;

  cursor: pointer;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Id = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 10px;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    flex: 0.5;
  }
`;
export const Name = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;

export const Document = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const Phone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const Options = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 992px) {
    flex: 0.5;
  }
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const IdArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  transition: color 0.6s;
  padding: 0 5px;

  @media (max-width: 768px) {
    flex: 0.5;
  }
`;

export const NameArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  padding: 0 5px;
`;

export const DocumentArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 5px;

  @media (max-width: 768px) {
    flex: 0.5;
  }
`;

export const PhoneArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 5px;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const WithOutCustomers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`;

export const ButtonCustomer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  width: 100%;
`;
export const ButtonAddCustomer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  border: 0;
  padding: 0.45rem 0.9rem;
  padding-top: 0.45rem;
  padding-right: 0.9rem;
  padding-bottom: 0.45rem;
  padding-left: 0.9rem;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const OptionsArea = styled.div`
  display: none;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  svg {
    height: 25px;
    width: 25px;
  }

  @media (max-width: 992px) {
    display: flex;
  }
`;
