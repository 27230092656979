import { takeLatest, all, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import api from '../../../services/api';

import { validationResponse } from '../../../utils/validations';

import {
  setAlert,
  setAlerts,
  setAlertActive,
  handleModalAlerts,
  setUpdatedAlert,
  removeAlert,
} from './actions';

export function* getAlerts() {
  const response = yield api.getAlerts();

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar alertas, tente novamente masi tarde.');
  }

  return yield put(setAlerts(response.data));
}

export function* createAlert({ payload }) {
  const response = yield api.createAlert(payload.alert);

  const validation = validationResponse(response);
  if (!validation) {
    return toast.error('Erro ao criar alerta, tente novamente mais tarde!');
  }
  yield put(setAlert(response.data));
  yield put(handleModalAlerts(false));
  yield put({ type: 'GET_ALERTS' });
  return toast.success('Aviso criado com sucesso!');
}

export function* updateAlertActive({ payload }) {
  const response = yield api.updateAlert(
    { active: payload.status },
    payload.alertId
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao atualizar status da mensagem, tente novamente mais tarde.'
    );
  }

  yield put(setAlertActive(response.data));

  if (response.data.active) {
    return toast.success('Mensagem ativada com sucesso!');
  }

  return toast.success('Mensagem desativada com sucesso!');
}

export function* updateAlert({ payload }) {
  const response = yield api.updateAlert(payload.data, payload.alertId);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao atualizar mensagem, tente novamente mais tarde.'
    );
  }

  yield put(handleModalAlerts(false));

  yield put(setUpdatedAlert(response.data));

  if (response.data.frequency_type === 'Day') {
    return toast.success('Mensagem diária editada com sucesso!');
  }
  if (response.data.frequency_type === 'Week') {
    return toast.success('Mensagem semanal editada com sucesso!');
  }

  return toast.success('Mensagem mensal editada com sucesso!');
}

export function* deleteAlert({ payload }) {
  const response = yield api.deleteAlert(payload.alertId);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao excluir mensagem, tente novamente mais tarde.');
  }

  toast.success('Mensagem excluída com sucesso!');

  return yield put(removeAlert(response.data.id));
}

export default all([
  takeLatest('GET_ALERTS', getAlerts),
  takeLatest('CREATE_ALERT', createAlert),
  takeLatest('UPDATE_ALERT_ACTIVE', updateAlertActive),
  takeLatest('UPDATE_ALERT', updateAlert),
  takeLatest('DELETE_ALERT', deleteAlert),
]);
