import React, { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { BiMessageSquareEdit } from 'react-icons/bi';
import { GoTrashcan } from 'react-icons/go';

import swal from 'sweetalert';
import * as AlertsAction from '../../../../store/modules/alerts/actions';

import CheckBox from '../../../components/CheckBox';

import {
  Container,
  Header,
  Title,
  AlertsArea,
  Alert,
  Value,
  Message,
  EditArea,
  ButtonEdit,
  ButtonDelete,
  WithoutAlert,
  ButtonArea,
  Button,
} from './styles';

function Alerts({
  handleModalAlerts,
  getAlerts,
  alerts,
  updateAlertActive,
  setCurrentAlert,
  deleteAlert,
}) {
  function verifyFrequencyType(frequencyType) {
    if (frequencyType === 'Day') {
      return 'Diário';
    }
    if (frequencyType === 'Week') {
      return 'Semanal';
    }

    return 'Mensal';
  }

  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  function handleActive(event, alertId) {
    updateAlertActive(event.target.checked, alertId);
  }

  function handleEditAlert(alert) {
    handleModalAlerts(true);
    setCurrentAlert(alert);
  }
  function handleNewAlert() {
    handleModalAlerts(true);
    setCurrentAlert(null);
  }

  function handleDeleteAlert(alert) {
    swal({
      title: 'Atenção',
      text: `Deseja realmente excluir a mensagem ${verifyFrequencyType(
        alert.frequency_type
      )} - ${alert.title}?`,
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
      dangerMode: true,
    }).then((response) => {
      if (response) {
        deleteAlert(alert.id);
      }
    });
  }

  return (
    <Container>
      <Header>
        <Title>Título</Title>
        <Title>Mensagem</Title>
        <Title>Frequência</Title>
        <Title>Status</Title>
        <EditArea />
      </Header>
      <AlertsArea>
        {alerts.length > 0 ? (
          alerts.map((alert) => (
            <Alert key={alert.id}>
              <Value>{alert.title}</Value>
              <Message>{alert.message}</Message>
              <Value>{verifyFrequencyType(alert.frequency_type)}</Value>
              <Value>
                <CheckBox
                  id={alert.id}
                  status={alert.active}
                  handleStatus={(e) => handleActive(e, alert.id)}
                />
                <span>{alert.active ? 'Ativo' : 'Inativo'}</span>
              </Value>
              <EditArea>
                <ButtonEdit onClick={() => handleEditAlert(alert)}>
                  <BiMessageSquareEdit />
                </ButtonEdit>
                <ButtonDelete>
                  <GoTrashcan onClick={() => handleDeleteAlert(alert)} />
                </ButtonDelete>
              </EditArea>
            </Alert>
          ))
        ) : (
          <WithoutAlert>
            <span>Nenhuma mensagem cadastrada.</span>
          </WithoutAlert>
        )}
      </AlertsArea>
      <ButtonArea>
        <Button onClick={() => handleNewAlert()}>Novo aviso</Button>
      </ButtonArea>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AlertsAction, dispatch);

const mapStatetoProps = (state) => ({
  modalAlerts: state?.alerts?.modalAlerts,
  alerts: state?.alerts?.alerts,
});

export default connect(mapStatetoProps, mapDispatchToProps)(Alerts);
