import React from 'react';

import { Container } from './styles';

import LoadingStore from '../../../components/Loading';

export default function Loading() {
  return (
    <Container>
      <LoadingStore />
    </Container>
  );
}
