import { takeLatest, all, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { validationResponse } from '../../../utils/validations';

import {
  setVariations,
  setOptions,
  putVariations,
  putOptions,
  handleModalOption,
} from './actions';

export function* getOptions() {
  const response = yield api.getOptions();

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao bsucar opções, tente novamente mais tarde.');
  }

  if (response.data.length <= 0) {
    yield put(setVariations([]));
    return yield put(setOptions([]));
  }

  const variations = response.data.filter((opt) => opt.variation === true);
  const options = response.data.filter((opt) => opt.variation === false);

  yield put(setVariations(variations));
  return yield put(setOptions(options));
}

export function* createOption({ payload }) {
  const {
    variation,
    groupName,
    groupMin,
    groupMax,
    repeatOption,
  } = payload.group;
  const dataGroup = {
    variation,
    title: groupName,
    min_choices: groupMin,
    max_choices: groupMax,
    repeat_option: repeatOption,
  };

  const group = yield api.createGroup(dataGroup);

  const validation = validationResponse(group);

  if (!validation) {
    return toast.error('Erro ao criar título, tente novamente mais tarde.');
  }

  const { items } = payload;

  const optionErrors = [];
  const optionsCreated = [];
  yield all(
    items.map(function* (item) {
      const dataItem = {
        option_group_id: group.data.id,
        name: item.name,
        code: item.code,
      };

      const option = yield api.createOption(dataItem);

      const validationOption = validationResponse(option);
      if (!validationOption) {
        return optionErrors.push(option);
      }

      optionsCreated.push(option.data);
      // TODO create table price product
      // item.prices.map((price) => {
      //   const dataPrice = {};
      // });
    })
  );

  if (optionErrors.length > 0) {
    return toast.error(
      `Erro ao criar ${
        group.data.variation ? 'variações' : 'opções'
      }, tente novamente mais tarde.`
    );
  }

  group.data.option_items = optionsCreated;

  if (group.data.variation) {
    yield put(putVariations(group.data));
  } else {
    yield put(putOptions(group.data));
  }

  yield put(handleModalOption(false));

  return toast.success(
    `${group.data.variation ? 'Variações' : 'Opções'} criadas com sucesso!`
  );
}

export default all([
  takeLatest('GET_OPTIONS', getOptions),
  takeLatest('CREATE_OPTION', createOption),
]);
