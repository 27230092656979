import styled from 'styled-components';
import chroma from 'chroma-js';

export const Container = styled.div`
  width: 100%;
  height: calc(
    100vh - 70px - 12px - 39px - 150px
  ); // tela - header - padding bottom - list header - list data

  border-radius: 5px;
`;

export const ControlCard = styled.div`
  height: auto;
  width: 25px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  transition: background 0.2s;
`;

export const Locate = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: ${(props) => props.theme.colors.greenDark};
  padding: 5px 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${chroma('white').darken(0.2)};
  }
`;

export const EditMarker = styled.div`
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #6e7989;
  padding: 5px 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${chroma('white').darken(0.2)};
  }
`;
