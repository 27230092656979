import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  animation: ${animate} 0.6s;

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ConfigArea = styled.div`
  display: flex;
  margin-top: 10px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const TitleConfig = styled.div`
  display: flex;
`;

export const Box = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  flex: 1;

  span {
    margin-left: 5px;
  }

  @media (max-width: 992px) {
    & + div {
      margin-top: 10px;
    }
  }
`;

export const BoxResponsive = styled(Box)`
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const TablePrice = styled(Box)`
  width: 50%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .select {
    width: 100%;
    margin-top: 10px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;

export const ButtonCancel = styled(Button)`
  background: ${(props) => props.theme.colors.redDark};
  margin-right: 20px;
  animation: ${animate} 0.6s;

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
  }
`;
