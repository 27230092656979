import styled from 'styled-components';

export const Container = styled.div`
  flex: 3;
  background-color: #fff;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const ListOrders = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  height: calc(
    100vh - 4.75rem - 70px - 12px
  ); // tela - titulo - header - padding bottom
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 20px 25px;
  font-weight: bold;
  font-size: 18px;
  color: ${(props) => props.theme.colors.greenDark};
  background: #fff;
`;

export const ArrayList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* height: 780px; */
  width: 100%;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const ContainerOrder = styled.div``;

export const Cart = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Order = styled.div`
  border-radius: 2px;
  margin-top: 5px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: ${(props) => (props.orderRef === props.order ? '1' : '0')};
  width: ${(props) => (props.orderRef === props.order ? '105%' : '100%')};
  box-shadow: ${(props) =>
    props.orderRef === props.order ? '0px 15px 15px #80828b26;' : 'none'};
  transition: width 0.5s, box-shadow 0.5s;

  span {
    margin-right: 2px;
    font-weight: bold;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    width: 100%;
  }
`;

export const Body = styled.div`
  background: ${(props) =>
    props.orderRef === props.order ? '#b8dafe' : '#fff'};
  color: ${(props) =>
    props.orderRef === props.order ? '#fff' : props.theme.colors.grayDark};
  font-weight: ${(props) =>
    props.orderRef === props.order ? 'bold' : 'normal'};
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: ${(props) => (props.orderRef === props.order ? '96%' : '95%')};

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    padding: 10px;
    background: #fff;
    color: ${(props) => props.theme.colors.grayDark};
  }
`;

export const Id = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;

  @media (max-width: 992px) {
    padding: 0;
  }
`;

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1300px) {
    display: none;
  }
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StatusColor = styled.div`
  border-radius: 2px;
  width: 5%;
  min-height: 100%;
  display: ${(props) => (props.orderRef === props.order ? 'none' : 'flex')};
  align-items: flex-end;
  justify-content: flex-end;
  background: ${(props) => props.status === 'aprovado' && '#b2dbb2'};
  background: ${(props) => props.status === 'pendente' && '#fcf1de'};
  background: ${(props) => props.status === 'cancelado' && '#edafa4'};

  @media (max-width: 1300px) {
    display: none;
  }
`;

export const OnlinePaymentsArea = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => {
    switch (props.status) {
      case 'Aprovada': {
        return '#b2dbb2';
      }
      case 'Aguardando Pagamento': {
        return '#fcf1de';
      }
      case 'Em Processamento': {
        return '#fcf1de';
      }
      case 'Em Contestação': {
        return '#fcf1de';
      }
      case 'Em Monitoramento': {
        return '#fcf1de';
      }
      case 'Cancelada': {
        return '#edafa4';
      }
      case 'Reprovada	': {
        return '#edafa4';
      }
      default:
        return '#edafa4';
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case 'Aprovada': {
        return '#609b40';
      }
      case 'Aguardando Pagamento': {
        return '#cc7912';
      }
      case 'Em Processamento': {
        return '#cc7912';
      }
      case 'Em Contestação': {
        return '#cc7912';
      }
      case 'Em Monitoramento': {
        return '#cc7912';
      }
      case 'Cancelada': {
        return '#c43232';
      }
      case 'Reprovada	': {
        return '#c43232';
      }
      default:
        return '#c43232';
    }
  }};
`;

export const FooterMobile = styled.div`
  padding: 10px;
  width: 100%;
  font-weight: bold;
  display: none;
  height: auto;
  align-items: center;
  justify-content: center;
  background: ${(props) => {
    if (props.status === 'aprovado') {
      return '#b2dbb2';
    }
    if (props.status === 'pendente') {
      return '#fcf1de';
    }
    return '#edafa4';
  }};

  color: ${(props) => {
    if (props.status === 'aprovado') {
      return '#609b40';
    }
    if (props.status === 'pendente') {
      return '#cc7912';
    }
    return '#c43232';
  }};

  div {
    display: flex;
    flex: 1;

    & + div {
      justify-content: center;
    }
  }

  .id {
    display: none;
    @media (max-width: 992px) {
      display: flex;
    }
  }

  @media (max-width: 1300px) {
    display: flex;
  }
`;
