import { takeLatest, all, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import history from '../../../services/history';
import { getStoreSlug } from '../../../utils/storeUtil';
import api from '../../../services/api';

import {
  setCustomers,
  setCurrentCustomer,
  setCustomerZipCode,
  setCustomersAdresses,
  handleModalAddress,
  updateAddressesList,
  removeAddress,
  handleLoadingCustomers,
} from './actions';

import { validationResponse } from '../../../utils/validations';

export function* getCustomers({ payload }) {
  const response = yield api.getCustomers(
    payload.page,
    payload.search,
    payload.sort,
    payload.direction
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar clientes, tente novamente mais tarde!');
  }

  yield put(
    setCustomers(response.data.customers, payload.page, response.data.nextPage)
  );
  return yield put(handleLoadingCustomers(false));
}

export function* getCustomer({ payload }) {
  const response = yield api.getCustomer(payload.customerId);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar clientes, tente novamente mais tarde!');
  }

  yield put(setCurrentCustomer(response.data));
  yield put(setCustomersAdresses(response.data.customer_address));

  return yield put(handleLoadingCustomers(false));
}

export function* createCustomer({ payload }) {
  const { customer } = payload;
  const response = yield api.createCustomer(customer);

  if (
    response &&
    response.response &&
    response.response.status &&
    response.response.status === 400
  ) {
    return toast.error(`${response.response.data.error}`);
  }

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao criar o clientes, tente novamente mais tarde.');
  }

  history.push(`/${getStoreSlug()}/clientes`);

  return toast.success('Cliente cadastrado com sucesso!');
}

export function* getCustomerAddresses({ payload }) {
  const response = yield api.getCustomerAddresses(payload.customerId);

  const validation = validationResponse(response);
  if (!validation) {
    return toast.error('Erro ao buscar endereços, tente novamente mais tarde.');
  }
  yield put(setCustomersAdresses(response.data));

  return true;
}

export function* createCustomerAddress({ payload }) {
  const response = yield api.createCustomerAdresses(
    payload.address,
    payload.customerId
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao criar endereço, tente novamente mais tarde.');
  }

  yield put(updateAddressesList(response.data));

  return toast.success('Endereço criado com sucesso!');
}

export function* findCustomerZipCode(payload) {
  const { zipCode } = payload;

  const response = yield api.getZipCode(zipCode);

  if (response.data) {
    return yield put(
      setCustomerZipCode({
        zip_code: response.data.cep,
        street: response.data.street,
        number: '',
        state: response.data.state,
        city: response.data.city,
        neighborhood: response.data.neighborhood,
      })
    );
  }

  return toast.error('CEP inválido');
}

export function* updateCustomer({ payload }) {
  const response = yield api.updateCustomer(
    payload.customerId,
    payload.customer
  );

  const validation = validationResponse(response);
  if (!validation) {
    return toast.error('Erro ao editar cliente, tente novamente mais tarde.');
  }

  return toast.success('Cliente editado com sucesso!');
}

export function* updateCustomerAddress({ payload }) {
  const response = yield api.updateCustomerAddress(
    payload.customerId,
    payload.customerAddressId,
    payload.customerAddress
  );
  const validation = validationResponse(response);
  if (!validation) {
    return toast.error('Erro ao editar endereço, tente novamente mais tarde!');
  }
  const { customerId } = payload;
  yield put({ type: 'GET_CUSTOMER_ADDRESSES', payload: { customerId } });
  yield put(handleModalAddress(false));

  return toast.success('Endereço editado com sucesso!');
}

export function* deleteCustomerAddress({ payload }) {
  const response = yield api.deleteCustomerAddress(
    payload.address,
    payload.customerId
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao exluir endereço, tente novamente mais tarde.');
  }

  yield put(removeAddress(payload.address));
  return toast.success('Endereço cadastrado com sucesso!');
}

export function* deleteCustomer({ payload }) {
  const response = yield api.deleteCustomer(payload.customerId);

  const validation = validationResponse(response);
  if (!validation) {
    return toast.error('Erro ao excluir cliente, tente novamente mais tarde.');
  }

  yield put(handleModalAddress(true));
  return toast.success('Cliente excluído com sucesso!');
}

export default all([
  takeLatest('GET_CUSTOMERS', getCustomers),
  takeLatest('GET_CUSTOMER', getCustomer),
  takeLatest('CREATE_CUSTOMER', createCustomer),
  takeLatest('GET_CUSTOMER_ADDRESSES', getCustomerAddresses),
  takeLatest('CREATE_CUSTOMER_ADDRESS', createCustomerAddress),
  takeLatest('FIND_CUSTOMER_ZIP_CODE', findCustomerZipCode),
  takeLatest('UPDATE_CUSTOMER', updateCustomer),
  takeLatest('UPDATE_CUSTOMER_ADDRESS', updateCustomerAddress),
  takeLatest('DELETE_CUSTOMER_ADDRESS', deleteCustomerAddress),
  takeLatest('DELETE_CUSTOMER', deleteCustomer),
]);
