import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  animation: ${animate} 0.6s;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 0;

  svg {
    margin-right: 5px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LayoutArea = styled.div`
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const PrimaryColor = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  width: 50%;
  height: auto;
  padding: 0px 10px 20px 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Color = styled.div`
  display: flex;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: ${(props) => props.color};
  box-shadow: 0px 2px 3px #00000050;
  cursor: ${(props) => (props.disable ? 'normal' : 'pointer')};
  transition: box-shadow 0.6s;
  opacity: ${(props) => (props.disable ? 0.5 : 1)};

  &:hover {
    box-shadow: 1px 4px 6px #00000050;
  }
`;

export const Disable = styled.div`
  pointer-events: ${(props) => (props.disable ? 'none' : 'all')};

  opacity: ${(props) => (props.disable ? '0.5' : 1)};
  background: ${(props) => (props.disable ? '#ccc' : 'transparent')};
`;

export const StoreInfo = styled(PrimaryColor)``;

export const InputFile = styled.div`
  width: auto;
  height: auto;
  border: 1px dashed #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  transition: border 2s;
  margin: 20px 0 0 20px;
  background: ${(props) => props.disable && '#00000015'};
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  display: flex;
  flex-direction: column;
  padding: 10px;

  &:hover {
    border: ${(props) => !props.disable && '1px solid #f5f5f5'};
  }
`;

export const LogoArea = styled.div`
  display: flex;
  border: 1px solid #f5f5f5;
  width: 50%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 0 20px 20px 20px;

  @media (max-width: 992px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Preview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-top: 10px;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 10px;
  justify-self: flex-end;
  animation: ${animate} 0.6s;
`;

export const Delete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
  width: 30px;
  height: 50px;
  color: ${(props) => props.theme.colors.redDark};
  padding: 5px;
  cursor: pointer;
  transition: background 0.6s, border 0.6s, color 0.5s;

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
    color: #fff;
  }
`;

export const Edit = styled(Delete)`
  border-radius: 0 0 5px 5px;
  color: ${(props) => props.theme.colors.greenDark};

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    color: #fff;
  }
`;

export const LogoPreview = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  height: 100px;
  width: 100px;
  background-image: ${(props) => ` url(${props.image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  opacity: ${(props) => props.disable && 0.5};

  svg {
    cursor: pointer;
    background: #fff;
    border-radius: 10px;
  }
`;

export const WaterMarkArea = styled(LogoArea)`
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const WaterMarkPreview = styled(LogoPreview)``;

export const BannerArea = styled(LogoArea)`
  flex-direction: column;
  width: 100%;
`;

export const BannerPreview = styled(LogoPreview)`
  width: 960px;
  height: 190px;
  background-size: cover;
`;
export const BannerMobilePreview = styled(LogoPreview)`
  width: 640px;
  height: 260px;
  background-size: contain;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;

export const ButtonCancel = styled(Button)`
  background: ${(props) => props.theme.colors.redDark};
  margin-right: 20px;
  animation: ${animate} 0.6s;

  &:hover {
    background: ${(props) => props.theme.colors.redMedium};
  }
`;
