import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swal from 'sweetalert';

import { FaPencilAlt } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';

import { BsThreeDotsVertical } from 'react-icons/bs';
import { formatPrice } from '../../../../utils/format';

import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import {
  Container,
  Card,
  Delivery,
  Header,
  Body,
  Status,
  Name,
  Price,
  TimeMin,
  TimeMax,
  CheckBoxArea,
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  OptionsArea,
  Options,
  OptionsMenu,
  Edit,
  Delete,
} from './styles';

function StoreInformations({
  deliveryAreas,
  handleDeliveryStatus,
  handleEditDelivery,
  handleCurrentDelivery,
  deleteDeliveryArea,
  getDeliveryAreas,
  nextPage,
}) {
  const [menuRef, setMenuRef] = useState(null);

  function handleChangeStatus(event, deliveryId) {
    handleDeliveryStatus(event.target.checked, deliveryId);
  }

  function handleMenu(deliveryArea) {
    if (deliveryArea === menuRef) {
      setMenuRef(null);
      return;
    }

    setMenuRef(deliveryArea);
  }

  function handleEdit(deliveryArea) {
    handleEditDelivery(true);
    handleCurrentDelivery(deliveryArea);
  }

  function handleDelete(DeliveryId) {
    swal({
      title: 'Deseja realmente excluir?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDeliveryArea(DeliveryId);
      }
    });
  }

  function handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (nextPage !== 0) {
        getDeliveryAreas(nextPage);
      }
    }
  }

  return (
    <Container>
      <Header>
        <Name>
          <span>Descrição da área</span>
        </Name>
        <Price>
          <span>Taxa de entrega</span>
        </Price>
        <TimeMin>
          <span>Tempo mínimo de entrega</span>
        </TimeMin>
        <TimeMax>
          <span>Tempo máximo de entrega</span>
        </TimeMax>
        <Status>
          <span>Status</span>
        </Status>
      </Header>

      <Card onScroll={handleScroll}>
        <>
          <Body>
            {deliveryAreas.map((deliveryArea) => {
              return (
                <Delivery status={deliveryArea.status} key={deliveryArea.id}>
                  <Name>
                    <span>{deliveryArea.name}</span>
                  </Name>
                  <Price>
                    <span>{formatPrice(deliveryArea.price, 2)}</span>
                  </Price>
                  <TimeMin>
                    <span>
                      {deliveryArea.min_waiting_time_formated}{' '}
                      {deliveryArea.min_waiting_type}
                    </span>
                  </TimeMin>
                  <TimeMax>
                    <span>
                      {deliveryArea.max_waiting_time_formated}{' '}
                      {deliveryArea.max_waiting_type}
                    </span>
                  </TimeMax>
                  <Status>
                    <CheckBoxArea status={deliveryArea.status}>
                      <CheckBoxWrapper>
                        <CheckBox
                          onChange={(e) =>
                            handleChangeStatus(e, deliveryArea.id)
                          }
                          id={deliveryArea.id}
                          type="checkbox"
                          checked={deliveryArea.status}
                        />
                        <CheckBoxLabel htmlFor={deliveryArea.id} />
                      </CheckBoxWrapper>
                    </CheckBoxArea>
                    {deliveryArea.status && (
                      <OptionsArea>
                        <Options onClick={() => handleMenu(deliveryArea)}>
                          <BsThreeDotsVertical size={30} />
                        </Options>
                        <OptionsMenu
                          menuRef={menuRef}
                          deliveryArea={deliveryArea}
                        >
                          <Edit onClick={() => handleEdit(deliveryArea)}>
                            <FaPencilAlt />
                            <span>Editar</span>
                          </Edit>
                          <Delete onClick={() => handleDelete(deliveryArea.id)}>
                            <RiDeleteBin5Line />
                            <span>Excluir</span>
                          </Delete>
                        </OptionsMenu>
                      </OptionsArea>
                    )}
                  </Status>
                </Delivery>
              );
            })}
          </Body>
        </>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(DeliveryActions, dispatch);

const mapStateToProps = (state) => ({
  deliveryAreas: state.delivery.deliveryAreas,
  deliveryLoading: state.delivery.deliveryLoading,
  nextPage: state.delivery.nextPage,
});
export default connect(mapStateToProps, mapDispatchToProps)(StoreInformations);
