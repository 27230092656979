import React, { useState, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import * as CustomerActions from '../../../../store/modules/customer/actions';

import Label from '../../../components/Label';
import Input from '../../../components/Input';
import {
  Box,
  Area,
  ButtonArea,
  ButtonCancel,
  SubmitButton,
} from '../../styles';
import { Container, ModalStyled, Form, Title } from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function ModalAddress({
  modalAddress,
  handleModalAddress,
  addressToEdit,
  createCustomerAddress,
  updateAddressesList,
  updateCustomerAddress,
  editAddressList,
  currentCustomer,
  findCustomerZipCode,
  customerAddress,
}) {
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  useEffect(() => {
    setZipCode(addressToEdit?.zip_code || '');
    setStreet(addressToEdit?.street || '');
    setNumber(addressToEdit?.number || '');
    setComplement(addressToEdit?.complement || '');
    setNeighborhood(addressToEdit?.neighborhood || '');
    setCity(addressToEdit?.city || '');
    setState(addressToEdit?.state || '');
  }, [addressToEdit]);

  // addres from search cep
  useEffect(() => {
    if (!customerAddress) return;

    setComplement(customerAddress.complement || '');
    setStreet(customerAddress.street || '');
    setCity(customerAddress.city || '');
    setNeighborhood(customerAddress.neighborhood || '');
    setState(customerAddress.state || '');
    setNumber(customerAddress.number || '');
  }, [customerAddress]);

  function handleCleanFields() {
    setZipCode('');
    setStreet('');
    setComplement('');
    setNumber('');
    setState('');
    setCity('');
    setNeighborhood('');
  }

  function handleIsChanged() {
    return !(
      street === addressToEdit.street &&
      complement === addressToEdit.complement &&
      number === addressToEdit.number &&
      state === addressToEdit.state &&
      city === addressToEdit.city &&
      neighborhood === addressToEdit.neighborhood
    );
  }

  function handleZipCode(text) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    setZipCode(text);
    if (text && text.length > 8) {
      findCustomerZipCode(text);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (currentCustomer) {
      // creating new address
      if (!addressToEdit) {
        createCustomerAddress(currentCustomer.id, {
          zip_code: zipCode,
          street,
          complement,
          number,
          state,
          city,
          neighborhood,
        });
        return handleModalAddress(false);
      }
      // editing address
      if (!handleIsChanged()) {
        handleModalAddress(false);
        return toast.info('Sem alterações');
      }

      return updateCustomerAddress(
        addressToEdit.customer_id,
        addressToEdit.id,
        {
          zip_code: zipCode,
          street,
          complement,
          number,
          state,
          city,
          neighborhood,
        }
      );
    }

    if (!currentCustomer) {
      // creating new address
      if (!addressToEdit) {
        updateAddressesList({
          tempId: new Date().getTime() + Math.random(), // temp id
          zip_code: zipCode,
          street,
          complement,
          number,
          state,
          city,
          neighborhood,
        });
        handleCleanFields();
        return handleModalAddress(false);
      }

      // editing address
      editAddressList({
        tempId: addressToEdit.tempId,
        zip_code: zipCode,
        street,
        complement,
        number,
        state,
        city,
        neighborhood,
      });

      handleCleanFields();
      return handleModalAddress(false);
    }

    return true;
  }

  function handleCancel() {
    handleModalAddress(false);
  }

  return (
    <Container>
      <ModalStyled isOpen={modalAddress} style={customStyles}>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Title>Novo endereço</Title>
          <Box>
            <Area flex={1}>
              <Label>
                CEP <span style={{ marginLeft: '5px', color: '#f00' }}> *</span>
              </Label>
              <Input
                required
                length={9}
                setState={handleZipCode}
                value={zipCode}
                placeholder="Digite o CEP do cliente."
              />
            </Area>{' '}
            <Area flex={3}>
              <Label>
                Endereço{' '}
                <span style={{ marginLeft: '5px', color: '#f00' }}> *</span>
              </Label>
              <Input
                required
                setState={setStreet}
                value={street}
                placeholder="Digite a rua do cliente."
              />
            </Area>
          </Box>
          <Box>
            <Area flex={1}>
              <Label>Complemento (opcional) </Label>
              <Input
                placeholder="Digite o complemento de endereço do cliente."
                setState={setComplement}
                value={complement}
              />
            </Area>
          </Box>

          <Box>
            <Area flex={1}>
              <Label>
                Bairro
                <span style={{ marginLeft: '5px', color: '#f00' }}> *</span>
              </Label>
              <Input
                placeholder="Digite o bairro do cliente."
                required
                setState={setNeighborhood}
                value={neighborhood}
              />
            </Area>{' '}
            <Area flex={1}>
              <Label>
                Numero da residência
                <span style={{ marginLeft: '5px', color: '#f00' }}> *</span>
              </Label>
              <Input
                required
                type="number"
                setState={setNumber}
                value={number}
                placeholder="Digite o número do endereço do cliente."
              />
            </Area>
          </Box>
          <Box>
            <Area flex={1}>
              <Label>
                Cidade{' '}
                <span style={{ marginLeft: '5px', color: '#f00' }}> *</span>
              </Label>
              <Input
                placeholder="Digite a cidade do cliente."
                required
                setState={setCity}
                value={city}
              />
            </Area>
            <Area flex={1}>
              <Label>
                Estado
                <span style={{ marginLeft: '5px', color: '#f00' }}> *</span>
              </Label>
              <Input
                placeholder="Digite o estado do endereço do cliente."
                required
                setState={setState}
                value={state}
              />
            </Area>
          </Box>
          <ButtonArea>
            <ButtonCancel onClick={() => handleCancel()}>
              <span>Cancelar</span>
            </ButtonCancel>
            {addressToEdit ? (
              <SubmitButton disable={false}>
                <span>Editar endereço</span>
              </SubmitButton>
            ) : (
              <SubmitButton disable={false}>
                <span>Adicionar endereço</span>
              </SubmitButton>
            )}
          </ButtonArea>
        </Form>
      </ModalStyled>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...CustomerActions }, dispatch);

const mapStateToProps = (state) => ({
  customerAddress: state.customer.customerAddress,
  modalAddress: state.customer.modalAddress,
  addressToEdit: state.customer.addressToEdit,
  currentCustomer: state.customer.currentCustomer,
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalAddress);
