export function handleModalAlerts(state) {
  return {
    type: 'HANDLE_MODAL_ALERTS',
    payload: { state },
  };
}

export function getAlerts() {
  return {
    type: 'GET_ALERTS',
  };
}

export function createAlert(alert) {
  return {
    type: 'CREATE_ALERT',
    payload: { alert },
  };
}

export function setAlert(alerts) {
  return {
    type: 'SET_ALERT',
    payload: { alerts },
  };
}
export function setAlerts(alerts) {
  return {
    type: 'SET_ALERTS',
    payload: { alerts },
  };
}

export function updateAlertActive(status, alertId) {
  return {
    type: 'UPDATE_ALERT_ACTIVE',
    payload: { status, alertId },
  };
}

export function setAlertActive(alert) {
  return {
    type: 'SET_ALERT_ACTIVE',
    payload: { alert },
  };
}

export function setCurrentAlert(alert) {
  return {
    type: 'SET_CURRENT_ALERT',
    payload: { alert },
  };
}

export function updateAlert(alertId, data) {
  return {
    type: 'UPDATE_ALERT',
    payload: { alertId, data },
  };
}

export function setUpdatedAlert(alert) {
  return {
    type: 'SET_UPDATED_ALERT',
    payload: { alert },
  };
}

export function deleteAlert(alertId) {
  return {
    type: 'DELETE_ALERT',
    payload: { alertId },
  };
}

export function removeAlert(alertId) {
  return {
    type: 'REMOVE_ALERT',
    payload: { alertId },
  };
}
