import produce from 'immer';

const INITIAL_STATE = {
  alerts: [],
  currentAlert: {},
};

export default function store(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'HANDLE_MODAL_ALERTS': {
        draft.modalAlerts = action.payload.state;
        break;
      }

      case 'SET_ALERTS': {
        draft.alerts = action.payload.alerts;
        break;
      }

      case 'SET_ALERT_ACTIVE': {
        const alertIndex = draft.alerts.findIndex(
          (a) => a.id === action.payload.alert.id
        );
        if (alertIndex >= 0) {
          draft.alerts[alertIndex].active = action.payload.alert.active;
        }
        break;
      }

      case 'SET_CURRENT_ALERT': {
        draft.currentAlert = action.payload.alert;
        break;
      }

      case 'SET_UPDATED_ALERT': {
        const alertIndex = draft.alerts.findIndex(
          (a) => a.id === action.payload.alert.id
        );
        if (alertIndex >= 0) {
          draft.alerts[alertIndex] = action.payload.alert;
        }
        break;
      }
      case 'REMOVE_ALERT': {
        const alertIndex = draft.alerts.findIndex(
          (a) => a.id === parseInt(action.payload.alertId, 10)
        );
        if (alertIndex >= 0) {
          draft.alerts.splice(alertIndex, 1);
        }
        break;
      }

      default:
    }
  });
}
