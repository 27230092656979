import React from 'react';

import { AiOutlineLoading } from 'react-icons/ai';
import { Container, FirstLoad, SecondLoad, Image } from './styles';

import i9p from '../../../assets/images/I9P-01.svg';

function Loading() {
  return (
    <Container>
      <FirstLoad>
        <AiOutlineLoading />
      </FirstLoad>
      <SecondLoad>
        <Image image={i9p} />
      </SecondLoad>
    </Container>
  );
}

export default Loading;
