import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  max-width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);

  @media (max-width: 992px) {
    padding: 10px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding-top: 20px;

  @media (max-width: 992px) {
    padding-top: 10px;
  }

  &:first-child {
    padding-top: 0px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  flex: ${(props) => props.flex};
  padding: 0 5px;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SelectArea = styled.div`
  display: flex;
  justify-content: space-between;

  .select {
    margin-bottom: 10px;
    width: 90%;
  }
`;

export const ButtonCreateCategory = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background: ${(props) => props.theme.colors.greenDark};
  cursor: pointer;
  transition: color 0.5s, background 0.6s, box-shadow 0.6s;
  box-shadow: 0px 3px 4px #00000050;

  &:hover {
    box-shadow: 0px 6px 8px #00000050;
    color: #fff;
    background: ${(props) => props.theme.colors.greenMedium};
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;

export const TablePricesHeader = styled.div`
  display: flex;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding: 12px 0;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TablePricesField = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
`;

export const TablePricesOptions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  width: auto;
  height: auto;
  flex: 1;

  @media (max-width: 768px) {
    display: none;
    flex: 0.2;
  }
`;

export const TablePricesOptionsMobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const TablePricesData = styled.div`
  display: flex;
  padding: 10px 0;
  flex: ${(props) => props.flex};
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  button {
    width: 10px;
  }

  svg {
    display: flex;
    width: 10px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

export const WithOutPrices = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const ButtonPlus = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    display: flex;
    font-size: 20px;
    margin-right: 20px;
  }
`;

export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;
  margin-left: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;

export const ButtonCancel = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.redDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;
  margin-right: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
    box-shadow: 2px 6px 8px #00000050;
  }
`;
