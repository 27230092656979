import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductsArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
`;

export const Tab = styled.div`
  width: 200px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${(props) => props.focus && '#fff'};
  padding: 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: color 0.2s, font-weight 0.2s, background 0.4s;
  color: ${(props) =>
    props.focus ? props.theme.colors.grayDark : props.theme.colors.greenDark};
  font-weight: ${(props) => !props.focus && 'bold'};

  & + div {
    margin-left: 10px;
  }

  &:hover {
    background: ${(props) => !props.focus && props.theme.colors.greenDark};
    color: ${(props) => !props.focus && '#fff'};
    font-weight: ${(props) => !props.focus && 'bold'};
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: ${(props) => (props.current ? '0px 10px 10px 10px' : '10px')};
  padding: 20px;
  height: calc(
    100vh - 4.75rem - 70px - 12px
  ); // tela - titulo - header - padding bottom

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
