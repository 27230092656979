import React from 'react';

import { Link } from 'react-router-dom';
import { getStoreSlug } from '../../../../utils/storeUtil';

import { Container, Side, Text, ReloadArea } from './styles';

function WithOutStore({ title, text, reload, image }) {
  return (
    <Container height={window.innerHeight}>
      <Side>
        <Text>
          <label>{title}</label>
          <span>{text}</span>
        </Text>
        {reload && (
          <ReloadArea>
            <Link to={`/${getStoreSlug()}`}>Recarregar</Link>
          </ReloadArea>
        )}
      </Side>
      <Side>
        <img src={image} alt="I9P" />
      </Side>
    </Container>
  );
}

export default WithOutStore;
