import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: #00000050;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
`;
export const TitleAlert = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  span {
    padding: 10px 0;
  }
  textarea {
    display: flex;
    justify-content: flex-start;
    resize: none;
    height: 100px;
    border: 1px solid #dee2e6;
  }
`;
export const MonthArea = styled.div`
  display: flex;
  flex-direction: column;
  span {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
  }
  input {
    padding: 10px;
  }

  textarea {
    min-width: 400px;
    padding: 10px;
  }
`;
export const Month = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const WeekDaysArea = styled.div`
  display: flex;
`;

export const WeekDays = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;

  input {
    border: 1px solid #dee2e6;
  }
`;

export const DayArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: ${(props) =>
    props.status ? props.theme.colors.greenDark : '#eee'};
  color: ${(props) => (props.status ? props.theme.colors.white : '#000')};
  cursor: pointer;
  font-weight: bold;
`;

export const TitleInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  svg {
    margin-left: 10px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;
export const ButtonArea = styled.div`
  display: flex;
  height: 100%;
  width: 90%;
`;
export const ButtonCancel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 40px;
  font-size: 18px;
  background: ${(props) => props.theme.colors.redLight};
  border-radius: 15px;
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border: 0;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.redDark};
    box-shadow: 2px 6px 8px #00000050;
  }
`;
export const ButtonAdd = styled(ButtonCancel)`
  background: ${(props) => props.theme.colors.greenMedium};
  &:hover {
    background: ${(props) => props.theme.colors.greenDark};
    box-shadow: 2px 6px 8px #00000050;
  }
`;
