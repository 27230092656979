import { takeLatest, all, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import history from '../../../services/history';
import { getStoreSlug } from '../../../utils/storeUtil';

import {
  setCategories,
  setProducts,
  updateCurrentProductPhoto,
  removeProductPhoto,
  updateProductPrice,
  updateCurrentProductPrices,
  removeProductTablePrice,
  updateCategories,
  setCurrentProduct,
  removeProduct,
  setOptions,
  updateOptions,
  updateTablePriceOptions,
  handleLoadingProductsList,
  setTablePriceOptions,
  setTablePrices,
  handleModalTablePrice,
} from './actions';

import { validationResponse } from '../../../utils/validations';

export function* createCategory({ payload }) {
  const response = yield api.createCategory(payload.name);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao criar categoria, tente novamente mais tarde');
  }

  yield put(
    updateOptions({ value: response.data.id, label: response.data.name })
  );
  yield put(updateCategories(response.data));

  return toast.success('Categoria criada com sucesso!');
}

let options = [];
function renderAllCategory(currentCategories) {
  if (currentCategories) {
    try {
      currentCategories.map((currentCategory) => {
        if (currentCategory.children && currentCategory.children.length > 0) {
          options.push({
            value: currentCategory.id,
            label: currentCategory.name,
          });
          return renderAllCategory(currentCategory.children);
        }
        return options.push({
          value: currentCategory.id,
          label: currentCategory.name,
        });
      });
    } catch (error) {
      options = [];
      renderAllCategory(currentCategories);
    }
  }

  return options;
}

export function* getCategories() {
  const response = yield api.getCategories();

  const validation = validationResponse(response);

  if (!validation) {
    yield put(setOptions([]));
    yield put(setCategories([]));

    return toast.error('Erro ao buscar categorias,tente novamente mais tarde.');
  }

  const allOptions = renderAllCategory(response.data);

  yield put(setOptions(allOptions));

  return yield put(setCategories(response.data));
}

export function* getProducts({ payload }) {
  const response = yield api.getProducts(
    payload.page,
    payload.search,
    payload.sort,
    payload.direction
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar produtos, tente novamente mais tarde!');
  }

  yield put(
    setProducts(response.data.products, payload.page, response.data.nextPage)
  );

  return yield put(handleLoadingProductsList(false));
}

export function* getProduct({ payload }) {
  const response = yield api.getProduct(payload.productId);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar produto, tente novamente mais tarde!');
  }

  yield put(yield put(setCurrentProduct(response.data)));

  return yield put(handleLoadingProductsList(false));
}

export function* createProduct({ payload }) {
  const response = yield api.createProduct(payload.product);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao criar o produto, tente novamente mais tarde.');
  }

  yield put(setCurrentProduct(null));

  history.push(`/${getStoreSlug()}/produtos/editar/${response.data.id}`);

  return toast.success('Produto cadastrado com sucesso!');
}

export function* updateProduct({ payload }) {
  const response = yield api.updateProduct(payload.product);

  if (response.status && response.status === 200) {
    history.push(`/${getStoreSlug()}/produtos`);

    return toast.success('Produto alterado com sucesso!');
  }
  return toast.error('Falha ao alterar produto, tente novamente mais tarde.');
}

export function* deleteProduct({ payload }) {
  const response = yield api.deleteProduct(payload.productId);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao excluir o produto, tente novamente mais tarde.'
    );
  }
  yield put(removeProduct(payload.productId));

  return toast.success('Produto excluido com sucesso!');
}

export function* updateProductAvaiable({ payload }) {
  const response = yield api.updateProductAvaiable(payload.productId, {
    available: payload.status,
  });

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao alterar status do produto, tente novamente mais tarde.'
    );
  }

  yield put({ type: 'GET_PRODUCTS', payload: { name: null, nextPage: 1 } });

  return toast.success('Status alterado com sucesso!');
}

export function* updateProductPhoto({ payload }) {
  const { productId, image } = payload;
  const response = yield api.updateProductPhoto(productId, image);

  if (response.status === 200) {
    return yield put(updateCurrentProductPhoto(response.data));
  }

  return toast.error('Falha ao registrar foto, tente novamento mais tarde.');
}

export function* deleteProductPhoto({ payload }) {
  const { productId, imageId } = payload;
  const response = yield api.deleteProductPhoto(productId, imageId);

  if (response.status && response.status === 200) {
    return yield put(removeProductPhoto(response.data.id));
  }

  return toast.error('Falha ao apagar foto, tente novamento mais tarde.');
}

export function* getTablePrices() {
  const response = yield api.getTablePrices();

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Ocorreu um erro ao buscar tabela de preço, tente novamente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  const tablePriceoptions = [];
  if (response.data && response.data.length > 0) {
    response.data.map((tablePrice) => {
      return tablePriceoptions.push({
        value: tablePrice.id,
        label: `${tablePrice.id} - ${tablePrice.name}`,
      });
    });
    yield put(setTablePriceOptions(tablePriceoptions));

    return yield put(setTablePrices(response.data));
  }
  return true;
}

export function* createProductTablePrice({ payload }) {
  const { productId, tablePriceId, price, promotionalPrice } = payload;

  const response = yield api.createProductTablePrice(
    productId,
    tablePriceId,
    price,
    promotionalPrice
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao criar tabela de preço, tente novamente mais tarde.'
    );
  }

  yield put(updateCurrentProductPrices(response.data));
  yield put(handleModalTablePrice(false));

  return toast.success('Produto atualizado com sucesso!');
}

export function* updateProductTablePrice({ payload }) {
  const response = yield api.updateProductTablePrice(
    payload.tablePriceId,
    payload.productId,
    payload.price,
    payload.promotionalPrice
  );

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao atualizar preço do produto tente novamente mais tarde.'
    );
  }

  yield put(
    updateProductPrice(
      response.data.id,
      response.data.regular_price,
      response.data.promotional_price
    )
  );
  yield put(handleModalTablePrice(false));

  return toast.success('Produto atualizado com sucesso!');
}

export function* deleteProductTablePrice({ payload }) {
  const response = yield api.deleteProductTablePrice(
    payload.tablePriceId,
    payload.productId
  );

  const validation = validationResponse(response);
  if (!validation) {
    return toast.error(
      'Erro ao excluir tabela de preço, tente novamente mais tarde.'
    );
  }

  yield put(removeProductTablePrice(response.data.id));

  return toast.success('Table de preço excluida com sucesso!');
}

export function* createTablePrice({ payload }) {
  const response = yield api.createTablePrice(payload.name);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao criar tabela de preços, tente novamente mais tarde.'
    );
  }

  yield put(
    updateTablePriceOptions({
      value: response.data.id,
      label: `${response.data.id} - ${response.data.name}`,
    })
  );

  return toast.success('Tabela de preços criada com sucesso!');
}

export default all([
  takeLatest('GET_CATEGORIES', getCategories),
  takeLatest('CREATE_CATEGORY', createCategory),
  takeLatest('GET_PRODUCTS', getProducts),
  takeLatest('CREATE_PRODUCT', createProduct),
  takeLatest('UPDATE_PRODUCT', updateProduct),
  takeLatest('DELETE_PRODUCT', deleteProduct),
  takeLatest('UPDATE_PRODUCT_AVAIABLE', updateProductAvaiable),
  takeLatest('UPDATE_PRODUCT_PHOTO', updateProductPhoto),
  takeLatest('DELETE_PRODUCT_PHOTO', deleteProductPhoto),
  takeLatest('GET_TABLE_PRICES', getTablePrices),
  takeLatest('CREATE_PRODUCT_TABLE_PRICE', createProductTablePrice),
  takeLatest('UPDATE_PRODUCT_TABLE_PRICE', updateProductTablePrice),
  takeLatest('DELETE_PRODUCT_TABLE_PRICE', deleteProductTablePrice),
  takeLatest('CREATE_TABLE_PRICE', createTablePrice),
  takeLatest('GET_PRODUCT', getProduct),
]);
