import { takeLatest, all, put } from 'redux-saga/effects';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import { validationResponse } from '../../../utils/validations';

import {
  handleModalArea,
  setDeliveryAreas,
  updateDeliveryArea,
  handleEditDelivery,
} from './actions';

export function* requestStoreLocation(payload) {
  yield api.updateStoreLocation(payload.latitude, payload.longitude);
}

export function* getDeliveryAreas({ payload }) {
  const response = yield api.getDeliveryAreas(payload.nextPage || 1);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar áreas, tente novamente mmais tarde.');
  }

  return yield put(setDeliveryAreas(response.deliveryAreas, response.nextPage));
}

export function* createDeliveryArea(payload) {
  const {
    name,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
    latitude,
    longitude,
    positions,
  } = payload;

  const status = true;
  const price = parseFloat(payload.price).toFixed(2);
  const radius = payload.radius ? payload.radius : 0;

  if (radius) {
    const geolocation = [{ lat: latitude, lng: longitude }];
    const response = yield api.createDeliveryArea(
      name,
      price,
      minWaitingTime,
      maxWaitingTime,
      minWaitingType,
      maxWaitingType,
      status,
      geolocation,
      radius
    );
    const validation = validationResponse(response);

    if (!validation) {
      return toast.error('erro ao criar área, tente novamente mais tarde.');
    }
    swal({
      title: 'Sucesso!',
      text: 'Área de delivery criado com sucesso!',
      icon: 'success',
      button: 'Continuar',
    });
    yield put({ type: 'GET_DELIVERY_AREAS', payload: { nextPage: 1 } });
    return yield put(handleModalArea(false));
  }
  if (positions.length >= 1) {
    const geolocation = [];

    positions[0].map((position) => {
      const { lat } = position;
      const { lng } = position;
      return geolocation.push({ lat, lng });
    });

    const response = yield api.createDeliveryArea(
      name,
      price,
      minWaitingTime,
      maxWaitingTime,
      minWaitingType,
      maxWaitingType,
      status,
      geolocation,
      radius
    );
    const valition = validationResponse(response);
    if (!valition) {
      toast.error('Erro ao criar área, tente novamente mais tarde.');
    }
    swal({
      title: 'Sucesso!',
      text: 'Área de delivery criado com sucesso!',
      icon: 'success',
      button: 'Continuar',
    });
    yield put({ type: 'GET_DELIVERY_AREAS', payload: { nextPage: 1 } });
    return yield put(handleModalArea(false));
  }
  return false;
}

export function* handleDeliveryStatus(payload) {
  const { status, deliveryId } = payload;
  const response = yield api.changeDeliveryStatus(status, deliveryId);
  const validation = validationResponse(response);

  if (!validation) {
    return toast.error(
      'Erro ao editar status da área, tente novamente mais tarde.'
    );
  }

  yield put(updateDeliveryArea(response));
  return toast.success('Status alterado com sucesso!');
}

export function* handleUpdateDelivery(payload) {
  const {
    deliveryId,
    name,
    price,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
  } = payload;
  const status = true;

  const deliveryArea = yield api.updateDelivery(
    deliveryId,
    name,
    price,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
    status
  );

  if (deliveryArea) {
    toast.success('Área alterada com sucesso!!');
    yield put(updateDeliveryArea(deliveryArea));
    yield put({ type: 'GET_DELIVERY_AREAS', payload: { nextPage: 1 } });
    yield put(handleEditDelivery(false));
  }
}

export function* handleDeleteDeliveryArea(payload) {
  const { deliveryId } = payload;

  const response = yield api.deleteDeliveryArea(deliveryId);

  if (response.status === 200) {
    yield put({ type: 'GET_DELIVERY_AREAS', payload: { nextPage: 1 } });
    return swal('Área de entrega excluida com sucesso!', {
      icon: 'success',
    });
  }
  return swal('Falha ao excluir área.', {
    icon: 'error',
  });
}

export default all([
  takeLatest('REQUEST_STORE_LOCATION', requestStoreLocation),
  takeLatest('CREATE_DELIVERY_AREA', createDeliveryArea),
  takeLatest('GET_DELIVERY_AREAS', getDeliveryAreas),
  takeLatest('HANDLE_DELIVERY_STATUS', handleDeliveryStatus),
  takeLatest('UPDATE_DELIVERY', handleUpdateDelivery),
  takeLatest('DELETE_DELIVERY_AREA', handleDeleteDeliveryArea),
]);
