import React, { useState } from 'react';

import swal from 'sweetalert';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Select from 'react-select';

import { Map, TileLayer, Circle, Polygon } from 'react-leaflet';
import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import {
  Container,
  Card,
  MapArea,
  Form,
  Header,
  Body,
  Name,
  Price,
  TimeMin,
  TimeInput,
  SelectArea,
  TimeMax,
  ButtonArea,
  ButtonCreate,
  ButtonCancel,
} from './styles';

function ModalArea({
  modalArea,
  latitude,
  longitude,
  radius,
  positions,
  createDeliveryArea,
}) {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [minWaitingTime, setMinWaitingTime] = useState('');
  const [maxWaitingTime, setMaxWaitingTime] = useState('');
  const [minWaitingType, setMinWaitingType] = useState({
    value: 'Minutos',
    label: 'Minutos',
    color: '#97bab3',
  });
  const [maxWaitingType, setMaxWaitingType] = useState({
    value: 'Minutos',
    label: 'Minutos',
    color: '#97bab3',
  });

  const options = [
    { value: 'Minutos', label: 'Minutos', color: '#97bab3' },
    { value: 'Horas', label: 'Horas', color: '#6D9DB1' },
    { value: 'Dias', label: 'Dias', color: '#716893' },
  ];

  function handleSubmit(e) {
    e.preventDefault();
    const minTime = minWaitingType.value;
    const maxTime = maxWaitingType.value;
    createDeliveryArea(
      name,
      price,
      minWaitingTime,
      maxWaitingTime,
      minTime,
      maxTime,
      latitude,
      longitude,
      radius,
      positions
    );
  }

  function handleCloseModal() {
    swal({
      title: 'Atenção!',
      html: true,
      text: `Deseja realmente cancelar?`,
      icon: 'warning',
      buttons: ['Não', 'Sim'],
    }).then((response) => {
      if (response) {
        window.location.reload();
      }
    });
  }

  return (
    modalArea && (
      <Container>
        <Card>
          <MapArea>
            <Map
              center={[latitude, longitude]}
              zoom={14}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              {radius && (
                <Circle
                  center={[latitude, longitude]}
                  fillColor="blue"
                  radius={radius}
                />
              )}
              {!radius && positions.length >= 1 && (
                <Polygon positions={positions} />
              )}
            </Map>
          </MapArea>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Header>
              <Name>
                <span>Descrição da área</span>
              </Name>
              <Price>
                <span>Taxa de entrega</span>
              </Price>
              <TimeMin>
                <span>Tempo mínimo de entrega</span>
              </TimeMin>
              <TimeMax>
                <span>Tempo máximo de entrega</span>
              </TimeMax>
            </Header>
            <Body>
              <Name>
                <input
                  required
                  placeholder="Ex. ( Jardim Brasil )"
                  onChange={(text) => setName(text.target.value)}
                />
              </Name>
              <Price>
                <input
                  required
                  type="number"
                  step="any"
                  placeholder="Ex. ( 10,50 )"
                  onChange={(text) => setPrice(text.target.value)}
                />
              </Price>
              <TimeMin>
                <TimeInput>
                  <input
                    required
                    type="number"
                    onChange={(text) => setMinWaitingTime(text.target.value)}
                  />
                </TimeInput>
                <SelectArea>
                  <Select
                    onChange={(e) => setMinWaitingType(e)}
                    options={options}
                    value={minWaitingType}
                  />
                </SelectArea>
              </TimeMin>

              <TimeMax>
                <TimeInput>
                  <input
                    required
                    type="number"
                    onChange={(text) => setMaxWaitingTime(text.target.value)}
                  />
                </TimeInput>
                <SelectArea>
                  <Select
                    onChange={(e) => setMaxWaitingType(e)}
                    options={options}
                    value={maxWaitingType}
                  />
                </SelectArea>
              </TimeMax>
            </Body>
            <ButtonArea>
              <ButtonCancel onClick={() => handleCloseModal()}>
                <span>Cancelar</span>
              </ButtonCancel>
              <ButtonCreate>
                <span>Criar área</span>
              </ButtonCreate>
            </ButtonArea>
          </Form>
        </Card>
      </Container>
    )
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...DeliveryActions }, dispatch);

const mapStateToProps = (state) => ({
  modalArea: state.delivery.modalArea,
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalArea);
