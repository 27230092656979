import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  FcInfo,
  FcClock,
  FcSettings,
  FcAddImage,
  FcCurrencyExchange,
} from 'react-icons/fc';
import {
  AiOutlineInfoCircle,
  AiOutlineClockCircle,
  AiFillSetting,
} from 'react-icons/ai';
import { BsCardImage, BsBell } from 'react-icons/bs';
import { HiCurrencyDollar } from 'react-icons/hi';
import { ReactComponent as Bell } from '../../assets/icons/bell.svg';

import * as StoreActions from '../../store/modules/store/actions';
import * as AuthActions from '../../store/modules/auth/actions';
import * as AlertsAction from '../../store/modules/alerts/actions';
import * as ProductActions from '../../store/modules/products/actions';

import StoreSettings from './components/StoreInfo';
import StoreWorkPeriod from './components/WorkPeriod';
import StoreConfig from './components/StoreConfig';
import StoreLayout from './components/StoreLayout';
import StoreDeliveryPaymentMethods from './components/StoreDeliveryPaymentMethods';
import Alerts from './components/Alerts';
import ModalAlerts from './components/Alerts/Components/ModalAlerts';
import Loading from './components/Loading';

import { Title } from '../components/Title';
import {
  Container,
  Body,
  SettingsArea,
  Store,
  Tabs,
  Tab,
  IconMobile,
  Card,
} from './styles';

function Settings({ getTablePrices, loadingStore, handleModalAlerts }) {
  const [storeSettings, setStoreSettings] = useState(true);
  const [storeWorkPeriod, setStoreWorkPeriod] = useState(false);
  const [storeConfig, setStoreConfig] = useState(false);
  const [storeLayout, setStoreLayout] = useState(false);
  const [
    storeDeliveryPaymentMethods,
    setStoreDeliveryPaymentMethods,
  ] = useState(false);
  const [alerts, setAlerts] = useState(false);

  useEffect(() => {
    handleModalAlerts(false);

    getTablePrices();
  }, [getTablePrices, handleModalAlerts]);

  function handleStoreSettings() {
    setStoreSettings(true);
    setStoreWorkPeriod(false);
    setStoreConfig(false);
    setStoreLayout(false);
    setStoreDeliveryPaymentMethods(false);
    setAlerts(false);
  }

  function handleStoreWorkPeriod() {
    setStoreSettings(false);
    setStoreWorkPeriod(true);
    setStoreConfig(false);
    setStoreLayout(false);
    setStoreDeliveryPaymentMethods(false);
    setAlerts(false);
  }

  function handleStoreConfig() {
    setStoreSettings(false);
    setStoreWorkPeriod(false);
    setStoreConfig(true);
    setStoreLayout(false);
    setStoreDeliveryPaymentMethods(false);
    setAlerts(false);
  }

  function handleStoreLayout() {
    setStoreSettings(false);
    setStoreWorkPeriod(false);
    setStoreConfig(false);
    setStoreLayout(true);
    setStoreDeliveryPaymentMethods(false);
    setAlerts(false);
  }

  function handleStoreDeliveryPayment() {
    setStoreSettings(false);
    setStoreWorkPeriod(false);
    setStoreConfig(false);
    setStoreLayout(false);
    setStoreDeliveryPaymentMethods(true);
    setAlerts(false);
  }
  function handleAlerts() {
    setStoreSettings(false);
    setStoreWorkPeriod(false);
    setStoreConfig(false);
    setStoreLayout(false);
    setStoreDeliveryPaymentMethods(false);
    setAlerts(true);
  }

  return (
    <Container>
      <ModalAlerts />
      {loadingStore && <Loading />}
      <Body>
        <SettingsArea>
          <Title text="Configurações da loja" />
          <Store>
            <Tabs>
              <Tab focus={storeSettings} onClick={() => handleStoreSettings()}>
                <span> Informações da loja</span>
                <IconMobile>
                  {storeSettings ? <FcInfo /> : <AiOutlineInfoCircle />}
                </IconMobile>
              </Tab>
              <Tab
                focus={storeWorkPeriod}
                onClick={() => handleStoreWorkPeriod()}
              >
                <span>Horário de funcionamento</span>
                <IconMobile>
                  {storeWorkPeriod ? <FcClock /> : <AiOutlineClockCircle />}
                </IconMobile>
              </Tab>
              <Tab focus={storeConfig} onClick={() => handleStoreConfig()}>
                <span>Configurações da loja</span>
                <IconMobile>
                  {storeConfig ? <FcSettings /> : <AiFillSetting />}
                </IconMobile>
              </Tab>
              <Tab focus={storeLayout} onClick={() => handleStoreLayout()}>
                <span>Layout da loja</span>
                <IconMobile>
                  {storeLayout ? <FcAddImage /> : <BsCardImage />}
                </IconMobile>
              </Tab>
              <Tab
                focus={storeDeliveryPaymentMethods}
                onClick={() => handleStoreDeliveryPayment()}
              >
                <span>Formas de pagamento na entrega</span>
                <IconMobile>
                  {storeDeliveryPaymentMethods ? (
                    <FcCurrencyExchange />
                  ) : (
                    <HiCurrencyDollar />
                  )}
                </IconMobile>
              </Tab>
              <Tab focus={alerts} onClick={() => handleAlerts()}>
                <span>Avisos</span>
                <IconMobile>{alerts ? <Bell /> : <BsBell />}</IconMobile>
              </Tab>
            </Tabs>
            <Card focus={storeSettings}>
              {storeSettings && <StoreSettings />}
              {storeWorkPeriod && <StoreWorkPeriod />}
              {storeConfig && <StoreConfig />}
              {storeLayout && <StoreLayout />}
              {storeDeliveryPaymentMethods && <StoreDeliveryPaymentMethods />}
              {alerts && <Alerts />}
            </Card>
          </Store>
        </SettingsArea>
      </Body>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...StoreActions, ...AuthActions, ...AlertsAction, ...ProductActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  storeSettings: state.store.storeInfo && state.store.storeInfo.settings,
  loadingStore: state.store.loadingStore,
});
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
