import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  *{
    margin:0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    font-family: 'Roboto', sans-serif;
  }

  html {
    @media (max-width: 1082px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    overflow-x: hidden;
    background: #F2F2F2;
    /* background: #fafbfe; */
    -webkit-font-smoothing: antialiased !important;

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.5);
    }
  }

  button {
    cursor:pointer;
  }

  .wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .content-page {
    margin-left: ${(props) => props.theme.dimensions.drawer.width};
    overflow: hidden;
    padding: ${(props) => props.theme.dimensions.header.height} 12px 12px 12px;
    min-height: 100vh;
  }

  .container-fluid {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 992px) {
    .content-page {
      margin-left: 0 !important;
    }

    .container-fluid {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;
