import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import * as StoreActions from '../../../../store/modules/store/actions';

import Label from '../../../components/Label';
import Input from '../../../components/Input';
import {
  Container,
  Day,
  Span,
  Closed,
  Space,
  BoxArea,
  Box,
  ButtonAddArea,
  ButtonAdd,
  Select,
  Weekdays,
  ButtonArea,
  Button,
} from './styles';

function WorkPeriod({ workPeriod, storeWorkPeriod, updatePeriodInfo }) {
  // Complete day, first period
  const [sundayFirst, setSundayFirst] = useState(null);
  const [mondayFirst, setMondayFirst] = useState(null);
  const [tuesdayFirst, setTuesdayFirst] = useState(null);
  const [wednesdayFirst, setWednesdayFirst] = useState(null);
  const [thursdayFirst, setThursdayFirst] = useState(null);
  const [fridayFirst, setFridayFirst] = useState(null);
  const [saturdayFirst, setSaturdayFirst] = useState(null);
  const [sundayVisible, setSundayVisible] = useState(false);

  // Complete day, Second period
  const [sundaySecond, setSundaySecond] = useState(null);
  const [mondaySecond, setMondaySecond] = useState(null);
  const [tuesdaySecond, setTuesdaySecond] = useState(null);
  const [wednesdaySecond, setWednesdaySecond] = useState(null);
  const [thursdaySecond, setThursdaySecond] = useState(null);
  const [fridaySecond, setFridaySecond] = useState(null);
  const [saturdaySecond, setSaturdaySecond] = useState(null);

  // Week days status first period
  const [mondayVisible, setMondayVisible] = useState(false);
  const [tuesdayVisible, setTuesdayVisible] = useState(false);
  const [wednesdayVisible, setWednesdayVisible] = useState(false);
  const [thursdayVisible, setThursdayVisible] = useState(false);
  const [fridayVisible, setFridayVisible] = useState(false);
  const [saturdayVisible, setSaturdayVisible] = useState(false);

  const [sundaySecondVisible, setSundaySecondVisible] = useState(false);
  const [mondaySecondVisible, setMondaySecondVisible] = useState(false);
  const [tuesdaySecondVisible, setTuesdaySecondVisible] = useState(false);
  const [wednesdaySecondVisible, setWednesdaySecondVisible] = useState(false);
  const [thursdaySecondVisible, setThursdaySecondVisible] = useState(false);
  const [fridaySecondVisible, setFridaySecondVisible] = useState(false);
  const [saturdaySecondVisible, setSaturdaySecondVisible] = useState(false);

  // Week days index on array first period
  const [sundayIndex, setSundayIndex] = useState(null);
  const [mondayIndex, setMondayIndex] = useState(null);
  const [tuesdayIndex, setTuesdayIndex] = useState(null);
  const [wednesdayIndex, setWednesdayIndex] = useState(null);
  const [thursdayIndex, setThursdayIndex] = useState(null);
  const [fridayIndex, setFridayIndex] = useState(null);
  const [saturdayIndex, setSaturdayIndex] = useState(null);

  // week day ubdex on array second period
  const [sundaySecondIndex, setSundaySecondIndex] = useState(null);
  const [mondaySecondIndex, setMondaySecondIndex] = useState(null);
  const [tuesdaySecondIndex, setTuesdaySecondIndex] = useState(null);
  const [wednesdaySecondIndex, setWednesdaySecondIndex] = useState(null);
  const [thursdaySecondIndex, setThursdaySecondIndex] = useState(null);
  const [fridaySecondIndex, setFridaySecondIndex] = useState(null);
  const [saturdaySecondIndex, setSaturdaySecondIndex] = useState(null);

  useEffect(() => {
    workPeriod.map((period) => {
      if (period?.week_day === 1 && period.period === 1) {
        if (period.start_hour && period.end_hour) setSundayVisible(true);
        return setSundayFirst(period);
      }
      if (period?.week_day === 2 && period.period === 1) {
        if (period.start_hour && period.end_hour) setMondayVisible(true);
        return setMondayFirst(period);
      }
      if (period?.week_day === 3 && period.period === 1) {
        if (period.start_hour && period.end_hour) setTuesdayVisible(true);
        return setTuesdayFirst(period);
      }
      if (period?.week_day === 4 && period.period === 1) {
        if (period.start_hour && period.end_hour) setWednesdayVisible(true);
        return setWednesdayFirst(period);
      }
      if (period?.week_day === 5 && period.period === 1) {
        if (period.start_hour && period.end_hour) setThursdayVisible(true);
        return setThursdayFirst(period);
      }
      if (period?.week_day === 6 && period.period === 1) {
        if (period.start_hour && period.end_hour) setFridayVisible(true);
        return setFridayFirst(period);
      }
      if (period?.week_day === 7 && period.period === 1) {
        if (period.start_hour && period.end_hour) setSaturdayVisible(true);
        return setSaturdayFirst(period);
      }

      // set second period
      if (period?.week_day === 1 && period.period === 2) {
        if (period.start_hour && period.end_hour) setSundaySecondVisible(true);
        return setSundaySecond(period);
      }
      if (period?.week_day === 2 && period.period === 2) {
        if (period.start_hour && period.end_hour) setMondaySecondVisible(true);
        return setMondaySecond(period);
      }
      if (period?.week_day === 3 && period.period === 2) {
        if (period.start_hour && period.end_hour) setTuesdaySecondVisible(true);
        return setTuesdaySecond(period);
      }
      if (period?.week_day === 4 && period.period === 2) {
        if (period.start_hour && period.end_hour)
          setWednesdaySecondVisible(true);
        return setWednesdaySecond(period);
      }
      if (period?.week_day === 5 && period.period === 2) {
        if (period.start_hour && period.end_hour)
          setThursdaySecondVisible(true);
        return setThursdaySecond(period);
      }
      if (period?.week_day === 6 && period.period === 2) {
        if (period.start_hour && period.end_hour) setFridaySecondVisible(true);
        return setFridaySecond(period);
      }
      if (period?.week_day === 7 && period.period === 2) {
        if (period.start_hour && period.end_hour)
          setSaturdaySecondVisible(true);
        return setSaturdaySecond(period);
      }
      return false;
    });
  }, [workPeriod]);

  useEffect(() => {
    if (sundayFirst) {
      setSundayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === sundayFirst.id)
      );
    }
    if (sundaySecond) {
      setSundaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === sundaySecond.id)
      );
    }
  }, [sundayFirst, sundaySecond, storeWorkPeriod]);

  useEffect(() => {
    if (mondayFirst) {
      setMondayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === mondayFirst.id)
      );
    }
    if (mondaySecond) {
      setMondaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === mondaySecond.id)
      );
    }
  }, [mondayFirst, mondaySecond, storeWorkPeriod]);

  useEffect(() => {
    if (tuesdayFirst) {
      setTuesdayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === tuesdayFirst.id)
      );
    }
    if (tuesdaySecond) {
      setTuesdaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === tuesdaySecond.id)
      );
    }
  }, [tuesdayFirst, tuesdaySecond, storeWorkPeriod]);

  useEffect(() => {
    if (wednesdayFirst) {
      setWednesdayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === wednesdayFirst.id)
      );
    }
    if (wednesdaySecond) {
      setWednesdaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === wednesdaySecond.id)
      );
    }
  }, [wednesdayFirst, wednesdaySecond, storeWorkPeriod]);

  useEffect(() => {
    if (thursdayFirst) {
      setThursdayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === thursdayFirst.id)
      );
    }
    if (thursdaySecond) {
      setThursdaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === thursdaySecond.id)
      );
    }
  }, [thursdayFirst, thursdaySecond, storeWorkPeriod]);

  useEffect(() => {
    if (fridayFirst) {
      setFridayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === fridayFirst.id)
      );
    }
    if (fridaySecond) {
      setFridaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === fridaySecond.id)
      );
    }
  }, [fridayFirst, fridaySecond, storeWorkPeriod]);

  useEffect(() => {
    if (saturdayFirst) {
      setSaturdayIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === saturdayFirst.id)
      );
    }
    if (saturdaySecond) {
      setSaturdaySecondIndex(
        storeWorkPeriod.findIndex((swp) => swp.id === saturdaySecond.id)
      );
    }
  }, [saturdayFirst, saturdaySecond, storeWorkPeriod]);

  async function handleSave() {
    if (
      sundayFirst === (storeWorkPeriod[sundayIndex] || null) &&
      mondayFirst === (storeWorkPeriod[mondayIndex] || null) &&
      tuesdayFirst === (storeWorkPeriod[tuesdayIndex] || null) &&
      wednesdayFirst === (storeWorkPeriod[wednesdayIndex] || null) &&
      thursdayFirst === (storeWorkPeriod[thursdayIndex] || null) &&
      fridayFirst === (storeWorkPeriod[fridayIndex] || null) &&
      saturdayFirst === (storeWorkPeriod[saturdayIndex] || null) &&
      sundaySecond === (storeWorkPeriod[sundaySecondIndex] || null) &&
      mondaySecond === (storeWorkPeriod[mondaySecondIndex] || null) &&
      tuesdaySecond === (storeWorkPeriod[tuesdaySecondIndex] || null) &&
      wednesdaySecond === (storeWorkPeriod[wednesdaySecondIndex] || null) &&
      thursdaySecond === (storeWorkPeriod[thursdaySecondIndex] || null) &&
      fridaySecond === (storeWorkPeriod[fridaySecondIndex] || null) &&
      saturdaySecond === (storeWorkPeriod[saturdaySecondIndex] || null)
    ) {
      return toast.info('Sem alterações');
    }

    if (
      (sundayFirst && sundayFirst.start_hour && !sundayFirst.end_hour) ||
      (sundayFirst && !sundayFirst.start_hour && sundayFirst.end_hour) ||
      (sundaySecond && sundaySecond.start_hour && !sundaySecond.end_hour) ||
      (sundaySecond && !sundaySecond.start_hour && sundaySecond.end_hour) ||
      (mondayFirst && mondayFirst.start_hour && !mondayFirst.end_hour) ||
      (mondayFirst && !mondayFirst.start_hour && mondayFirst.end_hour) ||
      (mondaySecond && mondaySecond.start_hour && !mondaySecond.end_hour) ||
      (mondaySecond && !mondaySecond.start_hour && mondaySecond.end_hour) ||
      (tuesdayFirst && tuesdayFirst.start_hour && !tuesdayFirst.end_hour) ||
      (tuesdayFirst && !tuesdayFirst.start_hour && tuesdayFirst.end_hour) ||
      (tuesdaySecond && tuesdaySecond.start_hour && !tuesdaySecond.end_hour) ||
      (tuesdaySecond && !tuesdaySecond.start_hour && tuesdaySecond.end_hour) ||
      (wednesdayFirst &&
        wednesdayFirst.start_hour &&
        !wednesdayFirst.end_hour) ||
      (wednesdayFirst &&
        !wednesdayFirst.start_hour &&
        wednesdayFirst.end_hour) ||
      (wednesdaySecond &&
        wednesdaySecond.start_hour &&
        !wednesdaySecond.end_hour) ||
      (wednesdaySecond &&
        !wednesdaySecond.start_hour &&
        wednesdaySecond.end_hour) ||
      (thursdayFirst && thursdayFirst.start_hour && !thursdayFirst.end_hour) ||
      (thursdayFirst && !thursdayFirst.start_hour && thursdayFirst.end_hour) ||
      (thursdaySecond &&
        thursdaySecond.start_hour &&
        !thursdaySecond.end_hour) ||
      (thursdaySecond &&
        !thursdaySecond.start_hour &&
        thursdaySecond.end_hour) ||
      (fridayFirst && fridayFirst.start_hour && !fridayFirst.end_hour) ||
      (fridayFirst && !fridayFirst.start_hour && fridayFirst.end_hour) ||
      (fridaySecond && fridaySecond.start_hour && !fridaySecond.end_hour) ||
      (fridaySecond && !fridaySecond.start_hour && fridaySecond.end_hour) ||
      (saturdayFirst && saturdayFirst.start_hour && !saturdayFirst.end_hour) ||
      (saturdayFirst && !saturdayFirst.start_hour && saturdayFirst.end_hour) ||
      (saturdaySecond &&
        saturdaySecond.start_hour &&
        !saturdaySecond.end_hour) ||
      (saturdaySecond && !saturdaySecond.start_hour && saturdaySecond.end_hour)
    ) {
      return toast.error(
        'É necessário preencher o horário de entrada e saída.'
      );
    }
    return swal({
      title: 'Atenção?',
      text: 'Deseja realmente salvar as alterações?',
      icon: 'warning',
      buttons: ['Manter', 'Salvar'],
    }).then((save) => {
      if (save) {
        const storePeriodInfo = [];
        if (sundayFirst && sundayFirst !== storeWorkPeriod[sundayIndex])
          storePeriodInfo.push({ ...sundayFirst, weekDay: 1, period: 1 });
        if (mondayFirst && mondayFirst !== storeWorkPeriod[mondayIndex])
          storePeriodInfo.push({ ...mondayFirst, weekDay: 2, period: 1 });
        if (tuesdayFirst && tuesdayFirst !== storeWorkPeriod[tuesdayIndex])
          storePeriodInfo.push({ ...tuesdayFirst, weekDay: 3, period: 1 });
        if (
          wednesdayFirst &&
          wednesdayFirst !== storeWorkPeriod[wednesdayIndex]
        )
          storePeriodInfo.push({ ...wednesdayFirst, weekDay: 4, period: 1 });
        if (thursdayFirst && thursdayFirst !== storeWorkPeriod[thursdayIndex])
          storePeriodInfo.push({ ...thursdayFirst, weekDay: 5, period: 1 });
        if (fridayFirst && fridayFirst !== storeWorkPeriod[fridayIndex])
          storePeriodInfo.push({ ...fridayFirst, weekDay: 6, period: 1 });
        if (saturdayFirst && saturdayFirst !== storeWorkPeriod[saturdayIndex])
          storePeriodInfo.push({ ...saturdayFirst, weekDay: 7, period: 1 });
        if (sundaySecond && sundaySecond !== storeWorkPeriod[sundaySecondIndex])
          storePeriodInfo.push({ ...sundaySecond, weekDay: 1, period: 2 });
        if (mondaySecond && mondaySecond !== storeWorkPeriod[mondaySecondIndex])
          storePeriodInfo.push({ ...mondaySecond, weekDay: 2, period: 2 });
        if (
          tuesdaySecond &&
          tuesdaySecond !== storeWorkPeriod[tuesdaySecondIndex]
        )
          storePeriodInfo.push({ ...tuesdaySecond, weekDay: 3, period: 2 });
        if (
          wednesdaySecond &&
          wednesdaySecond !== storeWorkPeriod[wednesdaySecondIndex]
        )
          storePeriodInfo.push({ ...wednesdaySecond, weekDay: 4, period: 2 });
        if (
          thursdaySecond &&
          thursdaySecond !== storeWorkPeriod[thursdaySecondIndex]
        )
          storePeriodInfo.push({ ...thursdaySecond, weekDay: 5, period: 2 });
        if (fridaySecond && fridaySecond !== storeWorkPeriod[fridaySecondIndex])
          storePeriodInfo.push({ ...fridaySecond, weekDay: 6, period: 2 });
        if (
          saturdaySecond &&
          saturdaySecond !== storeWorkPeriod[saturdaySecondIndex]
        )
          storePeriodInfo.push({ ...saturdaySecond, weekDay: 7, period: 2 });

        updatePeriodInfo(storePeriodInfo);
      }
    });
  }

  function handleSundayVisible(status) {
    setSundayFirst({ ...sundayFirst, start_hour: '', end_hour: '' });
    setSundayVisible(status);
    setSundaySecond({ ...sundaySecond, start_hour: '', end_hour: '' });
    setSundaySecondVisible(false);
  }
  function handleSundaySecondVisible(status) {
    setSundaySecond({ ...sundaySecond, start_hour: '', end_hour: '' });
    setSundaySecondVisible(status);
  }

  function handleMondayVisible(status) {
    setMondayFirst({ ...mondayFirst, start_hour: '', end_hour: '' });
    setMondayVisible(status);
    setMondaySecond({ ...mondaySecond, start_hour: '', end_hour: '' });
    setMondaySecondVisible(false);
  }

  function handleMondaySecondVisible(status) {
    setMondaySecond({ ...mondaySecond, start_hour: '', end_hour: '' });
    setMondaySecondVisible(status);
  }

  function handleTuesdayVisible(status) {
    setTuesdayFirst({ ...tuesdayFirst, start_hour: '', end_hour: '' });
    setTuesdayVisible(status);
    setTuesdaySecond({ ...tuesdaySecond, start_hour: '', end_hour: '' });
    setTuesdaySecondVisible(false);
  }

  function handleTuesdaySecondVisible(status) {
    setTuesdaySecond({ ...tuesdaySecond, start_hour: '', end_hour: '' });
    setTuesdaySecondVisible(status);
  }

  function handleWednesdayVisible(status) {
    setWednesdayFirst({ ...wednesdayFirst, start_hour: '', end_hour: '' });
    setWednesdayVisible(status);
    setWednesdaySecond({ ...wednesdaySecond, start_hour: '', end_hour: '' });
    setWednesdaySecondVisible(false);
  }

  function handleWednesdaySecondVisible(status) {
    setWednesdaySecond({ ...wednesdaySecond, start_hour: '', end_hour: '' });
    setWednesdaySecondVisible(status);
  }

  function handleThursdayVisible(status) {
    setThursdayFirst({ ...thursdayFirst, start_hour: '', end_hour: '' });
    setThursdayVisible(status);
    setThursdaySecond({ ...thursdaySecond, start_hour: '', end_hour: '' });
    setThursdaySecondVisible(false);
  }

  function handleThursdaySecondVisible(status) {
    setThursdaySecond({ ...thursdaySecond, start_hour: '', end_hour: '' });
    setThursdaySecondVisible(status);
  }

  function handleFridayVisible(status) {
    setFridayFirst({ ...fridayFirst, start_hour: '', end_hour: '' });
    setFridayVisible(status);
    setFridaySecond({ ...fridaySecond, start_hour: '', end_hour: '' });
    setFridaySecondVisible(false);
  }

  function handleFridaySecondVisible(status) {
    setFridaySecond({ ...fridaySecond, start_hour: '', end_hour: '' });
    setFridaySecondVisible(status);
  }

  function handleSaturdayVisible(status) {
    setSaturdayFirst({ ...saturdayFirst, start_hour: '', end_hour: '' });
    setSaturdayVisible(status);
    setSaturdaySecond({ ...saturdaySecond, start_hour: '', end_hour: '' });
    setSaturdaySecondVisible(false);
  }

  function handleSaturdaySecondVisible(status) {
    setSaturdaySecond({ ...saturdaySecond, start_hour: '', end_hour: '' });
    setSaturdaySecondVisible(status);
  }

  return (
    <Container>
      <Weekdays>
        <Day>
          <Span onClick={() => handleSundayVisible(!sundayVisible)}>
            <input
              checked={sundayVisible}
              type="checkbox"
              onClick={(e) => handleSundayVisible(e.target.checked)}
            />
            <span>Domingo</span>
          </Span>
          <Closed>{sundayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {sundayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setSundayFirst({ ...sundayFirst, start_hour: e })
                    }
                    value={sundayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    name="time"
                    type="time"
                    setState={(e) =>
                      setSundayFirst({ ...sundayFirst, end_hour: e })
                    }
                    value={sundayFirst?.end_hour}
                  />
                </Box>

                <ButtonAddArea>
                  {!sundaySecondVisible && (
                    <ButtonAdd
                      visible={sundaySecondVisible}
                      onClick={() =>
                        handleSundaySecondVisible(!sundaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {sundaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setSundaySecond({ ...sundaySecond, start_hour: e })
                      }
                      value={sundaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      name="time"
                      type="time"
                      setState={(e) =>
                        setSundaySecond({ ...sundaySecond, end_hour: e })
                      }
                      value={sundaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {sundaySecondVisible && (
                      <ButtonAdd
                        visible={sundaySecondVisible}
                        onClick={() =>
                          handleSundaySecondVisible(!sundaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>
      <Weekdays>
        <Day>
          <Span onClick={() => handleMondayVisible(!mondayVisible)}>
            <input
              type="checkbox"
              checked={mondayVisible}
              onChange={(e) => handleMondayVisible(e.target.checked)}
            />
            <span>Segunda-feira</span>
          </Span>
          <Closed>{mondayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {mondayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>
                  <Input
                    type="time"
                    setState={(e) =>
                      setMondayFirst({ ...mondayFirst, start_hour: e })
                    }
                    value={mondayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setMondayFirst({ ...mondayFirst, end_hour: e })
                    }
                    value={mondayFirst?.end_hour}
                  />
                </Box>
                <ButtonAddArea>
                  {!mondaySecondVisible && (
                    <ButtonAdd
                      visible={mondaySecondVisible}
                      onClick={() =>
                        handleMondaySecondVisible(!mondaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {mondaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>
                    <Input
                      type="time"
                      setState={(e) =>
                        setMondaySecond({ ...mondaySecond, start_hour: e })
                      }
                      value={mondaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setMondaySecond({ ...mondaySecond, end_hour: e })
                      }
                      value={mondaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {mondaySecondVisible && (
                      <ButtonAdd
                        visible={mondaySecondVisible}
                        onClick={() =>
                          handleMondaySecondVisible(!mondaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>
      <Weekdays>
        <Day>
          <Span onClick={() => handleTuesdayVisible(!tuesdayVisible)}>
            <input
              type="checkbox"
              checked={tuesdayVisible}
              onChange={(e) => handleTuesdayVisible(e.target.checked)}
            />
            <span>Terça-feira</span>
          </Span>
          <Closed>{tuesdayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {tuesdayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>
                  <Input
                    type="time"
                    setState={(e) =>
                      setTuesdayFirst({ ...tuesdayFirst, start_hour: e })
                    }
                    value={tuesdayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setTuesdayFirst({ ...tuesdayFirst, end_hour: e })
                    }
                    value={tuesdayFirst?.end_hour}
                  />
                </Box>
                <ButtonAddArea>
                  {!tuesdaySecondVisible && (
                    <ButtonAdd
                      visible={tuesdaySecondVisible}
                      onClick={() =>
                        handleTuesdaySecondVisible(!tuesdaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {tuesdaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>
                    <Input
                      type="time"
                      setState={(e) =>
                        setTuesdaySecond({ ...tuesdaySecond, start_hour: e })
                      }
                      value={tuesdaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setTuesdaySecond({ ...tuesdaySecond, end_hour: e })
                      }
                      value={tuesdaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {tuesdaySecondVisible && (
                      <ButtonAdd
                        visible={tuesdaySecondVisible}
                        onClick={() =>
                          handleTuesdaySecondVisible(!tuesdaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>
      <Weekdays>
        <Day>
          <Span onClick={() => handleWednesdayVisible(!wednesdayVisible)}>
            <input
              type="checkbox"
              checked={wednesdayVisible}
              onChange={(e) => handleWednesdayVisible(e.target.checked)}
            />
            <span>Quarta-feira</span>
          </Span>
          <Closed>{wednesdayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {wednesdayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>
                  <Input
                    type="time"
                    setState={(e) =>
                      setWednesdayFirst({ ...wednesdayFirst, start_hour: e })
                    }
                    value={wednesdayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setWednesdayFirst({ ...wednesdayFirst, end_hour: e })
                    }
                    value={wednesdayFirst?.end_hour}
                  />
                </Box>{' '}
                <ButtonAddArea>
                  {!wednesdaySecondVisible && (
                    <ButtonAdd
                      visible={wednesdaySecondVisible}
                      onClick={() =>
                        handleWednesdaySecondVisible(!wednesdaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {wednesdaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>
                    <Input
                      type="time"
                      setState={(e) =>
                        setWednesdaySecond({
                          ...wednesdaySecond,
                          start_hour: e,
                        })
                      }
                      value={wednesdaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setWednesdaySecond({ ...wednesdaySecond, end_hour: e })
                      }
                      value={wednesdaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {wednesdaySecondVisible && (
                      <ButtonAdd
                        visible={wednesdaySecondVisible}
                        onClick={() =>
                          handleWednesdaySecondVisible(!wednesdaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>
      <Weekdays>
        <Day>
          <Span onClick={() => handleThursdayVisible(!thursdayVisible)}>
            <input
              type="checkbox"
              checked={thursdayVisible}
              onChange={(e) => handleThursdayVisible(e.target.checked)}
            />
            <span>Quinta-feira</span>
          </Span>
          <Closed>{thursdayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {thursdayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>
                  <Input
                    type="time"
                    setState={(e) =>
                      setThursdayFirst({ ...thursdayFirst, start_hour: e })
                    }
                    value={thursdayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setThursdayFirst({ ...thursdayFirst, end_hour: e })
                    }
                    value={thursdayFirst?.end_hour}
                  />
                </Box>
                <ButtonAddArea>
                  {!thursdaySecondVisible && (
                    <ButtonAdd
                      visible={thursdaySecondVisible}
                      onClick={() =>
                        handleThursdaySecondVisible(!thursdaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {thursdaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>
                    <Input
                      type="time"
                      setState={(e) =>
                        setThursdaySecond({ ...thursdaySecond, start_hour: e })
                      }
                      value={thursdaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setThursdaySecond({ ...thursdaySecond, end_hour: e })
                      }
                      value={thursdaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {thursdaySecondVisible && (
                      <ButtonAdd
                        visible={thursdaySecondVisible}
                        onClick={() =>
                          handleThursdaySecondVisible(!thursdaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>
      <Weekdays>
        <Day>
          <Span onClick={() => handleFridayVisible(!fridayVisible)}>
            <input
              type="checkbox"
              checked={fridayVisible}
              onChange={(e) => handleFridayVisible(e.target.checked)}
            />
            <span>Sexta-feira</span>
          </Span>
          <Closed>{fridayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {fridayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>
                  <Input
                    type="time"
                    setState={(e) =>
                      setFridayFirst({ ...fridayFirst, start_hour: e })
                    }
                    value={fridayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setFridayFirst({ ...fridayFirst, end_hour: e })
                    }
                    value={fridayFirst?.end_hour}
                  />
                </Box>
                <ButtonAddArea>
                  {!fridaySecondVisible && (
                    <ButtonAdd
                      visible={fridaySecondVisible}
                      onClick={() =>
                        handleFridaySecondVisible(!fridaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {fridaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>
                    <Input
                      type="time"
                      setState={(e) =>
                        setFridaySecond({ ...fridaySecond, start_hour: e })
                      }
                      value={fridaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setFridaySecond({ ...fridaySecond, end_hour: e })
                      }
                      value={fridaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {fridaySecondVisible && (
                      <ButtonAdd
                        visible={fridaySecondVisible}
                        onClick={() =>
                          handleFridaySecondVisible(!fridaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>
      <Weekdays>
        <Day>
          <Span onClick={() => handleSaturdayVisible(!saturdayVisible)}>
            <input
              type="checkbox"
              checked={saturdayVisible}
              onChange={(e) => handleSaturdayVisible(e.target.checked)}
            />
            <span>Sábado</span>
          </Span>
          <Closed>{saturdayVisible ? <span /> : <span>fechado</span>}</Closed>
          <Space />
        </Day>
        {saturdayVisible && (
          <>
            <Select>
              <BoxArea>
                <Box>
                  <Label>Abertura da loja:</Label>
                  <Input
                    type="time"
                    setState={(e) =>
                      setSaturdayFirst({ ...saturdayFirst, start_hour: e })
                    }
                    value={saturdayFirst?.start_hour}
                  />
                </Box>
                <Box>
                  <Label>Fechamento da loja: </Label>

                  <Input
                    type="time"
                    setState={(e) =>
                      setSaturdayFirst({ ...saturdayFirst, end_hour: e })
                    }
                    value={saturdayFirst?.end_hour}
                  />
                </Box>
                <ButtonAddArea>
                  {!saturdaySecondVisible && (
                    <ButtonAdd
                      visible={saturdaySecondVisible}
                      onClick={() =>
                        handleSaturdaySecondVisible(!saturdaySecondVisible)
                      }
                    >
                      <AiOutlinePlus />
                    </ButtonAdd>
                  )}
                </ButtonAddArea>
              </BoxArea>
              {saturdaySecondVisible && (
                <BoxArea>
                  <Box>
                    <Label>Abertura da loja:</Label>
                    <Input
                      type="time"
                      setState={(e) =>
                        setSaturdaySecond({ ...saturdaySecond, start_hour: e })
                      }
                      value={saturdaySecond?.start_hour}
                    />
                  </Box>
                  <Box>
                    <Label>Fechamento da loja: </Label>

                    <Input
                      type="time"
                      setState={(e) =>
                        setSaturdaySecond({ ...saturdaySecond, end_hour: e })
                      }
                      value={saturdaySecond?.end_hour}
                    />
                  </Box>
                  <ButtonAddArea>
                    {saturdaySecondVisible && (
                      <ButtonAdd
                        visible={saturdaySecondVisible}
                        onClick={() =>
                          handleSaturdaySecondVisible(!saturdaySecondVisible)
                        }
                      >
                        <AiOutlineDelete />
                      </ButtonAdd>
                    )}
                  </ButtonAddArea>
                </BoxArea>
              )}
            </Select>
          </>
        )}
      </Weekdays>

      <ButtonArea>
        <Button onClick={() => handleSave()}>
          <span>Salvar</span>
        </Button>
      </ButtonArea>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(StoreActions, dispatch);

const mapStateToProps = (state) => ({
  workPeriod: state?.store?.storeInfo?.work_period,
  storeWorkPeriod: state?.store?.storeInfo?.work_period,
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkPeriod);
