import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProductsList from './components/ProductsList';
// import Variations from './components/Variations';
// import Options from './components/Options';

import * as ProductsActions from '../../store/modules/products/actions';
import * as AuthActions from '../../store/modules/auth/actions';

import { Title } from '../components/Title';
import ModalOption from './components/ModalOption';

import {
  Container,
  Body,
  ProductsArea,
  // Tabs,
  // Tab,
  Card,
} from './styles';

function Products({ modalOptionVisible }) {
  // const [list, setList] = useState(true);
  // const [variations, setVariations] = useState(false);
  // const [options, setOptions] = useState(false);

  // function handleList() {
  //   setList(true);
  //   setVariations(false);
  //   setOptions(false);
  // }
  // function handleVariations() {
  //   setList(false);
  //   setVariations(true);
  //   setOptions(false);
  // }
  // function handleOptions() {
  //   setList(false);
  //   setVariations(false);
  //   setOptions(true);
  // }

  return (
    <Container>
      {modalOptionVisible && <ModalOption />}
      <Body>
        <ProductsArea>
          <Title text="Produtos" />
          {/* <Tabs>
            <Tab focus={list} onClick={() => handleList()}>
              Produtos
            </Tab>
            <Tab focus={variations} onClick={() => handleVariations()}>
              Variações
            </Tab>
            <Tab focus={options} onClick={() => handleOptions()}>
              Opções
            </Tab>
          </Tabs> */}

          <Card
          // current={list}
          >
            <ProductsList />
            {/* {list && <ProductsList />} */}
            {/* {variations && <Variations />} */}
            {/* {options && <Options />} */}
          </Card>
        </ProductsArea>
      </Body>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...ProductsActions, ...AuthActions }, dispatch);

const mapStateToProps = (state) => ({
  products: state.products.products,
  nextPage: state.products.nextPage,
  modalOptionVisible: state.option.modalOptionVisible,
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);
