export function getOrders(nextPage) {
  return {
    type: 'GET_ORDERS',
    payload: { nextPage },
  };
}

export function getOrderItem(orderId) {
  return {
    type: 'GET_ORDER_ITEM',
    payload: { orderId },
  };
}

export function setOrders(orders) {
  return {
    type: 'SET_ORDERS',
    payload: { orders },
  };
}
export function setNextPage(nextPage) {
  return {
    type: 'SET_NEXT_PAGE',
    payload: { nextPage },
  };
}
export function setOrderItem(orderItem) {
  return {
    type: 'SET_ORDER_ITEM',
    payload: { orderItem },
  };
}

export function updateStatusOrder(status, orderId) {
  return {
    type: 'UPDATE_STATUS_ORDER',
    status,
    orderId,
  };
}

export function handleLoading(state) {
  return {
    type: 'HANDLE_LOADING',
    payload: { state },
  };
}
export function handleLoadingProducts(state) {
  return {
    type: 'HANDLE_LOADING_PRODUCTS',
    payload: { state },
  };
}

export function handleDrawerVisible(state) {
  return {
    type: 'HANDLE_DRAWER_VISIBLE',
    payload: { state },
  };
}

export function handleDrawerComplete(state) {
  return {
    type: 'HANDLE_DRAWER_COMPLETE',
    payload: { state },
  };
}
export function handleOrderItemsVisible(state) {
  return {
    type: 'HANDLE_ORDER_ITEMS_VISIBLE',
    payload: { state },
  };
}

export function getContactRequests(nextPage) {
  return {
    type: 'GET_CONTACT_REQUESTS',
    payload: { nextPage },
  };
}

export function setContactRequests(contactRequests) {
  return {
    type: 'SET_CONTACT_REQUESTS',
    payload: { contactRequests },
  };
}

export function updateContactRequest(id, status) {
  return {
    type: 'UPDATE_CONTACT_REQUEST',
    payload: { id, status },
  };
}

export function updateContactRequests(contactRequest) {
  return {
    type: 'UPDATE_CONTACT_REQUESTS',
    payload: { contactRequest },
  };
}

export function setNextPageContactRequest(nextPage) {
  return {
    type: 'SET_NEXT_PAGE_CONTACT_REQUESTS',
    payload: { nextPage },
  };
}

export function getTrackingOrder(trackingCode) {
  return {
    type: 'GET_TRACKING_ORDER',
    payload: { trackingCode },
  };
}
export function setTrackingOrder(tracks) {
  return {
    type: 'SET_TRACKING_ORDER',
    payload: { tracks },
  };
}

export function handleLoadingTracks(state) {
  return {
    type: 'HANDLE_LOADING_TRACKS',
    state,
  };
}
