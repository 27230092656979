import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import Select from 'react-select';

import { AiOutlinePlus } from 'react-icons/ai';

import { maskCurrency, unmaksCurrency } from '../../../../utils/mask';

import * as ProductsActions from '../../../../store/modules/products/actions';

import CheckBox from '../../../components/CheckBox';
import Label from '../../../components/Label';
import Input from '../../../components/Input';

import {
  Container,
  ModalStyled,
  Title,
  Body,
  SelectArea,
  ButtonCreateTablePrice,
  RegularPrice,
  PromotionalPrice,
  Check,
  TablePrice,
  ButtonArea,
  ButtonEdit,
  ButtonCancel,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function ModalTablePrice({
  handleModalTablePrice,
  modalTablePrice,
  currentProduct,
  currentPrice,
  tablePrices,
  tablePriceOptions,
  createTablePrice,
  createProductTablePrice,
  updateProductTablePrice,
  addProductTablePrice,
  editProductTablePrices,
}) {
  const [price, setPrice] = useState('');
  const [promotionalPrice, setPromotionalPrice] = useState('');
  const [tablePrice, setTablePrice] = useState('');
  const [tablePriceId, setTablePriceId] = useState('');
  const [promotionalPriceVisible, setPromotionalPriceVisible] = useState(false);

  function handleCheck(e) {
    if (!e.target.checked) {
      setPromotionalPrice('');
    }
    setPromotionalPriceVisible(e.target.checked);
  }

  useEffect(() => {
    if (currentPrice) {
      setPrice(maskCurrency(currentPrice.regular_price, false));
      setPromotionalPrice(maskCurrency(currentPrice.promotional_price, false));

      const tablePriceFilter = tablePrices?.filter((tp) => {
        return tp.id === currentPrice.table_prices_id;
      });
      setTablePrice(
        tablePriceFilter?.length > 0 ? tablePriceFilter[0].name : ''
      );
      setTablePriceId(
        tablePriceFilter?.length > 0 ? tablePriceFilter[0].id : ''
      );
      setPromotionalPriceVisible(
        currentPrice ? !!currentPrice.promotional_price : false
      );
    } else {
      setPrice('');
      setPromotionalPrice('');
      setTablePrice('');
      setTablePriceId('');
      setPromotionalPriceVisible(false);
    }
  }, [currentPrice, tablePrices]);

  function handleSubmit(e) {
    e.preventDefault();

    if (currentProduct) {
      // creating new product price
      if (!currentPrice) {
        return createProductTablePrice(
          currentProduct.id,
          tablePriceId,
          unmaksCurrency(price),
          unmaksCurrency(promotionalPrice)
        );
      }

      // editing product price
      if (
        price === currentPrice.regular_price &&
        promotionalPrice === currentPrice.promotional_price
      ) {
        handleModalTablePrice(false);
        return toast.info('Preço sem alteração');
      }

      return updateProductTablePrice(
        tablePriceId,
        currentPrice.product_id,
        unmaksCurrency(price),
        unmaksCurrency(promotionalPrice) || 0
      );
    }

    if (!currentProduct) {
      // creating new product price
      if (!currentPrice) {
        addProductTablePrice({
          tempId: new Date().getTime() + Math.random(), // temp id
          table_prices_id: tablePriceId,
          regular_price: unmaksCurrency(price),
          promotional_price: unmaksCurrency(promotionalPrice) || 0,
        });
        return handleModalTablePrice(false);
      }

      // editing address
      editProductTablePrices({
        tempId: currentPrice.tempId,
        table_prices_id: tablePriceId,
        regular_price: unmaksCurrency(price),
        promotional_price: unmaksCurrency(promotionalPrice) || 0,
      });
      return handleModalTablePrice(false);
    }

    return true;
  }

  function handleChange(e) {
    setTablePriceId(e.value);
  }

  function handleNewTablePrice(name) {
    createTablePrice(name);
  }

  function handleCreateTablePrice() {
    swal({
      text: 'Digite o nome da tabela de preços. Ex.(Atacado)',
      content: 'input',
      button: {
        text: 'Criar',
      },
    }).then((name) => {
      if (!name) return null;
      return handleNewTablePrice(name);
    });
  }

  function handlePrice(text) {
    setPrice(maskCurrency(unmaksCurrency(text), false));
  }

  function handlePromotionalPrice(text) {
    setPromotionalPrice(maskCurrency(unmaksCurrency(text), false));
  }

  return (
    <Container>
      <ModalStyled isOpen={modalTablePrice} style={customStyles}>
        <Title>
          {currentPrice ? <span>Alterar preço</span> : <span>Novo preço</span>}
        </Title>

        <Body onSubmit={(e) => handleSubmit(e)}>
          <TablePrice>
            <Label>Table de preço</Label>
            {currentPrice ? (
              <Input disabled value={tablePrice} />
            ) : (
              <SelectArea>
                <Select
                  className="select"
                  onChange={handleChange}
                  placeholder="Selecione..."
                  options={tablePriceOptions}
                />
                <ButtonCreateTablePrice
                  onClick={() => handleCreateTablePrice()}
                >
                  <AiOutlinePlus />
                </ButtonCreateTablePrice>
              </SelectArea>
            )}
          </TablePrice>

          <RegularPrice>
            <Label>Preço</Label>
            <Input required setState={handlePrice} value={price} />
          </RegularPrice>
          <Check>
            <Label className="habilit">Habilitar preço promocional</Label>
            <CheckBox
              status={promotionalPriceVisible}
              id="promotionalPrice"
              handleStatus={handleCheck}
            />
          </Check>
          {promotionalPriceVisible && (
            <PromotionalPrice>
              <Label>Preço promocional</Label>
              <Input
                setState={handlePromotionalPrice}
                value={promotionalPrice}
              />
            </PromotionalPrice>
          )}
          <ButtonArea>
            <ButtonCancel onClick={() => handleModalTablePrice(false)}>
              Calcelar
            </ButtonCancel>
            <ButtonEdit>Salvar</ButtonEdit>
          </ButtonArea>
        </Body>
      </ModalStyled>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...ProductsActions }, dispatch);

const mapStateToProps = (state) => ({
  priceDecimals: state.store.storeInfo
    ? state.store.storeInfo.settings.price_decimals
    : 2,
  modalTablePrice: state.products.modalTablePrice,
  currentPrice: state.products.currentPrice,
  tablePrices: state.products.tablePrices,
  currentProduct: state.products.currentProduct,
  tablePriceOptions: state.products.tablePriceOptions,
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalTablePrice);
