import React from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getStoreSlug } from '../utils/storeUtil';

import Drawer from '../pages/components/Drawer';
import Header from '../pages/components/Header';
// import Footer from '../pages/components/Footer';

function Main({ signed, children }) {
  return signed ? (
    <div className="wrapper">
      <Drawer className="sidebar" />
      <div className="content-page">
        <div className="content">
          <Header className="header" />
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={`/${getStoreSlug()}`} />
  );
}
const mapStateToProps = (state) => ({
  signed: state.auth.signed,
});
export default connect(mapStateToProps)(Main);
