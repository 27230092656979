import styled from 'styled-components';

export const Container = styled.div`
  flex: 5;
  width: 100%;
  padding-left: 20px;

  @media (max-width: 992px) {
    right: ${(props) => (props.orderItemsVisible ? '0' : `-${props.width}px`)};
    position: fixed;
    flex: 1;
    z-index: 2;
    width: 100%;
    height: 100% !important;
    padding: 0;
    background: #fff;
    transition: all 0.6s;
    margin-top: -4.75rem;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: ${(props) => props.theme.colors.grayDark};

  span {
    margin-left: 5px;
  }
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - 4.75rem - 70px - 12px
  ); // tela - titulo - header - padding bottom
  min-width: 55%;
  width: 100%;
  background: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  overflow-y: auto;

  @media (max-width: 1390px) {
    min-width: 45%;
  }

  @media (max-width: 992px) {
    width: 100%;
    padding: 10px;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.5);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Order = styled.div`
  padding: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.grayDark};
  border-bottom: 1px solid #eee;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const IdArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 3;

  @media (max-width: 1300px) {
    .space {
      display: none;
    }
  }

  @media (max-width: 576px) {
    margin-bottom: 10px;

    .space {
      display: none;
    }
  }
`;

export const Id = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 0.5rem;

  strong {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    font-size: 14px;

    strong {
      font-size: 18px;
    }
  }
`;
export const Date = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 0.5rem;

  strong {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    font-size: 14px;

    strong {
      font-size: 18px;
    }
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .select {
    @media (max-width: 576px) {
      width: 200px;
    }
  }
`;

export const TrackingCodeArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const TrackingCode = styled.div`
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
`;

export const TrackingCodeMobile = styled.div`
  display: none;
  padding: 10px 0;
  flex-direction: column;

  @media (max-width: 576px) {
    display: flex;
  }
`;

export const Customer = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  flex: 1;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Person = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0 20px 20px;
  flex: 1;
`;

export const Name = styled.div`
  align-items: center;
  color: #000;
  font-weight: bold;
`;
export const Document = styled.div`
  margin-top: 1px;
`;
export const Phone = styled.div`
  margin-top: 1px;
`;
export const Email = styled.div`
  margin-top: 1px;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;

  strong {
    color: #000;
  }
`;

export const DeliveryPaymentMethod = styled.div``;
export const PaymentMethod = styled.div`
  display: flex;
  padding: 0 20px;
`;

export const OnlinePaymentMethod = styled.div``;

export const Online = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${(props) => {
    switch (props.status) {
      case 'Aprovada': {
        return '#b2dbb2';
      }
      case 'Aguardando Pagamento': {
        return '#fcf1de';
      }
      case 'Em Processamento': {
        return '#fcf1de';
      }
      case 'Em Contestação': {
        return '#fcf1de';
      }
      case 'Em Monitoramento': {
        return '#fcf1de';
      }
      case 'Cancelada': {
        return '#edafa4';
      }
      case 'Reprovada	': {
        return '#edafa4';
      }
      default:
        return '#edafa4';
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case 'Aprovada': {
        return '#609b40';
      }
      case 'Aguardando Pagamento': {
        return '#cc7912';
      }
      case 'Em Processamento': {
        return '#cc7912';
      }
      case 'Em Contestação': {
        return '#cc7912';
      }
      case 'Em Monitoramento': {
        return '#cc7912';
      }
      case 'Cancelada': {
        return '#c43232';
      }
      case 'Reprovada	': {
        return '#c43232';
      }
      default:
        return '#c43232';
    }
  }};
`;

export const ObservationArea = styled.div`
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
`;
export const Observation = styled.div`
  padding: 0 20px;
`;

export const ProductHeader = styled.div`
  padding: 0px 20px 0 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const Products = styled.div`
  /* overflow-y: visible; */
  border-bottom: 1px solid #eee;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    /* overflow-y: visible; */
    height: 200px;
    margin-bottom: 20px;
  }
`;
export const Product = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const Item = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`;

export const Amount = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ProductName = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ProductDescription = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ProductUnitDescription = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Value = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const TotalMobile = styled.div`
  display: none;
  justify-content: flex-end;
  padding: 5px;
  background: #fff;
  color: #000;
  font-weight: bold;
  /* position: fixed; */
  width: 100%;
  /* bottom: 0; */

  span {
    margin-right: 10px;
  }

  @media (max-width: 992px) {
    display: flex;
  }
`;

export const Total = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0 0;
  color: #000;
  font-weight: bold;

  span {
    margin-right: 5px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
