import { combineReducers } from 'redux';

import user from './user/reducer';
import auth from './auth/reducer';
import orders from './orders/reducer';
import store from './store/reducer';
import delivery from './delivery/reducer';
import products from './products/reducer';
import customer from './customer/reducer';
import dashboard from './dashboard/reducer';
import alerts from './alerts/reducer';
import option from './option/reducer';

export default combineReducers({
  user,
  auth,
  orders,
  store,
  delivery,
  products,
  customer,
  dashboard,
  alerts,
  option,
});
