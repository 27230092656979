import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Map,
  TileLayer,
  Circle,
  Polygon,
  Tooltip,
  FeatureGroup,
  Marker,
} from 'react-leaflet';

import swal from 'sweetalert';

import { EditControl } from 'react-leaflet-draw';
import Control from 'react-leaflet-control';

import Select from 'react-select';

import { GiPositionMarker } from 'react-icons/gi';
import { FaRegSave } from 'react-icons/fa';

import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import { ControlCard, EditMarker } from '../MapArea/styles';
import {
  Container,
  Card,
  MapArea,
  Form,
  Header,
  Body,
  Name,
  Price,
  TimeMin,
  TimeMax,
  ButtonArea,
  ButtonCreate,
  ButtonCancel,
  TimeInput,
  SelectArea,
} from '../ModalArea/styles';

function ModalEditDelivery({
  updateDelivery,
  currentDeliveryArea,
  requestStoreLocation,
  store,
  handleEditDelivery,
}) {
  const [name, setName] = useState(currentDeliveryArea.name);
  const [price, setPrice] = useState(currentDeliveryArea.price);
  const [minWaitingTime, setMinWaitingTime] = useState(
    currentDeliveryArea.min_waiting_time_formated
  );
  const [maxWaitingTime, setMaxWaitingTime] = useState(
    currentDeliveryArea.max_waiting_time_formated
  );
  const [minWaitingType, setMinWaitingType] = useState(
    currentDeliveryArea.min_waiting_type
  );
  const [maxWaitingType, setMaxWaitingType] = useState(
    currentDeliveryArea.max_waiting_type
  );
  const [editMarker, setEditMarker] = useState(false);
  // const [currentLatitude, setCurrentLatitude] = useState();
  // const [currentLongitude, setCurrentLongitude] = useState();
  const inputRef = useRef(null);

  const { latitude, longitude, radius, positions } = currentDeliveryArea;

  const options = [
    { value: 'Minutos', label: 'Minutos', color: '#97bab3' },
    { value: 'Horas', label: 'Horas', color: '#6D9DB1' },
    { value: 'Dias', label: 'Dias', color: '#716893' },
  ];

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    updateDelivery(
      currentDeliveryArea.id,
      name,
      price,
      minWaitingTime,
      maxWaitingTime,
      minWaitingType,
      maxWaitingType
    );
  }

  function handleCloseModal() {
    swal({
      title: 'Atenção!',
      html: true,
      text: `Deseja realmente cancelar?`,
      icon: 'warning',
      buttons: ['Não', 'Sim'],
    }).then((response) => {
      if (response) {
        handleEditDelivery(false);
      }
    });
  }

  function handlePosition() {
    // const { lat } = e.target._latlng;
    // const { lng } = e.target._latlng;
    // setCurrentLatitude(lat);
    // setCurrentLongitude(lng);
  }

  function handleSaveMarker() {
    setEditMarker(false);
    requestStoreLocation(latitude, longitude);
  }

  function verifyDate(waitingType) {
    if (waitingType === 'Minutos') return options[0];
    if (waitingType === 'Horas') return options[1];
    if (waitingType === 'Dias') return options[2];
    return '';
  }

  return (
    <Container>
      <Card>
        <MapArea>
          <Map
            center={[
              currentDeliveryArea.geolocation[0].latitude,
              currentDeliveryArea.geolocation[0].longitude,
            ]}
            zoom={12}
            style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            <Control position="topright">
              <ControlCard>
                {store.latitude && store.longitude && (
                  <>
                    {!editMarker ? (
                      <EditMarker onClick={() => setEditMarker(true)}>
                        <GiPositionMarker size={20} />
                      </EditMarker>
                    ) : (
                      <EditMarker onClick={() => handleSaveMarker()}>
                        <FaRegSave size={20} />
                      </EditMarker>
                    )}
                  </>
                )}
              </ControlCard>
            </Control>
            {store.latitude && store.longitude && (
              <Marker
                ondrag={handlePosition}
                draggable={editMarker}
                position={[store.latitude, store.longitude]}
              >
                <Tooltip>Sua localização</Tooltip>
              </Marker>
            )}
            <FeatureGroup>
              <EditControl
                position="topleft"
                onEdited={(e) => console.log(e)}
                onDeleted={(e) => console.log(e)}
                draw={{
                  polyline: false,
                  circlemarker: false,
                  marker: false,
                  polygon: false,
                  rectangle: false,
                  circle: false,
                }}
                edit={{ remove: false, edit: false }}
              />
            </FeatureGroup>
            {currentDeliveryArea.geolocation.length === 1 &&
              currentDeliveryArea.geolocation.map((deliveryArea) => {
                return (
                  <Circle
                    center={[deliveryArea.latitude, deliveryArea.longitude]}
                    fillColor="blue"
                    radius={parseFloat(deliveryArea.radius)}
                  >
                    <Tooltip>{currentDeliveryArea.name}</Tooltip>
                  </Circle>
                );
              })}

            {!radius && positions.length >= 1 && (
              <Polygon positions={positions}>
                <Tooltip>{currentDeliveryArea.name}</Tooltip>
              </Polygon>
            )}
          </Map>
        </MapArea>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Header>
            <Name>
              <span>Descrição da área</span>
            </Name>
            <Price>
              <span>Taxa de entrega</span>
            </Price>
            <TimeMin>
              <span>Tempo mínimo de entrega</span>
            </TimeMin>
            <TimeMax>
              <span>Tempo máximo de entrega</span>
            </TimeMax>
          </Header>
          <Body>
            <Name>
              <input
                ref={inputRef}
                required
                value={name}
                placeholder="Ex. ( Jardim Brasil )"
                onChange={(text) => setName(text.target.value)}
              />
            </Name>
            <Price>
              <input
                required
                value={price}
                type="number"
                step="any"
                placeholder="Ex. ( 10,50 )"
                onChange={(text) => setPrice(text.target.value)}
              />
            </Price>
            <TimeMin>
              <TimeInput>
                <input
                  value={minWaitingTime}
                  required
                  type="number"
                  onChange={(text) => setMinWaitingTime(text.target.value)}
                />
              </TimeInput>
              <SelectArea>
                <Select
                  onChange={(e) => setMinWaitingType(e.value)}
                  value={verifyDate(minWaitingType)}
                  placeholder="Selecione..."
                  options={options}
                />
              </SelectArea>
            </TimeMin>

            <TimeMax>
              <TimeInput>
                <input
                  value={maxWaitingTime}
                  required
                  type="number"
                  onChange={(text) => setMaxWaitingTime(text.target.value)}
                />
              </TimeInput>
              <SelectArea>
                <Select
                  onChange={(e) => setMaxWaitingType(e.value)}
                  value={verifyDate(maxWaitingType)}
                  placeholder="Selecione..."
                  options={options}
                />
              </SelectArea>
            </TimeMax>
          </Body>
          <ButtonArea>
            <ButtonCancel onClick={() => handleCloseModal()}>
              <span>Cancelar</span>
            </ButtonCancel>
            <ButtonCreate>
              <span>Alterar área</span>
            </ButtonCreate>
          </ButtonArea>
        </Form>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(DeliveryActions, dispatch);

const mapStateToProps = (state) => ({
  store: state.store.storeInfo,
  currentDeliveryArea: state.delivery.currentDeliveryArea,
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditDelivery);
