import styled from 'styled-components';

import Modal from 'react-modal';

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  width: 40%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-left: 40px;

  &:hover {
    box-shadow: 10px 10px 10px #80828b30;
  }

  @media (max-width: 1700px) {
    width: 60%;
  }

  @media (max-width: 992px) {
    width: 95%;
    border-radius: 0;
    margin-left: 0;
  }

  @media (max-width: 576px) {
    padding: 10px 0;
  }
`;

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: auto;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const RegularPrice = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const PromotionalPrice = styled(RegularPrice)`
  input {
    margin-top: 10px;
    margin-right: 5px;
  }
`;
export const TablePrice = styled(RegularPrice)`
  .select {
    margin-bottom: 10px;
    width: 90%;
  }
`;

export const SelectArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonCreateTablePrice = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background: ${(props) => props.theme.colors.greenMedium};
  cursor: pointer;
  transition: color 0.5s, background 0.6s, box-shadow 0.6s;
  box-shadow: 0px 3px 4px #00000050;

  &:hover {
    box-shadow: 0px 6px 8px #00000050;
    color: #fff;
    background: ${(props) => props.theme.colors.greenDark};
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;

export const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
export const ButtonCancel = styled.button.attrs({ type: 'submit' })`
  border: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  height: 40px;
  width: 40%;
  background: #e57373;
  border-radius: 10px;
  transition: color 0.5s, background 0.6s, box-shadow 0.6s;
  box-shadow: 0px 3px 4px #00000050;

  &:hover {
    box-shadow: 0px 6px 8px #00000050;
    color: #fff;
    background: #ef5350;
  }
`;
export const ButtonEdit = styled(ButtonCancel)`
  background: ${(props) => props.theme.colors.greenMedium};

  &:hover {
    box-shadow: 0px 6px 8px #00000050;
    color: #fff;
    background: ${(props) => props.theme.colors.greenDark};
  }
`;
