import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IoIosArrowDropup } from 'react-icons/io';

import swal from 'sweetalert';
import { toast } from 'react-toastify';

import DefaultCard from '../../../../assets/icons/cards/defaultCard.png';
import Visa from '../../../../assets/icons/cards/Visa.png';
import Mastercard from '../../../../assets/icons/cards/Mastercard.png';
import Maestro from '../../../../assets/icons/cards/Maestro.png';
import Elo from '../../../../assets/icons/cards/Elo.png';
import Amex from '../../../../assets/icons/cards/Amex.png';
import DinersClub from '../../../../assets/icons/cards/DinersClub.png';
import Discover from '../../../../assets/icons/cards/Discover.png';
import Hipercard from '../../../../assets/icons/cards/Hipercard.png';
import Alelo from '../../../../assets/icons/cards/Alelo.png';
import Sodexo from '../../../../assets/icons/cards/Sodexo.png';
import Sorocred from '../../../../assets/icons/cards/Sorocred.png';
import Ticket from '../../../../assets/icons/cards/Ticket.png';
import Vr from '../../../../assets/icons/cards/Vr.png';

import * as StoreActions from '../../../../store/modules/store/actions';
import CheckBox from '../../../components/CheckBox';

import {
  Container,
  Card,
  SubTitle,
  Body,
  Header,
  Text,
  Status,
  CheckBoxArea,
  Options,
  CardArea,
  Area,
  Cards,
  ButtonArea,
  Button,
  Money,
  CreditCard,
  DebitCard,
  Voucher,
} from './styles';

function StoreDeliveryPaymentMethods({
  getDeliveryPaymentMethods,
  createOrUpdateDeliveryPaymentMethods,
  getDeliveryPaymentCards,
  createOrUpdateDeliveryPaymentCards,
  createNewCard,
  deliveryPaymentMethods,
  deliveryPaymentCards,
}) {
  const [money, setMoney] = useState(deliveryPaymentMethods?.money || false);
  const [credit, setCredit] = useState(deliveryPaymentMethods?.credit || false);
  const [creditVisible, setCreditVisible] = useState(false);
  const [creditCards, setCreditCards] = useState([
    { name: 'Visa', type: 'Credit Card', status: false },
    { name: 'Mastercard', type: 'Credit Card', status: false },
    { name: 'Elo', type: 'Credit Card', status: false },
    { name: 'Hipercard', type: 'Credit Card', status: false },
    { name: 'American Express', type: 'Credit Card', status: false },
    { name: 'Diners Club', type: 'Credit Card', status: false },
    { name: 'Discover Network', type: 'Credit Card', status: false },
    { name: 'Maestro', type: 'Credit Card', status: false },
  ]);
  const [debit, setDebit] = useState(deliveryPaymentMethods?.debit || false);
  const [debitVisible, setDebitVisible] = useState(false);
  const [debitCards, setDebitCards] = useState([
    { name: 'Visa', type: 'Debit Card', status: false },
    { name: 'Mastercard', type: 'Debit Card', status: false },
    { name: 'Elo', type: 'Debit Card', status: false },
    { name: 'Hipercard', type: 'Debit Card', status: false },
    { name: 'American Express', type: 'Debit Card', status: false },
  ]);
  const [voucher, setVoucher] = useState(
    deliveryPaymentMethods?.voucher || false
  );
  const [voucherVisible, setVoucherVisible] = useState(false);
  const [voucherCards, setVoucherCards] = useState([
    { name: 'Alelo', type: 'Voucher Card', status: false },
    { name: 'Sodexo', type: 'Voucher Card', status: false },
    { name: 'Sorocred', type: 'Voucher Card', status: false },
    { name: 'Ticket', type: 'Voucher Card', status: false },
    { name: 'Vr', type: 'Voucher Card', status: false },
  ]);
  const [
    deliveryPaymentCardsToUpdate,
    setDeliveryPaymentCardsToUpdate,
  ] = useState([]);

  useEffect(() => {
    getDeliveryPaymentMethods();
    getDeliveryPaymentCards();
  }, [getDeliveryPaymentMethods, getDeliveryPaymentCards]);

  useEffect(() => {
    if (deliveryPaymentCards?.length > 0) {
      const creditCardsFiltered = deliveryPaymentCards.filter(
        (deliveryPaymentCard) => deliveryPaymentCard.type === 'Credit Card'
      );
      if (creditCardsFiltered?.length > 0) {
        creditCardsFiltered.map((cc) => {
          const creditCardIndex = creditCards.findIndex(
            (creditCard) => creditCard.name === cc.name
          );
          if (creditCardIndex >= 0) {
            creditCards[creditCardIndex] = {
              ...creditCards[creditCardIndex],
              status: cc.status,
            };
            return setCreditCards(creditCards);
          }

          creditCards.push(cc);
          return setCreditCards(creditCards);
        });
      }
      const debitCardsFiltered = deliveryPaymentCards.filter(
        (deliveryPaymentCard) => deliveryPaymentCard.type === 'Debit Card'
      );
      if (debitCardsFiltered?.length > 0) {
        debitCardsFiltered.map((dc) => {
          const debitCardIndex = debitCards.findIndex(
            (debitCard) => debitCard.name === dc.name
          );
          if (debitCardIndex >= 0) {
            debitCards[debitCardIndex] = {
              ...debitCards[debitCardIndex],
              status: dc.status,
            };
            return setDebitCards(debitCards);
          }

          debitCards.push(dc);
          return setDebitCards(debitCards);
        });
      }
      const voucherCardsFiltered = deliveryPaymentCards.filter(
        (deliveryPaymentCard) => deliveryPaymentCard.type === 'Voucher Card'
      );
      if (voucherCardsFiltered?.length > 0) {
        voucherCardsFiltered.map((vc) => {
          const voucherCardIndex = voucherCards.findIndex(
            (debitCard) => debitCard.name === vc.name
          );
          if (voucherCardIndex >= 0) {
            voucherCards[voucherCardIndex] = {
              ...voucherCards[voucherCardIndex],
              status: vc.status,
            };
            return setVoucherCards(voucherCards);
          }

          voucherCards.push(vc);
          return setVoucherCards(voucherCards);
        });
      }
    }
  }, [deliveryPaymentCards, creditCards, debitCards, voucherCards]);

  // #region handle Checkbox

  function handleMoney(e) {
    setMoney(e.target.checked);
  }
  function handleCredit(e) {
    setCredit(e.target.checked);
    if (e.target.checked) return setCreditVisible(true);

    return setCreditVisible(false);
  }
  function handleDebit(e) {
    setDebit(e.target.checked);
    if (e.target.checked) return setDebitVisible(true);

    return setDebitVisible(false);
  }
  function handleVoucher(e) {
    setVoucher(e.target.checked);
    if (e.target.checked) return setVoucherVisible(true);

    return setVoucherVisible(false);
  }

  // #endregion

  function handleIcon(icon) {
    if (icon === 'American Express') {
      return <img src={Amex} alt={icon} />;
    }
    if (icon === 'Diners Club') {
      return <img src={DinersClub} alt={icon} />;
    }
    if (icon === 'Visa') {
      return <img src={Visa} alt={icon} />;
    }
    if (icon === 'Mastercard') {
      return <img src={Mastercard} alt={icon} />;
    }
    if (icon === 'Elo') {
      return <img src={Elo} alt={icon} />;
    }
    if (icon === 'Hippercard') {
      return <img src={Visa} alt={icon} />;
    }
    if (icon === 'Discover Network') {
      return <img src={Discover} alt={icon} />;
    }
    if (icon === 'Maestro') {
      return <img src={Maestro} alt={icon} />;
    }
    if (icon === 'Hipercard') {
      return <img src={Hipercard} alt={icon} />;
    }
    if (icon === 'Alelo') {
      return <img src={Alelo} alt={icon} />;
    }
    if (icon === 'Sodexo') {
      return <img src={Sodexo} alt={icon} />;
    }
    if (icon === 'Sorocred') {
      return <img src={Sorocred} alt={icon} />;
    }
    if (icon === 'Ticket') {
      return <img src={Ticket} alt={icon} />;
    }
    if (icon === 'Vr') {
      return <img src={Vr} alt={icon} />;
    }

    return <img src={DefaultCard} alt={icon} />;
  }

  function handleNewCreditCard() {
    swal({
      text: 'Digite a bandeira do cartão de crédito.',
      content: 'input',
      buttons: ['Cancelar', 'Adicionar'],
    }).then((value) => {
      if (value) {
        setCreditCards((state) => [...state, { name: value, status: false }]);
        const type = 'Credit Card';
        return createNewCard(value, type, deliveryPaymentMethods.id);
      }
      return false;
    });
  }

  function handleNewDebitCard() {
    swal({
      text: 'Digite a bandeira do cartão de débito.',
      content: 'input',
      buttons: ['Cancelar', 'Adicionar'],
    }).then((value) => {
      if (value) {
        setDebitCards((state) => [...state, { name: value, status: false }]);
        const type = 'Debit Card';
        return createNewCard(value, type, deliveryPaymentMethods.id);
      }
      return false;
    });
  }

  function handleNewVoucherCard() {
    swal({
      text: 'Digite a bandeira do voucher.',
      content: 'input',
      buttons: ['Cancelar', 'Adicionar'],
    }).then((value) => {
      if (value) {
        setVoucherCards((state) => [...state, { name: value, status: false }]);
        const type = 'Voucher Card';
        return createNewCard(value, type, deliveryPaymentMethods.id);
      }
      return false;
    });
  }

  function handleSave() {
    const creditCardsSelecteds = creditCards.filter(
      (creditCard) => creditCard.status === true
    );
    const debitCardsSelecteds = debitCards.filter(
      (debitCard) => debitCard.status === true
    );
    const voucherCardsSelecteds = voucherCards.filter(
      (voucherCard) => voucherCard.status === true
    );
    if (credit && creditCardsSelecteds.length <= 0) {
      return toast.info(
        'Selecione os cartões de crédito que seu estabelecimento aceita, ou desative a opção cartão de crédito.  '
      );
    }
    if (debit && debitCardsSelecteds.length <= 0) {
      return toast.info(
        'Selecione os cartões de débito que seu estabelecimento aceita, ou desative a opção cartão de débito.  '
      );
    }
    if (voucher && voucherCardsSelecteds.length <= 0) {
      return toast.info(
        'Selecione os vouchers que seu estabelecimento aceita, ou desative a opção voucher. '
      );
    }

    if (
      deliveryPaymentMethods?.money === money &&
      deliveryPaymentMethods?.credit === credit &&
      deliveryPaymentMethods?.debit === debit &&
      deliveryPaymentMethods?.voucher === voucher &&
      JSON.stringify(creditCards) === JSON.stringify(deliveryPaymentCards)
    ) {
      return toast.info('Sem alterações');
    }

    if (
      deliveryPaymentMethods?.money !== money ||
      deliveryPaymentMethods?.credit !== credit ||
      deliveryPaymentMethods?.debit !== debit ||
      deliveryPaymentMethods?.voucher !== voucher
    ) {
      createOrUpdateDeliveryPaymentMethods({
        money,
        credit,
        debit,
        voucher,
      });
    }

    if (
      JSON.stringify(creditCards) !== JSON.stringify(deliveryPaymentCards) &&
      deliveryPaymentCardsToUpdate?.length > 0
    ) {
      createOrUpdateDeliveryPaymentCards(deliveryPaymentCardsToUpdate);
    }

    return false;
  }

  function editCreditCard(e, creditCard, index) {
    const array = [...creditCards];
    array[index] = { ...array[index], status: e.target.checked };
    setCreditCards(array);
    setDeliveryPaymentCardsToUpdate((state) => [
      ...state,
      { ...creditCard, status: !creditCard.status },
    ]);
  }

  function editDebitCard(e, debitCard, index) {
    const array = [...debitCards];
    array[index] = { ...array[index], status: e.target.checked };
    setDebitCards(array);
    setDeliveryPaymentCardsToUpdate((state) => [
      ...state,
      { ...debitCard, status: !debitCard.status },
    ]);
  }

  function editVoucherCard(e, voucherCard, index) {
    const array = [...voucherCards];
    array[index] = { ...array[index], status: e.target.checked };
    setVoucherCards(array);
    setDeliveryPaymentCardsToUpdate((state) => [
      ...state,
      { ...voucherCard, status: !voucherCard.status },
    ]);
  }

  return (
    <Container>
      <Card>
        <SubTitle>
          <span>
            Selecione as formas de pagamentos e bandeiras que seu
            estabelecimento aceita.
          </span>
        </SubTitle>
        <Body>
          <Money money={money}>
            <Header>
              <Text>
                <span>Dinheiro</span>
              </Text>
              <Status>
                <span>{money ? 'Habilitado' : 'Desabilitado'}</span>
              </Status>
              <CheckBoxArea>
                <CheckBox
                  status={money}
                  id="money"
                  handleStatus={handleMoney}
                />
              </CheckBoxArea>
            </Header>
          </Money>
          <CreditCard creditVisible={creditVisible} credit={credit}>
            <Header
              creditVisible={creditVisible}
              onClick={() => credit && setCreditVisible(!creditVisible)}
            >
              <Text>
                <span>Cartão de crédito</span>
              </Text>
              <Status>
                <span>{credit ? 'Habilitado' : 'Desabilitado'}</span>
              </Status>
              <Options status={creditVisible}>
                {credit && <IoIosArrowDropup />}
                <CheckBox
                  status={credit}
                  id="credit"
                  handleStatus={handleCredit}
                />
              </Options>
            </Header>
            {creditVisible && credit && (
              <CardArea>
                <Area>
                  {creditCards?.length > 0 &&
                    creditCards.map((creditCard, index) => {
                      return (
                        <Cards key={creditCard.name}>
                          <input
                            onChange={(e) =>
                              editCreditCard(e, creditCard, index)
                            }
                            checked={creditCard.status}
                            type="checkbox"
                          />
                          {handleIcon(creditCard.name)} {creditCard.name}
                        </Cards>
                      );
                    })}
                </Area>
                <ButtonArea>
                  <Button onClick={() => handleNewCreditCard()}>
                    Adicionar nova bandeira
                  </Button>
                </ButtonArea>
              </CardArea>
            )}
          </CreditCard>
          <DebitCard debit={debit} debitVisible={debitVisible}>
            <Header
              debitVisible={debitVisible}
              onClick={() => setDebitVisible(!debitVisible)}
            >
              <Text>
                <span>Cartão de débito</span>
              </Text>
              <Status>
                <span>{debit ? 'Habilitado' : 'Desabilitado'}</span>
              </Status>

              <Options status={debitVisible}>
                {debit && <IoIosArrowDropup />}
                <CheckBox
                  status={debit}
                  id="debit"
                  handleStatus={handleDebit}
                />
              </Options>
            </Header>
            {debitVisible && debit && (
              <CardArea>
                <Area>
                  {debitCards?.length > 0 &&
                    debitCards.map((debitCard, index) => {
                      return (
                        <Cards key={debitCard.name}>
                          <input
                            onChange={(e) => editDebitCard(e, debitCard, index)}
                            checked={debitCard.status}
                            type="checkbox"
                          />
                          {handleIcon(debitCard.name)} {debitCard.name}
                        </Cards>
                      );
                    })}
                </Area>
                <ButtonArea>
                  <Button onClick={() => handleNewDebitCard()}>
                    Adicionar nova bandeira
                  </Button>
                </ButtonArea>
              </CardArea>
            )}
          </DebitCard>
          <Voucher voucher={voucher} voucherVisible={voucherVisible}>
            <Header onClick={() => setVoucherVisible(!voucherVisible)}>
              <Text>
                <span>Voucher</span>
              </Text>
              <Status>
                <span>{voucher ? 'Habilitado' : 'Desabilitado'}</span>
              </Status>
              <Options status={voucherVisible}>
                {voucher && <IoIosArrowDropup />}
                <CheckBox
                  status={voucher}
                  id="voucher"
                  handleStatus={handleVoucher}
                />
              </Options>
            </Header>
            {voucherVisible && voucher && (
              <CardArea>
                <Area>
                  {voucherCards?.length > 0 &&
                    voucherCards.map((voucherCard, index) => {
                      return (
                        <Cards key={voucherCard.name}>
                          <input
                            onChange={(e) =>
                              editVoucherCard(e, voucherCard, index)
                            }
                            checked={voucherCard.status}
                            type="checkbox"
                          />
                          {handleIcon(voucherCard.name)} {voucherCard.name}
                        </Cards>
                      );
                    })}
                </Area>
                <ButtonArea>
                  <Button onClick={() => handleNewVoucherCard()}>
                    Adicionar nova bandeira
                  </Button>
                </ButtonArea>
              </CardArea>
            )}
          </Voucher>
          <ButtonArea onClick={() => handleSave()}>
            <Button>Salvar</Button>
          </ButtonArea>
        </Body>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(StoreActions, dispatch);

const mapStateToProps = (state) => ({
  deliveryPaymentMethods: state.store.deliveryPaymentMethods,
  deliveryPaymentCards: state.store.deliveryPaymentCards,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreDeliveryPaymentMethods);
