import styled from 'styled-components';

export const Container = styled.div``;

export const Body = styled.div`
  display: flex;
`;

export const DeliveryInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 70px - 12px); // tela - header - padding bottom
`;
