import styled from 'styled-components';

import Modal from 'react-modal';

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  width: 40%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-left: 40px;

  &:hover {
    box-shadow: 10px 10px 10px #80828b30;
  }

  @media (max-width: 1700px) {
    width: 60%;
  }

  @media (max-width: 992px) {
    width: 95%;
    border-radius: 0;
    margin-left: 0;
  }

  @media (max-width: 576px) {
    padding: 10px 0;
  }
`;

export const Form = styled.form``;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 40px;
  color: ${(props) => props.theme.colors.greenDark};

  @media (max-width: 768px) {
    padding: 20px;
  }
`;
export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 20px 5px;
`;
export const ButtonCancel = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.redDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;
  margin-right: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
    box-shadow: 2px 6px 8px #00000050;
  }
`;

export const Button = styled.button.attrs({ type: 'submit' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;
  margin-left: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }
`;
