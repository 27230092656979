import produce from 'immer';

const INITIAL_STATE = {
  orders: [],
  contactRequests: [],
  nextPageContactRequests: 1,
  nextPage: 1,
  orderItem: {},
  loading: true,
  loadingProducts: false,
  drawerComplete: false,
  drawerVisible: false,
  orderItemsVisible: false,
  loadingTracks: false,
  tracks: [],
};

export default function main(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_ORDERS': {
        if (draft.orders.length > 0) {
          draft.orders = state.orders.concat(action.payload.orders);
          break;
        }

        draft.orders = action.payload.orders;
        break;
      }
      case 'SET_NEXT_PAGE': {
        draft.nextPage = action.payload.nextPage;
        break;
      }
      case 'SET_ORDER_ITEM': {
        draft.orderItem = action.payload.orderItem;
        break;
      }

      case 'HANDLE_LOADING': {
        draft.loading = action.payload.state;
        break;
      }
      case 'HANDLE_LOADING_PRODUCTS': {
        draft.loadingProducts = action.payload.state;
        break;
      }
      case 'HANDLE_DRAWER_VISIBLE': {
        draft.drawerVisible = action.payload.state;
        break;
      }
      case 'HANDLE_DRAWER_COMPLETE': {
        draft.drawerComplete = action.payload.state;
        break;
      }
      case 'HANDLE_ORDER_ITEMS_VISIBLE': {
        draft.orderItemsVisible = action.payload.state;
        break;
      }
      case 'SET_CONTACT_REQUESTS': {
        if (draft.contactRequests.length > 0) {
          draft.contactRequests = state.contactRequests.concat(
            action.payload.contactRequests
          );
          break;
        }

        draft.contactRequests = action.payload.contactRequests;
        break;
      }

      case 'UPDATE_CONTACT_REQUESTS': {
        const contactRequestIndex = draft.contactRequests.findIndex(
          (c) => c.id === action.payload.contactRequest.id
        );

        if (contactRequestIndex >= 0) {
          draft.contactRequests[contactRequestIndex] =
            action.payload.contactRequest;
        }
        break;
      }

      case 'SET_NEXT_PAGE_CONTACT_REQUESTS': {
        draft.nextPageContactRequests = action.payload.nextPage;
        break;
      }
      case 'HANDLE_LOADING_TRACKS': {
        draft.loadingTracks = action.state;
        break;
      }
      case 'SET_TRACKING_ORDER': {
        draft.tracks = action.payload.tracks;
        break;
      }
      default:
        break;
    }
  });
}
