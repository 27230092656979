export const theme = {
  colors: {
    white: '#fff',
    greenDark: '#11998e',
    greenMedium: '#24c386',
    greenLight: '#38ef7d',
    linearGradientGreen: `linear-gradient(to right, #11998e, #24c386, #38ef7d)`,
    grayDark: '#3d3d3b',
    grayLight: '#9d9d9c',
    redLight: '#e57373',
    redDark: '#ef5350',
    blackLight: '#333',
  },
  dimensions: {
    header: {
      height: '70px',
    },
    drawer: {
      width: '90px',
    },
  },
};
