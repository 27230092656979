import axios from 'axios';

import history from './history';
import { getStoreSlug } from '../utils/storeUtil';

const api = axios.create({
  baseURL: 'https://www.api.i9p.com.br/api/v1/adm',
  // baseURL: 'http://10.0.0.10:3333/api/v1/adm',
  // baseURL: 'http://localhost:3333/api/v1/adm',
  // baseURL: 'http://privea.ddns.com.br:3333/api/v1/adm',
});

const store = getStoreSlug();

export default {
  getApiInstance() {
    return api;
  },

  async Login(email, password) {
    const data = { email, password };

    const response = await api
      .post('/sessions', data, { headers: { store } })
      .then(null, (error) => {
        return { status: error.response.status };
      });

    return response;
  },

  async getStore() {
    const response = await api
      .get('/stores', {
        headers: { store },
      })
      .then(null, (error) => {
        if (error.response) {
          const { status } = error.response;

          if (status === 400) {
            return history.push(`/${getStoreSlug()}/erro/400`);
          }

          if (status === 401) {
            return error;
          }

          if (status === 403) {
            return history.push(`/${getStoreSlug()}/erro/403`);
          }

          if (status === 404) {
            return history.push(`/${getStoreSlug()}/erro/404`);
          }
        }

        return history.push(`/${getStoreSlug()}/erro/500`);
      });
    return response?.data;
  },

  async updateStoreLocation(latitude, longitude) {
    const data = { latitude, longitude };

    await api
      .put('/store/position', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
  },

  async getDeliveryAreas(page) {
    const areas = await api
      .get('/delivery_area', {
        params: {
          page,
          limit: page > 1 ? 20 : 20,
        },
        headers: { store },
      })
      .then(null, (error) => {
        if (error.response) {
          const { status } = error.response;

          if (status === 400) {
            return history.push(`/${getStoreSlug()}/erro/400`);
          }

          if (status === 401) {
            return error;
          }

          if (status === 403) {
            return history.push(`/${getStoreSlug()}/erro/403`);
          }

          if (status === 404) {
            return history.push(`/${getStoreSlug()}/erro/404`);
          }
        }

        return history.push(`/${getStoreSlug()}/erro/500`);
      });
    return areas.data;
  },

  async createDeliveryArea(
    name,
    price,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
    status,
    geolocation,
    radius
  ) {
    let minMinutes = minWaitingTime;
    let maxMinutes = maxWaitingTime;
    if (minWaitingType === 'Horas') {
      minMinutes = minWaitingTime * 60;
    }
    if (maxWaitingType === 'Horas') {
      maxMinutes = maxWaitingTime * 60;
    }
    if (minWaitingType === 'Dias') {
      minMinutes = minWaitingTime * 1440;
    }
    if (maxWaitingType === 'Dias') {
      maxMinutes = maxWaitingTime * 1440;
    }
    const data = {
      name,
      price,
      min_waiting_time: minMinutes,
      max_waiting_time: maxMinutes,
      min_waiting_type: minWaitingType,
      max_waiting_type: maxWaitingType,
      status,
      geolocation,
      radius,
    };

    const response = await api
      .post('/delivery_area', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response.data;
  },

  async getOrders(orderId, page) {
    const response = orderId
      ? await api
          .get(`/orders/${orderId}`, {
            headers: { store },
          })
          .then(null, (error) => {
            if (error.response) {
              const { status } = error.response;

              if (status === 400) {
                return history.push(`/${getStoreSlug()}/erro/400`);
              }

              if (status === 401) {
                return error;
              }

              if (status === 403) {
                return history.push(`/${getStoreSlug()}/erro/403`);
              }

              if (status === 404) {
                return history.push(`/${getStoreSlug()}/erro/404`);
              }
            }

            return history.push(`/${getStoreSlug()}/erro/500`);
          })
      : await api
          .get('/orders', {
            params: {
              page,
            },
            headers: { store },
          })
          .then(null, (error) => {
            if (error.response) {
              const { status } = error.response;

              if (status === 400) {
                return history.push(`/${getStoreSlug()}/erro/400`);
              }

              if (status === 401) {
                return error;
              }

              if (status === 403) {
                return history.push(`/${getStoreSlug()}/erro/403`);
              }

              if (status === 404) {
                return history.push(`/${getStoreSlug()}/erro/404`);
              }
            }

            return history.push(`/${getStoreSlug()}/erro/500`);
          });

    return response;
  },

  async updateOrderStatus(status, orderId) {
    const data = { status };

    const response = await api.put(`orders/${orderId}`, data, {
      headers: { store },
    });

    return response;
  },

  async changeDeliveryStatus(status, deliveryId) {
    const data = { status };

    const response = await api.put(`/delivery_area/${deliveryId}`, data, {
      headers: { store },
    });

    return response.data;
  },

  async updateDelivery(
    deliveryId,
    name,
    price,
    minWaitingTime,
    maxWaitingTime,
    minWaitingType,
    maxWaitingType,
    status
  ) {
    let minMinutes = minWaitingTime;
    let maxMinutes = maxWaitingTime;
    if (minWaitingType === 'Horas') {
      minMinutes = minWaitingTime * 60;
    }
    if (maxWaitingType === 'Horas') {
      maxMinutes = maxWaitingTime * 60;
    }
    if (minWaitingType === 'Dias') {
      minMinutes = minWaitingTime * 1440;
    }
    if (maxWaitingType === 'Dias') {
      maxMinutes = maxWaitingTime * 1440;
    }
    const data = {
      name,
      price,
      min_waiting_time: minMinutes,
      max_waiting_time: maxMinutes,
      min_waiting_type: minWaitingType,
      max_waiting_type: maxWaitingType,
      status,
    };

    const response = await api.put(`/delivery_area/${deliveryId}`, data, {
      headers: { store },
    });

    return response.data;
  },

  async deleteDeliveryArea(deliveryId) {
    const response = await api.delete(`/delivery_area/${deliveryId}`, {
      headers: { store },
    });

    return response;
  },

  async getZipCode(zipCode) {
    const response = await axios
      .get(`https://brasilapi.com.br/api/cep/v1/${zipCode}`)
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getTablePrices() {
    const response = await api
      .get('/table-prices', { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateStore(storeToUpdate) {
    const {
      name,
      razaoSocial,
      cnpj,
      description,
      zipCode,
      street,
      number,
      neighborhood,
      complement,
      city,
      state,
    } = storeToUpdate;
    const data = {
      name,
      razao_social: razaoSocial,
      cnpj,
      description,
      zip_code: zipCode,
      street,
      number,
      neighborhood,
      complement,
      city,
      state,
    };
    const response = await api
      .put(`/stores`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updateStoreSettings(storeToUpdate) {
    const data = {
      customer_register_approval: storeToUpdate.customerRegister,
      price_public: storeToUpdate.pricePublic,
      order_minimun_value: storeToUpdate.minimunValue,
      online: storeToUpdate.online,
      delivery_time: storeToUpdate.deliveryTime,
      restrict_delivery_area: storeToUpdate.delivery,
      table_prices_id: storeToUpdate.tablePrice,
      color_primary: storeToUpdate.colorPrimary,
      show_store_info_header: storeToUpdate.cardInfo,
      facebook: storeToUpdate.facebook,
      instagram: storeToUpdate.instagram,
      youtube: storeToUpdate.youtube,
      twitter: storeToUpdate.twitter,
      website: storeToUpdate.website,
      cart_visible: storeToUpdate.cartVisible,
      show_address: storeToUpdate.showAddress,
    };
    const response = await api
      .put('stores/settings', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateStoreLogo(logo) {
    const data = new FormData();
    data.append('file', logo);

    const response = await api
      .post(`/stores/settings/logo`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async deleteStoreLogo() {
    const data = {
      logo_path: '',
    };
    const response = await api
      .put('stores/settings', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateStoreBanner(banner) {
    const data = new FormData();
    data.append('file', banner);

    const response = await api
      .post(`/stores/settings/banner`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async deleteStoreBanner() {
    const data = {
      banner_path: '',
    };
    const response = await api
      .put('stores/settings', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateStoreBannerMobile(bannerMobile) {
    const data = new FormData();
    data.append('file', bannerMobile);

    const response = await api
      .post(`/stores/settings/banner-mobile`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async deleteStoreBannerMobile() {
    const data = {
      banner_mobile_path: '',
    };
    const response = await api
      .put('stores/settings', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateStoreWaterMark(waterMark) {
    const data = new FormData();
    data.append('file', waterMark);

    const response = await api
      .post(`/stores/settings/watermark`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },
  async deleteStoreWaterMark() {
    const data = {
      product_watermark_path: '',
      product_watermark_thumb_path: '',
    };
    const response = await api
      .put('stores/settings', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getCategories() {
    const response = await api
      .get('/categories', { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async createCategory(name) {
    const data = {
      name,
    };

    const response = await api
      .post('categories', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async getProducts(page, search, sort, direction) {
    const headers = { store };
    if (search) headers.name = search;

    const response = await api
      .get('/products', {
        params: {
          page,
          sort,
          direction,
        },
        headers,
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getProduct(productId) {
    const headers = { store };

    const response = await api
      .get(`/products/${productId}`, {
        headers,
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async createProduct(product) {
    const data = {
      category_id: product.category_id,
      name: product.name,
      code: product.code || '',
      description: product.description || '',
      unit_description: product.unitDescription || '',
      multiple: parseFloat(product.multiple) || 1,
      weight: product.weight || null,
      length: product.length || null,
      height: product.height || null,
      width: product.width || null,
      prices: product.prices || [],
    };
    const response = await api
      .post('/products', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async deleteProduct(productId) {
    const response = await api
      .delete(`/products/${productId}`, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateProductAvaiable(productId, data) {
    const response = await api
      .put(`products/${productId}`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updateProductPhoto(poductId, image) {
    const data = new FormData();
    data.append('file', image);

    const response = await api
      .post(`/products/${poductId}/photos`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async deleteProductPhoto(poductId, imageId) {
    const response = await api
      .delete(`/products/${poductId}/photos/${imageId}`, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async updateProduct(product) {
    const data = {
      category_id: product.category_id,
      name: product.name,
      code: product.code || '',
      description: product.description || '',
      unit_description: product.unitDescription || '',
      multiple: parseFloat(product.multiple) || 1,
      weight: product.weight || null,
      length: product.length || null,
      height: product.height || null,
      width: product.width || null,
    };

    const response = await api
      .put(`products/${product.id}`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },
  async createProductTablePrice(
    productId,
    tablePriceId,
    price,
    promotionalPrice
  ) {
    const data = {
      product_id: productId,
      regular_price: parseFloat(price),
      promotional_price: promotionalPrice ? parseFloat(promotionalPrice) : 0,
    };

    const response = await api
      .post(`/table-prices/${tablePriceId}/products`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updateProductTablePrice(
    tablePriceId,
    productId,
    price,
    promotionalPrice
  ) {
    const data = {
      regular_price: parseFloat(price),
      promotional_price: promotionalPrice ? parseFloat(promotionalPrice) : 0,
    };
    const response = await api
      .put(`/table-prices/${tablePriceId}/products/${productId}`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async createTablePrice(name) {
    const data = { name };
    const response = await api
      .post('/table-prices', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async deleteProductTablePrice(tablePriceId, productId) {
    const response = await api
      .delete(`/table-prices/${tablePriceId}/products/${productId}`, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getCustomers(page, search, sort, direction) {
    const response = await api
      .get('/customers', {
        params: {
          page,
          search: search || '%',
          sort,
          direction,
        },
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getCustomer(customerId) {
    const response = await api
      .get(`/customers/${customerId}`, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getCustomerAddresses(customerId) {
    const response = await api
      .get(`customers/${customerId}/addresses`, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async createCustomer(customer) {
    const data = {
      name: customer.name,
      email: customer.email,
      cellphone: customer.celphone,
      phone: customer.phonehome,
      document: customer.document,
      password: customer.password,
      accept_terms: true,
      register_aproved: true,
      force_change_password: customer.forceChangePassword,
      address: customer.address,
    };

    if (customer.tablePriceId) data.table_prices_id = customer.tablePriceId;

    const response = await api
      .post(`/customers`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async createCustomerAdresses(address, customerId) {
    const data = {
      zip_code: address.zip_code,
      complement: address.complement || '',
      neighborhood: address.neighborhood,
      street: address.street,
      city: address.city,
      state: address.state,
      number: parseInt(address.number, 10),
    };

    const response = await api
      .post(`/customers/${customerId}/addresses`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async deleteCustomerAddress(address, customerId) {
    const response = await api
      .delete(`customers/${customerId}/addresses/${address.id}`, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updateCustomer(customerId, customer) {
    const data = {
      name: customer.name,
      phone: customer.phone,
      cellphone: customer.cellphone,
      email: customer.email,
      document: customer.document,
      force_change_password: customer.force_change_password,
      table_prices_id: customer.table_prices_id,
      register_aproved: customer.register_aproved,
    };

    const response = await api
      .put(`/customers/${customerId}`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },
  async updateCustomerAddress(customerId, customerAddressId, customerAddress) {
    const data = {
      zip_code: customerAddress.zip_code,
      complement: customerAddress.complement || '',
      neighborhood: customerAddress.neighborhood,
      street: customerAddress.street,
      city: customerAddress.city,
      state: customerAddress.state,
      number: parseInt(customerAddress.number, 10),
    };

    const response = await api
      .put(`customers/${customerId}/addresses/${customerAddressId}`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async deleteCustomer(customerId) {
    const response = await api
      .delete(`customers/${customerId}`, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async getContactRequests(page) {
    const response = await api
      .get('/contact-request', {
        params: {
          page,
        },
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updateContactRequest(id, status) {
    const data = {
      status,
    };
    const response = await api
      .post(`/contact-request/${id}`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updatePeriodInfo(workPeriodId, data) {
    const response = await api
      .put(`/stores/work-period/${workPeriodId}`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async getDeliveryPaymentMethods() {
    const response = await api
      .get('/payment-methods', { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async createDeliveryPaymentMethods(data) {
    const response = await api
      .post('/payment-methods', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getDeliveryPaymentCards() {
    const response = await api
      .get('/payment-cards', { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async createOrUpdateDeliveryPaymentCards(data) {
    const response = await api
      .post('/payment-cards', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async setDeliveryPaymentMethods(data) {
    const response = await api
      .post('/payment-methods', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getDashboardValues(data) {
    const response = await api
      .post('/dashboard', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async getAlerts() {
    const response = await api
      .get('/alerts', { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async createAlert(data) {
    const response = await api
      .post('/alert', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async updateAlert(data, alertId) {
    const response = await api
      .put(`/alert/${alertId}`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async deleteAlert(alertId) {
    const response = await api
      .delete(`/alert/${alertId}`, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getOptions() {
    const response = await api
      .get('/option/groups', { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async createGroup(data) {
    const response = await api
      .post('/option/group', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async createOption(data) {
    const response = await api
      .post('/option/item', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },
  async getTrackingOrder(data) {
    const response = await api
      .post('/delivery/track-order', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
};
