import React from 'react';
import { connect } from 'react-redux';

import CategoriesList from './components/CategoriesList';

import { Container, Body } from './styles';

function Categories() {
  return (
    <Container>
      <Body>
        <CategoriesList />
      </Body>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
