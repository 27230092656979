import { takeLatest, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import { getStoreSlug } from '../../../utils/storeUtil';
import api from '../../../services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  const { email, password } = payload;

  if (email === '' || password === '') {
    toast.error('Preecha os campos.');
    return yield put(signFailure());
  }
  const response = yield api.Login(email, password);

  if (response && response.status && response.status !== 200) {
    toast.error('Usuário ou senha incorretos');
    return yield put(signFailure());
  }

  if (response && response.status && response.status === 200) {
    const user = response.response && response.data.customer;

    const { token } = response.data;

    api.getApiInstance().defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));
    return history.push(`/${getStoreSlug()}/pedidos`);
  }
  return toast.error('Erro ao conectar, tente novamente mais tarde');
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.getApiInstance().defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('SIGN_IN_REQUEST', signIn),
]);
