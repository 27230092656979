export function handleLoadingCustomers(state) {
  return {
    type: 'HANDLE_LOADING_CUSTOMERS',
    payload: { state },
  };
}

export function getCustomers(payload) {
  return {
    type: 'GET_CUSTOMERS',
    payload,
  };
}

export function setCustomers(customers, page, nextPage) {
  return {
    type: 'SET_CUSTOMERS',
    payload: { customers, page, nextPage },
  };
}

export function getCustomer(customerId) {
  return {
    type: 'GET_CUSTOMER',
    payload: { customerId },
  };
}

export function setCurrentCustomer(currentCustomer) {
  return {
    type: 'SET_CURRENT_CUSTOMER',
    payload: { currentCustomer },
  };
}

export function getCustomerAddresses(customerId) {
  return {
    type: 'GET_CUSTOMER_ADDRESSES',
    payload: { customerId },
  };
}

export function setCustomersAdresses(addresses) {
  return {
    type: 'SET_CUSTOMER_ADDRESSES',
    payload: { addresses },
  };
}

export function createCustomer(customer) {
  return {
    type: 'CREATE_CUSTOMER',
    payload: { customer },
  };
}

export function updateCustomer(customerId, customer) {
  return {
    type: 'UPDATE_CUSTOMER',
    payload: { customerId, customer },
  };
}

export function deleteCustomer(customerId) {
  return {
    type: 'DELETE_CUSTOMER',
    payload: { customerId },
  };
}

export function handleModalAddress(state) {
  return {
    type: 'HANDLE_MODAL_ADDRESS',
    state,
  };
}

export function findCustomerZipCode(zipCode) {
  return {
    type: 'FIND_CUSTOMER_ZIP_CODE',
    zipCode,
  };
}
export function setCustomerZipCode(address) {
  return {
    type: 'SET_CUSTOMER_ZIP_CODE',
    address,
  };
}

export function updateAddressesList(address) {
  return {
    type: 'UPDATE_ADDRESSES_LIST',
    address,
  };
}

export function editAddressList(address) {
  return {
    type: 'EDIT_ADDRESS_LIST',
    payload: { address },
  };
}

export function setAddressToEdit(address) {
  return {
    type: 'SET_ADDRESS_TO_EDIT',
    payload: { address },
  };
}

export function createCustomerAddress(customerId, address) {
  return {
    type: 'CREATE_CUSTOMER_ADDRESS',
    payload: { customerId, address },
  };
}

export function updateCustomerAddress(
  customerId,
  customerAddressId,
  customerAddress
) {
  return {
    type: 'UPDATE_CUSTOMER_ADDRESS',
    payload: { customerId, customerAddressId, customerAddress },
  };
}

export function deleteCustomerAddress(address, customerId) {
  return {
    type: 'DELETE_CUSTOMER_ADDRESS',
    payload: { address, customerId },
  };
}

export function removeAddress(address) {
  return {
    type: 'REMOVE_ADDRESS',
    payload: { address },
  };
}
