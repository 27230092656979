import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
`;

export const SettingsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Store = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0px;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
`;

export const Tab = styled.div`
  width: 200px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${(props) => props.focus && '#fff'};
  padding: 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: color 0.2s, font-weight 0.2s, background 0.4s;
  color: ${(props) =>
    props.focus ? props.theme.colors.grayDark : props.theme.colors.greenDark};
  font-weight: ${(props) => !props.focus && 'bold'};

  & + div {
    margin-left: 10px;
  }

  &:hover {
    background: ${(props) => !props.focus && props.theme.colors.greenDark};
    color: ${(props) => !props.focus && '#fff'};
    font-weight: ${(props) => !props.focus && 'bold'};
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
`;

export const IconMobile = styled.div`
  display: none;

  svg {
    height: 25px;
    width: 25px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Card = styled.div`
  background: #fff;
  border-radius: ${(props) => (props.focus ? '0px' : '0.25rem')} 0.25rem 0.25rem
    0.25rem;
  box-shadow: 1px 2px 2px #00000050;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
`;
