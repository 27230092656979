import React from 'react';

import CustomersList from './components/CustomersList';

import { Container, Body } from './styles';

function Customers({ match }) {
  return (
    <Container>
      <Body>
        <CustomersList match={match} />
      </Body>
    </Container>
  );
}
export default Customers;
