import produce from 'immer';

const INITIAL_STATE = {
  alerts: [],
  currentAlert: {},
  deliveryPaymentMethods: {},
  deliveryPaymentCards: [],
  loadingStore: false,
  address: {},
  modalAlerts: false,
  storeInfo: {
    name: '',
    street: '',
    number: '',
    city: '',
    work_period: [],
    settings: {
      logo_url: null,
      banner_url: null,
      color_primary: '#43A047',
      order_minimun_value: null,
      price_public: false,
      showcase: false,
    },
  },
};

export default function store(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_STORE_INFO': {
        draft.storeInfo = action.payload;

        break;
      }

      case 'SET_STORE_ADDRESS': {
        draft.address = action.address;

        break;
      }

      case 'HANDLE_LOADING_STORE': {
        draft.loadingStore = action.payload.state;
        break;
      }
      case 'SET_DELIVERY_PAYMENT_METHODS': {
        draft.deliveryPaymentMethods = action.payload.deliveryPaymentMethods;
        break;
      }

      case 'SET_DELIVERY_PAYMENT_CARDS': {
        draft.deliveryPaymentCards = action.payload.deliveryPaymentCards;
        break;
      }

      case 'UPDATE_DELIVERY_PAYMENT_CARDS': {
        draft.deliveryPaymentCards = [
          ...state.deliveryPaymentCards,
          action.payload.deliveryPaymentCards,
        ];
        break;
      }

      default:
    }
  });
}
