import styled, { keyframes } from 'styled-components';

import Modal from 'react-modal';

const animate = keyframes`
  from {
    opacity: 0;
  };
  to {
    opacity: 1;
  }
`;

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 80%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;

  &:hover {
    box-shadow: 10px 10px 10px #80828b30;
  }

  @media (max-width: 1700px) {
    width: 60%;
  }

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin-top: 0;
    border-radius: 0;
  }

  @media (max-width: 576px) {
    padding: 0;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  height: 100% !important;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const TracksList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const TrackArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${animate} 0.6s;
  height: 100%;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  padding: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const Track = styled.div`
  display: flex;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Dot = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

export const Line = styled.div`
  display: flex;
  height: 100%;
  width: 1px;
  background: ${(props) => props.color};
`;

export const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;

  span {
    margin-bottom: 10px;
  }
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 1150px) {
    padding: 20px;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  background: ${(props) => props.color};
  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000050;
  transition: all 0.6s;
  color: #fff;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }
`;
