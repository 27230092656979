import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  @media (max-width: 992px) {
    padding: 10px;
  }
`;
export const Title = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 0;

  svg {
    margin-right: 5px;
  }
`;

export const Weekdays = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 10px;

  & + div {
    margin-top: 10px;
  }
`;

export const Day = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  input {
    margin-right: 10px;
  }
`;

export const Select = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation: ${animate} 0.6s;
`;

export const BoxArea = styled.div`
  display: flex;
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  flex: 1;
`;

export const ButtonAddArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 5px 10px;
  flex: 0.1;
`;

export const ButtonAdd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.visible ? props.theme.colors.redLight : props.theme.colors.greenDark};
  color: #fff;
  border-radius: 5px;
  height: 30px;
  width: 100%;
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disable ? 0.5 : 1)};

  box-shadow: 0px 2px 5px #00000050;
  transition: all 0.6s;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    box-shadow: 1px 3px 4px #00000050;
  }
`;

export const Span = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};

  @media (max-width: 576px) {
    font-size: 14px;
    flex: 2;
  }
`;

export const Closed = styled.div`
  display: flex;
  justify-content: Center;
  align-items: center;
  flex: 1;
`;
export const Space = styled.div`
  flex: 1;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.5;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
  border: 0;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }

  svg {
    margin-left: 25px;
  }
`;

export const ButtonCancel = styled(Button)`
  background: ${(props) => props.theme.colors.redDark};
  margin-right: 20px;
  animation: ${animate} 0.6s;

  &:hover {
    background: ${(props) => props.theme.colors.redLight};
  }
`;
