import React from 'react';

import { InputStyled } from './styles';

function InputMask({
  id,
  length,
  type,
  mask,
  maskPlaceholder,
  maskChar,
  placeholder,
  value,
  setState,
  required,
  disabled,
  pattern,
  children,
}) {
  return (
    <InputStyled
      id={id}
      required={required}
      maxLength={length}
      type={type}
      onChange={(text) => setState(text.target.value)}
      className="inputMask"
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      maskChar={maskChar}
      pattern={pattern}
    >
      {children}
    </InputStyled>
  );
}

export default InputMask;
