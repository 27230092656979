import produce from 'immer';

const INITIAL_STATE = {
  loadingProductsList: true,
  nextPage: 1,
  products: [],
  currentProduct: { photos: [] },
  currentProductPrices: [], // list of prices from creating/editing product
  currentPrice: {},
  categories: [], // list of categories
  options: [], // list of categoris for select input
  tablePrices: [], // list of table prices
  tablePriceOptions: [], // list of table prices for select input
  modalTablePrice: false,
};

export default function products(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_CATEGORIES': {
        draft.categories = action.payload.categories;
        break;
      }

      case 'UPDATE_CATEGORY': {
        draft.categories.push({
          ...action.payload.category,
        });
        break;
      }

      case 'SET_OPTIONS': {
        draft.options = action.payload.options;
        break;
      }

      case 'UPDATE_OPTIONS': {
        draft.options = [...state.options, action.payload.option];
        break;
      }

      case 'SET_TABLE_PRICES': {
        draft.tablePrices = action.payload.tablePrices;

        break;
      }

      case 'SET_TABEL_PRICE_OPTIONS': {
        draft.tablePriceOptions = action.payload.options;
        break;
      }

      case 'UPDATE_TABEL_PRICE_OPTIONS': {
        draft.tablePriceOptions = [
          ...state.tablePriceOptions,
          action.payload.option,
        ];
        break;
      }

      case 'SET_PRODUCTS': {
        if (
          action.payload.page === 1 ||
          state.customers?.length <= 0 ||
          action.payload.customers?.length <= 0
        ) {
          draft.products = action.payload.products;
        } else {
          draft.products = state.products.concat(action.payload.products);
        }

        draft.nextPage = action.payload.nextPage;

        break;
      }

      case 'REMOVE_PRODUCT': {
        const productIndex = draft.products.findIndex(
          (p) => p.id === action.payload.productId
        );

        if (productIndex >= 0) {
          draft.products.splice(productIndex, 1);
        }
        break;
      }

      case 'SET_CURRENT_PRODUCT': {
        draft.currentProduct = action.payload.product;
        break;
      }

      case 'SET_CURRENT_PRODUCT_PRICES': {
        draft.currentProductPrices = action.payload.prices;
        break;
      }

      case 'UPDATE_CURRENT_PRODUCT': {
        draft.currentProduct.name = action.name;
        draft.currentProduct.code = action.code;
        draft.currentProduct.description = action.description;
        draft.currentProduct.unit_description = action.unitDescription;
        draft.currentProduct.multiple = action.multiple;

        break;
      }
      case 'UPDATE_CURRENT_PRODUCT_PHOTO': {
        draft.currentProduct.photos = state.currentProduct.photos.concat(
          action.payload.photo
        );

        break;
      }
      case 'UPDATE_CURRENT_PRODUCT_PRICE': {
        const priceIndex = draft.currentProductPrices.findIndex(
          (p) => p.id === action.payload.tablePriceId
        );
        draft.currentProductPrices[priceIndex].regular_price =
          action.payload.price;
        draft.currentProductPrices[priceIndex].promotional_price =
          action.payload.promotionalPrice;

        break;
      }
      case 'UPDATE_CURRENT_PRODUCT_PRICES': {
        draft.currentProductPrices.push(action.payload.newPrice);

        break;
      }
      case 'REMOVE_PRODUCT_PRICE': {
        const priceIndex = draft.currentProductPrices.findIndex(
          (p) =>
            p.id === action.payload.tablePriceId ||
            p.tempId === action.payload.tablePriceId
        );

        if (priceIndex >= 0) {
          draft.currentProductPrices.splice(priceIndex, 1);
        }
        break;
      }
      case 'REMOVE_PRODUCT_PHOTO': {
        const photoIndex = draft.currentProduct.photos.findIndex(
          (p) => p.id === action.payload.imageId
        );

        if (photoIndex >= 0) {
          draft.currentProduct.photos.splice(photoIndex, 1);
        }

        break;
      }

      case 'SET_CURRENT_PRODUCT_PRICE': {
        draft.currentPrice = action.payload.price;

        break;
      }

      case 'ADD_PRODUCT_TABLE_PRICE': {
        if (state.currentProductPrices) {
          draft.currentProductPrices.push(action.payload.price);
          break;
        }

        draft.currentProductPrices = [action.payload.price];
        break;
      }

      case 'EDIT_PRODUCT_TABLE_PRICE': {
        const priceListIndex = draft.currentProductPrices.findIndex((a) => {
          return a.tempId === action.payload.price.tempId;
        });
        if (priceListIndex >= 0) {
          draft.currentProductPrices[priceListIndex] = action.payload.price;
        }
        break;
      }

      case 'HANDLE_MODAL_TABLE_PRICE': {
        draft.modalTablePrice = action.payload.state;

        break;
      }

      case 'HANDLE_LOADING_PRODUCTS_LIST': {
        draft.loadingProductsList = action.payload.state;
        break;
      }

      default:
        break;
    }
  });
}
