import styled from 'styled-components';

export const Container = styled.div`
  cursor: help;
  .tooltip {
    max-width: 300px;
    width: auto;
    height: auto;
  }
`;
