import styled from 'styled-components';

export const SearchArea = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f1f3fa;
  margin: 12px 0;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const Search = styled.input`
  width: 100%;
  height: 38px;
  border-radius: 0.25rem;
  padding: 10px 10px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.grayLight};
  background: #f1f3fa;
  /* background: #f5f5f5; */
  border: none;
  color: #6c757d;

  &::placeholder {
    color: #adb5bd;
  }
`;

export const ProductsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const ProductHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  border-top: 1px solid #e3eaef;
  border-bottom: 1px solid #e3eaef;
  padding: 12px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Sort = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 22px;
    height: 22px;
  }
`;
export const ProductTitle = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
`;
export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const Photo = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const Id = styled.div`
  display: flex;
  flex: 0.5;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.grayDark};

  svg {
    margin-right: 10px;
  }
`;

export const Name = styled(Id)`
  flex: 2;
`;

export const Code = styled(Id)`
  flex: 1;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const Available = styled(Id)`
  flex-direction: column;
  flex: 1;
`;

export const Delete = styled(Id)`
  flex: 0.5;
`;

export const Product = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  &:nth-child(odd) {
    background: #f1f3fa;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ProductPhoto = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  height: 60px;
  background-image: ${(props) => ` url(${props.image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const ProductId = styled.div`
  display: flex;
  flex: 0.5;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
`;

export const ProductName = styled(ProductId)`
  flex: 2;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px;
`;

export const ProductCode = styled(ProductId)`
  flex: 1;
  justify-content: center;
  padding: 10px;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const ProductDelete = styled(ProductId)`
  flex: 0.5;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    height: 30px;
    width: 30px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
export const OptionsArea = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  svg {
    height: 25px;
    width: 25px;
  }

  @media (max-width: 992px) {
    display: flex;
  }
`;

export const WithoutProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  width: 100%;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: ${(props) => props.theme.colors.greenDark};
  box-shadow: 0px 3px 4px #00000050;
  transition: box-shadow 0.6s, background 0.6s;
  border: 0;
  padding: 0.45rem 0.9rem;
  padding-top: 0.45rem;
  padding-right: 0.9rem;
  padding-bottom: 0.45rem;
  padding-left: 0.9rem;

  &:hover {
    background: ${(props) => props.theme.colors.greenMedium};
    box-shadow: 2px 6px 8px #00000050;
  }
`;
