import React, { useEffect, useState } from 'react';

import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { differenceInDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import isMobile from '../../../../utils/isMobile';

import { formatPrice } from '../../../../utils/format';

import * as DashboardActions from '../../../../store/modules/dashboard/actions';

import {
  Container,
  Background,
  TitleStyled,
  Body,
  Header,
  FilterArea,
  Filter,
  DateArea,
  ModalArea,
  ModalDate,
  ConfirmArea,
  ConfirmDate,
  DateTitle,
  CardsArea,
  SubTitle,
  Card,
  Text,
  Value,
  Amount,
  CircleArea,
  CircleCustomized,
} from './styles';

function Values({ getDashboardValues, dashboardValues }) {
  const today = new Date();
  const [state, setState] = useState({
    selection: {
      startDate: new Date().setDate(today.getDate() - 30),
      endDate: new Date(),
      key: 'selection',
    },
  });
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [daysAgo, setDaysAgo] = useState(30);

  useEffect(() => {
    getDashboardValues('', '');
  }, [getDashboardValues]);

  function handleSelect(date) {
    setDaysAgo(
      differenceInDays(date.selection.endDate, date.selection.startDate)
    );
    getDashboardValues(date.selection.startDate, date.selection.endDate);
    setState(date);
  }

  function verifyDate() {
    const day = format(state.selection.endDate, 'dd/MM/yyyy');
    const todayDate = format(new Date(), 'dd/MM/yyyy');
    const dayMonth = format(state.selection.startDate, 'MMMM', {
      locale: ptBR,
    });
    const lastMonth = format(state.selection.endDate, 'MMMM', { locale: ptBR });

    if (daysAgo <= 0 && day === todayDate) {
      return <span>Hoje</span>;
    }
    if (daysAgo <= 0 && day !== todayDate) {
      return (
        <span>{format(state.selection.endDate, 'PPPP', { locale: ptBR })}</span>
      );
    }
    if (daysAgo > 0 && day === todayDate) {
      return <span>Últimos {daysAgo} dias</span>;
    }
    if (daysAgo && dayMonth === lastMonth) {
      return (
        <span>
          {daysAgo} dias de {lastMonth}
        </span>
      );
    }
    return (
      <span>
        {daysAgo} dias de {dayMonth} até {lastMonth}
      </span>
    );
  }

  return (
    <Container>
      {calendarVisible && (
        <ModalArea>
          <ModalDate>
            {isMobile ? (
              <DateRange
                locale={ptBR}
                className="calendar"
                ranges={[state.selection]}
                date={new Date()}
                onChange={handleSelect}
              />
            ) : (
              <DateRangePicker
                locale={ptBR}
                className="calendar"
                ranges={[state.selection]}
                date={new Date()}
                onChange={handleSelect}
              />
            )}

            <ConfirmArea>
              <ConfirmDate onClick={() => setCalendarVisible(!calendarVisible)}>
                Confirmar
              </ConfirmDate>
            </ConfirmArea>
          </ModalDate>
        </ModalArea>
      )}
      <Background>
        <TitleStyled>Dashboard</TitleStyled>
        <Body>
          <Header>
            <SubTitle>Pedidos</SubTitle>
            <DateArea>
              <DateTitle onClick={() => setCalendarVisible(!calendarVisible)}>
                {verifyDate()}
              </DateTitle>
            </DateArea>

            <FilterArea>
              <Filter onClick={() => setCalendarVisible(!calendarVisible)}>
                Filtro
              </Filter>
            </FilterArea>
          </Header>

          <CardsArea>
            <Card>
              <Value>
                <Text>Vendas</Text>
                {formatPrice(dashboardValues?.orders?.income || 0, 2)}
              </Value>
              <CircleCustomized
                progress={dashboardValues?.orders?.percentage_income?.toFixed(
                  2
                )}
              />
            </Card>
            <Card>
              <Value>
                <Text>Quantidade</Text>
                <span>{dashboardValues?.orders?.quantity}</span>
              </Value>
              <CircleCustomized
                progress={dashboardValues?.orders?.percentage_orders?.toFixed(
                  2
                )}
              />
            </Card>
            <Card>
              <Value>
                <Text>Média</Text>
                <span>
                  {formatPrice(dashboardValues?.orders?.media_income, 2)}
                </span>
              </Value>
            </Card>
          </CardsArea>
          <SubTitle style={{ color: 'black' }}>Produtos</SubTitle>
          <CardsArea>
            <Card>
              <Value>
                <Text>Vendidos</Text>
                <span>{dashboardValues?.products?.quantity_order_items}</span>
              </Value>
              <CircleCustomized
                progress={dashboardValues?.products?.percentage_order_items?.toFixed(
                  2
                )}
              />
            </Card>
            <Card>
              <Value>
                <Text>Produto mais vendido</Text>
                {dashboardValues?.products?.best_seller?.product_name ? (
                  <span>
                    {dashboardValues?.products?.best_seller?.product_name}
                  </span>
                ) : (
                  <span>Sem produto</span>
                )}
                <Amount>
                  {dashboardValues?.products?.best_seller?.total_amount}{' '}
                  vendidos
                </Amount>
              </Value>

              <CircleArea>
                <CircleCustomized
                  progress={
                    dashboardValues?.products?.percentage_items_best_seller?.toFixed(
                      2
                    ) || 0
                  }
                />
              </CircleArea>
            </Card>
            <Card>
              <Value>
                <Text>Cadastrados</Text>
                <span>{dashboardValues?.products?.quantity}</span>
              </Value>
            </Card>
          </CardsArea>

          <SubTitle style={{ color: 'black' }}>Clientes</SubTitle>
          <CardsArea>
            <Card>
              <Value>
                <Text>Melhor comprador</Text>
                {dashboardValues?.customers?.best_buyer?.customer_name ? (
                  <span>
                    {dashboardValues?.customers?.best_buyer?.customer_name}
                  </span>
                ) : (
                  <span>Sem cliente</span>
                )}

                <Amount>
                  {dashboardValues?.customers?.best_buyer?.total_customer}{' '}
                  compras
                </Amount>
              </Value>
              <CircleCustomized
                progress={dashboardValues?.customers?.percentage_best_buyer?.toFixed(
                  2
                )}
              />
            </Card>
            <Card>
              <Value>
                <Text>Cadastrados</Text>
                <span>{dashboardValues?.customers?.quantity}</span>
              </Value>
            </Card>
          </CardsArea>
        </Body>
      </Background>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...DashboardActions }, dispatch);

const mapStateToProps = (state) => ({
  dashboardValues: state.dashboard.dashboardValues,
});
export default connect(mapStateToProps, mapDispatchToProps)(Values);
