export function validationResponse(response) {
  if (
    response &&
    response.response &&
    response.response.status &&
    response.response.status !== 200
  ) {
    return false;
  }
  return true;
}

export function ValidateDocument(document) {
  const doc = document.replace(/[^\d]+/g, '');
  if (doc === '') return false;
  if (doc.length === 11) {
    if (
      doc === '00000000000' ||
      doc === '11111111111' ||
      doc === '22222222222' ||
      doc === '33333333333' ||
      doc === '44444444444' ||
      doc === '55555555555' ||
      doc === '66666666666' ||
      doc === '77777777777' ||
      doc === '88888888888' ||
      doc === '99999999999'
    )
      return false;
    let sum;
    let rest;
    sum = 0;

    for (let i = 1; i <= 9; i += 1)
      sum += parseInt(doc.substring(i - 1, i), 10) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(doc.substring(9, 10), 10)) return false;

    sum = 0;
    for (let i = 1; i <= 10; i += 1)
      sum += parseInt(doc.substring(i - 1, i), 10) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(doc.substring(10, 11), 10)) return false;
    return true;
  }
  if (doc.length === 14) {
    if (
      doc === '00000000000000' ||
      doc === '11111111111111' ||
      doc === '22222222222222' ||
      doc === '33333333333333' ||
      doc === '44444444444444' ||
      doc === '55555555555555' ||
      doc === '66666666666666' ||
      doc === '77777777777777' ||
      doc === '88888888888888' ||
      doc === '99999999999999'
    ) {
      return false;
    }
    let size = doc.length - 2;
    let numbers = doc.substring(0, size);
    const digits = doc.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i -= 1) {
      // eslint-disable-next-line
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    // eslint-disable-next-line
    if (result != digits.charAt(0)) return false;

    size += 1;
    numbers = doc.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i -= 1) {
      // eslint-disable-next-line
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    // eslint-disable-next-line
    if (result != digits.charAt(1)) return false;

    return true;
  }

  return false;
}
