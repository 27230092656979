import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swal from 'sweetalert';
import { toast } from 'react-toastify';

import { FiBox, FiSettings, FiLogOut } from 'react-icons/fi';
import { RiEBike2Line } from 'react-icons/ri';
import {
  AiOutlineFileProtect,
  AiOutlineUser,
  AiFillContacts,
  AiOutlineBarChart,
} from 'react-icons/ai';
import i9p from '../../../assets/images/logo-symbol.svg';

import { getStoreSlug } from '../../../utils/storeUtil';
import history from '../../../services/history';

import * as AuthActions from '../../../store/modules/auth/actions';
import * as OrdersActions from '../../../store/modules/orders/actions';

import { Container, DrawerOverlay, Title, Option } from './styles';

function Drawer({
  handleDrawerVisible,
  drawerComplete,
  drawerVisible,
  signOut,
  delivery,
  showcase,
}) {
  const [activePage, setActivePage] = useState();

  useEffect(() => {
    const { pathname } = window.location;
    const array = pathname.split('/');
    switch (array[2]) {
      case 'dashboard':
        setActivePage('dashboard');
        break;
      case 'pedidos':
        setActivePage('orders');
        break;
      case 'clientes' || 'cliente':
        setActivePage('customers');
        break;
      case 'produtos' || 'produto' || 'variacao':
        setActivePage('products');
        break;
      case 'delivery':
        setActivePage('delivery');
        break;
      case 'configuracoes':
        setActivePage('settings');
        break;
      default:
        break;
    }
  }, []);

  function handleSignOut() {
    swal('Deseja realmente sair?', {
      icon: 'warning',
      buttons: ['Cancelar', 'Sair'],
    }).then((response) => {
      if (response) {
        signOut();
        history.push(`/${getStoreSlug()}`);
      }
    });
  }

  function handleIsDeliveryAvaliable() {
    if (window.innerWidth < 992) {
      toast.info('Acesso somente pelo desktop :)');
      return false;
    }
    if (!delivery) {
      swal(
        'Restrição de área não habilitada em suas configurações, é necessário ativar para utilizar essa funcionalidade.',
        {
          icon: 'warning',
          buttons: ['Cancelar', 'Ir para configurações'],
        }
      ).then((response) => {
        if (response) {
          history.push(`/${getStoreSlug()}/configuracoes`);
        }
      });
      return false;
    }

    return true;
  }

  function handleOptionClick(option) {
    if (drawerVisible) handleDrawerVisible(!drawerVisible);

    let route;
    switch (option) {
      case 'dashboard':
        route = 'dashboard';
        break;
      case 'orders':
        route = 'pedidos';
        break;
      case 'customers':
        route = 'clientes';
        break;
      case 'products':
        route = 'produtos';
        break;
      case 'delivery':
        route = handleIsDeliveryAvaliable() ? 'delivery' : null;
        break;
      case 'settings':
        route = 'configuracoes';
        break;
      case 'signout':
        route = null;
        handleSignOut();
        break;
      default:
        route = null;
        break;
    }

    if (route) {
      setActivePage(option);
      history.push(`/${getStoreSlug()}/${route}`);
    }
  }

  return (
    <>
      {drawerVisible && (
        <DrawerOverlay onClick={() => handleDrawerVisible(!drawerVisible)} />
      )}
      <Container drawerVisible={drawerVisible} drawerComplete={drawerComplete}>
        <Option href={`/${getStoreSlug()}/`} drawerComplete={drawerComplete}>
          <img src={i9p} alt="I9P" width="50" height="50" />
        </Option>
        <Option
          drawerComplete={drawerComplete}
          current={activePage === 'dashboard'}
          onClick={() => handleOptionClick('dashboard')}
        >
          <AiOutlineBarChart />
          <Title drawerComplete={drawerComplete}>Dashboard</Title>
        </Option>
        <Option
          drawerComplete={drawerComplete}
          current={activePage === 'orders'}
          onClick={() => handleOptionClick('orders')}
        >
          {showcase ? (
            <>
              <AiFillContacts />
              <Title drawerComplete={drawerComplete}>Contatos</Title>
            </>
          ) : (
            <>
              <AiOutlineFileProtect />
              <Title drawerComplete={drawerComplete}>Pedidos</Title>
            </>
          )}
        </Option>
        <Option
          drawerComplete={drawerComplete}
          current={activePage === 'customers'}
          onClick={() => handleOptionClick('customers')}
        >
          <AiOutlineUser />
          <Title drawerComplete={drawerComplete}>Clientes</Title>
        </Option>
        <Option
          drawerComplete={drawerComplete}
          current={activePage === 'products'}
          onClick={() => handleOptionClick('products')}
        >
          <FiBox />
          <Title drawerComplete={drawerComplete}>Produtos</Title>
        </Option>
        <Option
          drawerComplete={drawerComplete}
          current={activePage === 'delivery'}
          onClick={() => handleOptionClick('delivery')}
        >
          <RiEBike2Line />
          <Title drawerComplete={drawerComplete}>Delivery</Title>
        </Option>
        <Option
          drawerComplete={drawerComplete}
          current={activePage === 'settings'}
          onClick={() => handleOptionClick('settings')}
        >
          <FiSettings />
          <Title drawerComplete={drawerComplete}>Configurações</Title>
        </Option>
        <Option
          drawerComplete={drawerComplete}
          onClick={() => handleOptionClick('signout')}
        >
          <FiLogOut />
          <Title drawerComplete={drawerComplete}>Sair</Title>
        </Option>
      </Container>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...AuthActions, ...OrdersActions }, dispatch);

const mapStateToProps = (state) => ({
  drawerVisible: state.orders.drawerVisible,
  drawerComplete: state.orders.drawerComplete,
  delivery: state.store?.storeInfo?.settings?.restrict_delivery_area,
  showcase: state.store?.storeInfo?.settings?.showcase,
});
export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
