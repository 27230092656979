import React, { useState, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swal from 'sweetalert';
import { toast } from 'react-toastify';

import { ChromePicker } from 'react-color';

import { AiOutlineClose } from 'react-icons/ai';
import { BiMessageSquareEdit } from 'react-icons/bi';

import * as StoreActions from '../../../../store/modules/store/actions';

import Tooltip from '../../../components/Tooltip';
import CheckBox from '../../../components/CheckBox';

import {
  Container,
  Card,
  Title,
  Body,
  LayoutArea,
  PrimaryColor,
  Color,
  StoreInfo,
  InputFile,
  LogoArea,
  Preview,
  Icons,
  Delete,
  Edit,
  LogoPreview,
  BannerArea,
  BannerPreview,
  BannerMobilePreview,
  WaterMarkArea,
  WaterMarkPreview,
  ButtonArea,
  Button,
} from './styles';

function StoreLayout({
  storeSettings,
  updateStoreConfig,
  updateStoreLogo,
  deleteStoreLogo,
  updateStoreBanner,
  deleteStoreBanner,
  updateStoreBannerMobile,
  deleteStoreBannerMobile,
  updateStoreWaterMark,
  deleteStoreWaterMark,
}) {
  const [colorVisible, setColorVisible] = useState(false);
  const [colorPrimary, setColorPrimary] = useState(
    storeSettings?.color_primary || '#FFF'
  );
  const [cardInfo, setCardInfo] = useState(
    storeSettings?.show_store_info_header || false
  );
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(storeSettings?.logo_url || null);
  const [banner, setBanner] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(storeSettings?.banner_url || null);
  const [bannerMobile, setBannerMobile] = useState(null);
  const [bannerMobileUrl, setBannerMobileUrl] = useState(
    storeSettings?.banner_mobile_url || null
  );
  const [waterMark, setWaterMark] = useState(null);
  const [waterMarkUrl, setWaterMarkUrl] = useState(
    storeSettings?.product_watermark_url || null
  );

  const inputLogo = useRef(null);
  const inputBanner = useRef(null);
  const inputBannerMobile = useRef(null);
  const inputWaterMark = useRef(null);

  function handleSave() {
    if (
      storeSettings &&
      colorPrimary === storeSettings.color_primary &&
      cardInfo === storeSettings.show_store_info_header &&
      logoUrl === storeSettings.logo_url &&
      bannerUrl === storeSettings.banner_url &&
      bannerMobileUrl === storeSettings.banner_mobile_url &&
      waterMarkUrl === storeSettings.product_watermark_url
    ) {
      setColorVisible(false);
      return toast.info('Sem alterações');
    }
    return swal({
      title: 'Atenção?',
      text: 'Deseja realmente salvar as alterações?',
      icon: 'warning',
      buttons: ['Manter', 'Salvar'],
    }).then(async (save) => {
      if (save) {
        const storeLayout = {
          colorPrimary,
          cardInfo,
        };

        if (logo) await updateStoreLogo(logo);
        if (banner) await updateStoreBanner(banner);
        if (bannerMobile) await updateStoreBannerMobile(bannerMobile);
        if (waterMark) await updateStoreWaterMark(waterMark);
        if (colorPrimary || cardInfo) await updateStoreConfig(storeLayout);
        setColorVisible(false);
      }
    });
  }

  function handleCardInfo(e) {
    setCardInfo(e.target.checked);
  }

  function handleInputClick(input) {
    if (input === 'logo') inputLogo.current.click();
    if (input === 'banner') inputBanner.current.click();
    if (input === 'banner_mobile') inputBannerMobile.current.click();
    if (input === 'waterMark') inputWaterMark.current.click();
  }

  function handleLogo(image) {
    if (image.size > 2 * 1024 * 1024)
      return toast.info(
        'Imagem muito grande, por favor selecione um logo com no máximo 2MB'
      );
    setLogo(image);
    if (!image) return setLogoUrl();
    return setLogoUrl(URL.createObjectURL(image));
  }

  function handleBanner(image) {
    if (image.size > 2 * 1024 * 1024)
      return toast.info(
        'Imagem muito grande, por favor selecione um banner com no máximo 2MB'
      );
    setBanner(image);
    if (!image) return setBannerUrl();
    return setBannerUrl(URL.createObjectURL(image));
  }

  function handleBannerMobile(image) {
    if (image.size > 2 * 1024 * 1024)
      return toast.info(
        'Imagem muito grande, por favor selecione um banner mobile com no máximo 2MB'
      );
    setBannerMobile(image);
    if (!image) return setBannerMobileUrl();
    return setBannerMobileUrl(URL.createObjectURL(image));
  }

  function handleWaterMark(image) {
    if (image.size > 2 * 1024 * 1024)
      return toast.info(
        `Imagem muito grande, por favor selecione uma marca d'agua com no máximo 2MB`
      );
    setWaterMark(image);
    if (!image) return setWaterMarkUrl();
    return setWaterMarkUrl(URL.createObjectURL(image));
  }

  function handleDeleteLogo() {
    swal('Deseja realmente exluir esse logo?', {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        setLogo(null);
        setLogoUrl(null);
        deleteStoreLogo();
      }
    });
  }
  function handleDeleteWaterMark() {
    swal("Deseja realmente exluir essa marca d'agua?", {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        setWaterMarkUrl(null);
        setWaterMark(null);
        deleteStoreWaterMark();
      }
    });
  }
  function handleDeleteBanner() {
    swal('Deseja realmente exluir esse banner?', {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        setBanner(null);
        setBannerUrl(null);
        deleteStoreBanner();
      }
    });
  }
  function handleDeleteBannerMobile() {
    swal('Deseja realmente exluir esse banner mobile?', {
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Excluir'],
    }).then((response) => {
      if (response) {
        setBannerMobile(null);
        setBannerMobileUrl(null);
        deleteStoreBannerMobile();
      }
    });
  }

  return (
    <Container>
      <Card>
        <Body>
          <LayoutArea>
            <PrimaryColor>
              <Title>
                <Tooltip info="Selecione a cor principal de sua loja, as alterações mudará a aparência de sua loja virtual. " />
                <span>Selecione a cor principal de sua loja</span>
              </Title>

              {colorVisible ? (
                <>
                  <ChromePicker
                    disabled
                    className="picker"
                    color={colorPrimary}
                    onChange={(selected) => setColorPrimary(selected.hex)}
                    disableAlpha
                  />
                </>
              ) : (
                <Color
                  onClick={() => setColorVisible(true)}
                  color={colorPrimary}
                />
              )}
            </PrimaryColor>
            <StoreInfo>
              <Title>
                <Tooltip info="Caso habilitado, as informações da loja ficará visível na parte superior do site também. " />
                <span>Habilitar card de informações</span>
              </Title>
              <CheckBox
                id="cardInfo"
                status={cardInfo}
                handleStatus={handleCardInfo}
              />
            </StoreInfo>
          </LayoutArea>
          <LayoutArea>
            <LogoArea>
              <Title>
                <Tooltip
                  info="Selecione o logo de sua loja para que esteja visível para seus usuários, de preferência com as medidas 512x512.
                  O logo será ajustado em suas posições.  obs.(O logo deve conter no máximo 2MB)."
                />
                <span>Selecione o logo de sua loja</span>
              </Title>
              <input
                ref={inputLogo}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleLogo(e.target.files[0])}
              />
              {!logoUrl ? (
                <InputFile onClick={() => handleInputClick('logo')}>
                  <span>Escolha seu logo</span>
                  <span>512x512</span>
                </InputFile>
              ) : (
                <>
                  {logo && <span>{logo.name}</span>}
                  <Preview>
                    <LogoPreview image={logoUrl} />

                    <Icons>
                      <Delete onClick={() => handleDeleteLogo()}>
                        <AiOutlineClose />
                      </Delete>
                      <Edit onClick={() => handleInputClick('logo')}>
                        <BiMessageSquareEdit />
                      </Edit>
                    </Icons>
                  </Preview>
                </>
              )}
            </LogoArea>

            <WaterMarkArea>
              <Title>
                <Tooltip
                  info="Selecione a marca d'agua que será aplicada nas fotos de seus produtos, de preferência com as medidas 800x800.
                   A marca d'agua será aplicada automaticamente em todas as fotos a partir do momento que você fizer o upload.
                   obs.(A marca d&rsquo;agua deve conter no máximo 2MB)."
                />
                <span>Selecione sua marca d&rsquo;agua</span>
              </Title>{' '}
              <input
                ref={inputWaterMark}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleWaterMark(e.target.files[0])}
              />
              {!waterMarkUrl ? (
                <>
                  <InputFile onClick={() => handleInputClick('waterMark')}>
                    <span>Escolha sua marca d&rsquo;agua </span>
                    <span>800x800</span>
                  </InputFile>
                </>
              ) : (
                <>
                  {waterMark && <span>{waterMark.name}</span>}
                  <Preview>
                    <WaterMarkPreview image={waterMarkUrl} />
                    <Icons>
                      <Delete onClick={() => handleDeleteWaterMark()}>
                        <AiOutlineClose />
                      </Delete>
                      <Edit onClick={() => handleInputClick('waterMark')}>
                        <BiMessageSquareEdit />
                      </Edit>
                    </Icons>
                  </Preview>
                </>
              )}
            </WaterMarkArea>
          </LayoutArea>
          <LayoutArea>
            <BannerArea>
              <Title>
                <Tooltip
                  info="Selecione um banner que ficará visível na parte superior de sua loja, de preferência com as medidas 1920x380.
                 O banner sempre será ajustado ao centro, conforme o tamanho inserido poderá cortar os cantos.
                  obs.(O banner deve conter no máximo 2MB). "
                />
                <span>Selecione o banner de sua loja</span>
              </Title>
              <input
                ref={inputBanner}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleBanner(e.target.files[0])}
              />
              {!bannerUrl ? (
                <>
                  <InputFile onClick={() => handleInputClick('banner')}>
                    <span>Escolha seu banner</span>
                    <span>1920x380</span>
                  </InputFile>
                </>
              ) : (
                <>
                  <Preview>
                    <BannerPreview image={bannerUrl} />
                    <Icons>
                      <Delete onClick={() => handleDeleteBanner()}>
                        <AiOutlineClose />
                      </Delete>
                      <Edit onClick={() => handleInputClick('banner')}>
                        <BiMessageSquareEdit />
                      </Edit>
                    </Icons>
                  </Preview>

                  {banner && <span>{banner.name}</span>}
                </>
              )}
            </BannerArea>
          </LayoutArea>
          <LayoutArea>
            <BannerArea>
              <Title>
                <Tooltip
                  info="Selecione um banner versãp mobile que ficará visível na parte superior de sua
                   loja quando estiver em um celular,de preferência com as medidas 640x260.
                 O banner sempre será ajustado ao centro, conforme o tamanho inserido poderá cortar os cantos.
                  obs.(O banner deve conter no máximo 2MB). "
                />
                <span>Selecione o banner mobile de sua loja</span>
              </Title>
              <input
                ref={inputBannerMobile}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleBannerMobile(e.target.files[0])}
              />
              {!bannerMobileUrl ? (
                <>
                  <InputFile onClick={() => handleInputClick('banner_mobile')}>
                    <span>Escolha seu banner mobile</span>
                    <span>640x260</span>
                  </InputFile>
                </>
              ) : (
                <>
                  <Preview>
                    <BannerMobilePreview image={bannerMobileUrl} />
                    <Icons>
                      <Delete onClick={() => handleDeleteBannerMobile()}>
                        <AiOutlineClose />
                      </Delete>
                      <Edit onClick={() => handleInputClick('banner_mobile')}>
                        <BiMessageSquareEdit />
                      </Edit>
                    </Icons>
                  </Preview>

                  {bannerMobile && <span>{bannerMobile.name}</span>}
                </>
              )}
            </BannerArea>
          </LayoutArea>
          <ButtonArea>
            <Button onClick={() => handleSave()}>
              <span>Salvar</span>
            </Button>
          </ButtonArea>
        </Body>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(StoreActions, dispatch);

const mapStateToProps = (state) => ({
  storeSettings: state.store.storeInfo && state.store.storeInfo.settings,
});
export default connect(mapStateToProps, mapDispatchToProps)(StoreLayout);
