import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.linearGradientGreen};
`;

export const ImageArea = styled.div`
  display: none;
  flex: 3;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: ${(props) => ` url(${props.image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.greenDark};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayDark};
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 40px;
`;

export const FormArea = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5%;
  color: ${(props) => props.theme.colors.grayDark};
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 4px 7px #00000050;

  span {
    align-self: flex-start;
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const LogoArea = styled.div`
  width: 100%;
`;

export const Logo = styled.div`
  height: 100px;
  background-image: ${(props) => ` url(${props.image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 20px 20px;
`;

export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  border: 1px solid #6e7989;
  border-radius: 20px;
  height: 40px;
  width: 70%;
  background: ${(props) => props.theme.colors.greenDark};
  font-weight: bold;
  font-size: 18px;
  color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 3px 6px #00000050;
  transition: box-shadow 0.6s;

  border: 0;
  &:hover {
    box-shadow: 2px 5px 8px #00000050;
  }
`;
